import { StoreService } from '../lib/store.service';
import { Injectable } from '@angular/core';
import * as Auth from 'aws-amplify/auth';
import { AuthSession } from 'aws-amplify/auth';
import CONFIG from '../../config';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(private storeService: StoreService) {}

  async initSession(): Promise<void> {
    this.storeService.initStore(await Auth.fetchAuthSession());
  }

  async signIn(): Promise<void> {
    await Auth.signInWithRedirect({
      provider: {
        custom: CONFIG.cognitoIdpName,
      },
    });
  }

  async isAuthTokenExpired(): Promise<boolean> {
    const expiration = (await this.storeService.session)?.credentials
      ?.expiration;

    return !expiration || Date.now() > expiration.getTime();
  }

  async getSession(): Promise<AuthSession> {
    if (!this.storeService.session || (await this.isAuthTokenExpired())) {
      this.storeService.session = Auth.fetchAuthSession();
    }

    return this.storeService.session;
  }
}
