export class TeamsMessageCard {
  '@type' = 'MessageCard';
  '@context' = 'http://schema.org/extensions';
  themeColor?: string;
  title: string;
  sections: TeamsMessageCardSection[] = [];
  summary: string;

  constructor(title: string, summary: string) {
    this.title = title;
    this.summary = summary;
  }
}

export class TeamsMessageCardSection {
  activityTitle: string;
  activitySubtitle?: string;
  activityImage?: string;

  constructor(title: string) {
    this.activityTitle = title;
  }
}

export class TeamsFactsMessageCardSection extends TeamsMessageCardSection {
  facts: Array<{
    name: string;
    value: string;
  }> = [];
}

export class TeamsTextMessageCardSection extends TeamsMessageCardSection {
  text: string;

  constructor(title: string, text: string) {
    super(title);
    this.text = text;
  }
}

export class TeamsImagesMessageCardSection extends TeamsMessageCardSection {
  images: Array<{ image: string }>;

  constructor(title: string, ...images: string[]) {
    super(title);
    this.images = images?.map((img) => ({ image: img }));
  }
}
