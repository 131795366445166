import { Pipe, PipeTransform } from '@angular/core';
import { Firmware } from '../../models/firmware';
import { MetaVersion } from '../../models/metaversion/meta-version';

@Pipe({
  name: 'displayMetaVersionWifi',
})
export class DisplayMetaVersionWifiPipe implements PipeTransform {
  transform(metaVersion: Firmware): string {
    if (metaVersion) {
      return MetaVersion.formatFirmwareId(metaVersion);
    }
    return '';
  }
}
