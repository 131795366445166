<!-- Loader -->
<ng-template #loader>
  <app-spinner></app-spinner>
</ng-template>

<!-- Charts -->
<div class="card shadow mb-4">
  <div class="card-body">
    <div
      class="d-flex justify-content-between"
      *ngIf="numberOfProducts; else loader"
    >
      <app-number-of-products-chart
        [numberOfProducts]="numberOfProducts"
        [colors]="_chartsColors"
        class="d-flex flex-column graph-container"
      ></app-number-of-products-chart>
      <app-recalculated-per-year-chart
        [costs]="_costsRecalculatedPerYear"
        [colors]="_chartsColors"
        class="d-flex flex-column graph-container"
      >
      </app-recalculated-per-year-chart>
      <app-cost-repartition-chart
        [costsRecalculatedByMonth]="_costsRecalculatedPerMonth"
        [numberOfProducts]="numberOfProducts"
        [colors]="_chartsColors"
        class="d-flex flex-column graph-container"
      ></app-cost-repartition-chart>
    </div>
  </div>
</div>

<!-- Form -->
<div class="card shadow mb-4">
  <div class="card-body">
    <div class="d-flex flex-row justify-content-between">
      <div class="d-flex flex-column">
        <app-month-range-filter
          [initialFilters]="initialFilters"
          [currentFilters]="currentFilters"
          (filter)="filters.emit($event)"
        ></app-month-range-filter>
      </div>
      <div class="d-flex align-self-end ml-2">
        <!-- In a development environment, the table references used may sometimes cause an ExpressionChangedAfterItHasBeenCheckedError.
        I couldn't properly fix this behavior in all cases, but it is no big deal and shouldn't appear in a production environment. -->
        <app-table-export
          fileName="product_costs"
          [tables]="
            !numberOfProducts &&
            !_costsRecalculatedPerMonth &&
            !_costsRecalculatedPerYear
              ? undefined
              : [
                  {
                    name: 'Number of products',
                    table: _numberOfProductsTableRef?.nativeElement,
                  },
                  {
                    name: 'Costs per month',
                    table: _recalculatedPerMonthTableRef?.nativeElement,
                  },
                  {
                    name: 'Costs per year',
                    table: _recalculatedPerYearTableRef?.nativeElement,
                  },
                ]
          "
        >
        </app-table-export>
      </div>
    </div>
  </div>
</div>

<!-- Data tables -->
<div class="card shadow mb-4" [style]="numberOfProducts ? '' : 'display: none'">
  <div class="card-body with-caption">
    <!-- Number of products -->
    <h5 id="numberOfProductsTitle">Number of products</h5>
    <div class="scrollable">
      <table
        class="table data-table"
        aria-describedby="numberOfProductsTitle"
        #numberOfProductsTable
      >
        <ng-container *ngIf="numberOfProducts">
          <thead>
            <tr class="odd-row">
              <th scope="col" class="row-header">Product</th>
              <th
                *ngFor="let monthLabel of numberOfProducts.monthLabels"
                scope="col"
              >
                {{ monthLabel }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let productNumbers of numberOfProducts.productsNumbers;
                let index = index
              "
              [class]="index % 2 ? 'odd-row' : ''"
            >
              <th scope="row" class="row-header">
                {{ productNumbers.productName }}
              </th>
              <td *ngFor="let number of productNumbers.monthNumbers">
                {{ number }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="odd-row">
              <th scope="row" class="row-header tfoot-top-row">Total</th>
              <td
                *ngFor="let total of numberOfProducts.totals"
                class="tfoot-top-row"
              >
                {{ total }}
              </td>
            </tr>
          </tfoot>
        </ng-container>
      </table>
    </div>
    <!-- Monthly recalculated costs -->
    <ng-container [style]="_costsRecalculatedPerMonth ? '' : 'display: none'">
      <h5 id="recalculatedPerMonthTitle" class="mt-4">
        Cost recalculated by product per month ($)
      </h5>
      <div class="scrollable">
        <table
          class="table data-table"
          aria-describedby="recalculatedPerMonthTitle"
          #recalculatedPerMonthTable
        >
          <ng-container *ngIf="_costsRecalculatedPerMonth">
            <thead>
              <tr class="odd-row">
                <th scope="col" class="row-header">Product</th>
                <th
                  *ngFor="
                    let monthLabel of _costsRecalculatedPerMonth.monthLabels
                  "
                  scope="col"
                >
                  {{ monthLabel }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let cost of _costsRecalculatedPerMonth.costs;
                  let index = index
                "
                [class]="index % 2 ? 'odd-row' : ''"
              >
                <th scope="row" class="row-header">{{ cost.key }}</th>
                <td *ngFor="let value of cost.monthCosts">
                  {{ value | number: "1.3-3" }}
                </td>
              </tr>
            </tbody>
          </ng-container>
        </table>
      </div>
    </ng-container>
    <!-- Yearly recalculated costs -->
    <ng-container [style]="_costsRecalculatedPerYear ? '' : 'display: none'">
      <h5 id="recalculatedPerYearTitle" class="mt-4">
        Cost recalculated by product for one year ($)
      </h5>
      <div class="scrollable">
        <table
          class="table data-table"
          aria-describedby="recalculatedPerYearTitle"
          #recalculatedPerYearTable
        >
          <ng-container *ngIf="_costsRecalculatedPerYear">
            <thead>
              <tr class="odd-row">
                <th scope="col" class="row-header">Product</th>
                <th
                  *ngFor="
                    let monthLabel of _costsRecalculatedPerYear.monthLabels
                  "
                  scope="col"
                >
                  {{ monthLabel }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let cost of _costsRecalculatedPerYear.costs;
                  let index = index
                "
                [class]="index % 2 ? 'odd-row' : ''"
              >
                <th scope="row" class="row-header">{{ cost.key }}</th>
                <td *ngFor="let value of cost.monthCosts">
                  {{ value | number: "1.3-3" }}
                </td>
              </tr>
            </tbody>
          </ng-container>
        </table>
      </div>
    </ng-container>
  </div>
</div>
