<span
  class="badge"
  [ngClass]="{
    'badge-primary': jobType === 'SINGLE',
    'badge-info': jobType === 'MULTI',
    'badge-warning': jobType === 'GROUP',
  }"
>
  {{ jobType }}
</span>
