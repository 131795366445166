import { Component } from '@angular/core';

@Component({
  selector: 'app-403',
  standalone: true,
  imports: [],
  templateUrl: './403.component.html',
  styleUrl: './403.component.scss',
})
export class Forbidden403Component {}
