import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
import { AuthSession } from 'aws-amplify/auth';

@Injectable({
  providedIn: 'root',
})
export class SigV4UtilsService {
  SigV4Utils = {
    getSignedUrl: (
      host: string,
      region: string,
      credentials: AuthSession['credentials'],
    ): string => {
      const time = moment.utc();
      const date = time.format('YYYYMMDD');
      const datetime = `${date}T${time.format('HHmmss')}Z`;

      const method = 'GET';
      const protocol = 'wss';
      const uri = '/mqtt';
      const service = 'iotdevicegateway';
      const algorithm = 'AWS4-HMAC-SHA256';

      const credentialScope = `${date}/${region}/${service}/aws4_request`;
      let canonicalQuerystring = `X-Amz-Algorithm=${algorithm}`;
      canonicalQuerystring += `&X-Amz-Credential=${encodeURIComponent(
        `${credentials?.accessKeyId}/${credentialScope}`,
      )}`;
      canonicalQuerystring += `&X-Amz-Date=${datetime}`;
      canonicalQuerystring += '&X-Amz-Expires=86400';
      canonicalQuerystring += '&X-Amz-SignedHeaders=host';

      const canonicalHeaders = `host:${host}\n`;
      const payloadHash = this.SigV4Utils.hash('');
      const canonicalRequest = [
        method,
        uri,
        canonicalQuerystring,
        canonicalHeaders,
        'host',
        payloadHash,
      ].join('\n');

      const stringToSign = [
        algorithm,
        datetime,
        credentialScope,
        this.SigV4Utils.hash(canonicalRequest),
      ].join('\n');

      const signingKey = this.SigV4Utils.getSignatureKey(
        date,
        region,
        service,
        credentials?.secretAccessKey,
      );

      const signature = this.SigV4Utils.hmacHex(stringToSign, signingKey);
      canonicalQuerystring += `&X-Amz-Signature=${signature}`;

      if (credentials?.sessionToken) {
        canonicalQuerystring += `&X-Amz-Security-Token=${encodeURIComponent(
          credentials.sessionToken,
        )}`;
      }

      return `${protocol}://${host}${uri}?${canonicalQuerystring}`;
    },
    getSignatureKey: (
      date: string,
      region: string,
      service: string,
      key?: string,
    ): CryptoJS.lib.WordArray => {
      const kDate = this.SigV4Utils.hmac(date, `AWS4${key}`);
      const kRegion = this.SigV4Utils.hmac(region, kDate);
      const kService = this.SigV4Utils.hmac(service, kRegion);
      return this.SigV4Utils.hmac('aws4_request', kService);
    },
    hash: (input: string): string =>
      CryptoJS.SHA256(input).toString(CryptoJS.enc.Hex),
    hmac: (
      input: string,
      key: string | CryptoJS.lib.WordArray,
    ): CryptoJS.lib.WordArray => CryptoJS.HmacSHA256(input, key),
    hmacHex: (input: string, key: string | CryptoJS.lib.WordArray): string =>
      this.SigV4Utils.hmac(input, key).toString(CryptoJS.enc.Hex),
  };
}
