<div class="container-fluid" *ngIf="jobs$ | async as jobs; else loadingBlock">
  <!-- Page Heading -->
  <h1 class="text-gray-800">Deployments</h1>
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <form
        class="form-inline"
        [formGroup]="formGroupGlobalFilters"
        (ngSubmit)="globalFilter()"
      >
        <h6 class="m-0 font-weight-bold text-primary">Deployments List</h6>
        <div class="form-row">
          <div class="col-auto ml-3 d-flex">
            <div
              class="form-check form-switch mb-0"
              [ngClass]="{ loading: filterLoading }"
            >
              <input
                *ngIf="!filterLoading; else loadingSmall"
                type="checkbox"
                id="inProgressOnly"
                name="inProgressOnly"
                class="form-check-input"
                formControlName="inProgressOnly"
                (change)="submitButton.click()"
              />
              <label class="form-check-label" for="inProgressOnly">
                In progress only
              </label>
            </div>
          </div>
          <div class="col-auto">
            <button
              class="btn btn-primary"
              type="reset"
              (click)="resetFilters()"
            >
              Reset filters
            </button>
          </div>
        </div>
        <button type="submit" hidden #submitButton></button>
      </form>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <app-ngx-table-with-query-params-persistence
          [columns]="columns"
          [configuration]="configuration"
          [data]="jobs"
          [filtersTemplate]="filtersTemplate"
          [filtersFormGroup]="formGroupLocalFilters"
          class="table"
        >
          <ng-template let-rawJob>
            <!-- Allows IDEs to correctly infer the object type to provide completion and checks -->
            <ng-container *ngIf="typedJobObject(rawJob) as job">
              <td>{{ job.createdAt | displayMetaVersionDate }}</td>
              <td>{{ job.jobId }}</td>
              <td>
                <a
                  *ngIf="job.metaversionId as metaversion"
                  routerLink="/metaversions/{{ metaversion }}"
                  >{{ metaversion }}</a
                >
              </td>
              <td>
                <app-job-type-badge
                  *ngIf="job.singleOrMulti as jobType"
                  [jobType]="jobType"
                ></app-job-type-badge>
              </td>
              <td>{{ job.thingType }}</td>
              <td>
                <span
                  class="badge"
                  [ngClass]="{
                    'badge-success': job.status === 'COMPLETED',
                    'badge-warning': job.status === 'IN_PROGRESS',
                    'badge-danger': job.status === 'FAILED',
                    'badge-light': job.status === 'CANCELED',
                  }"
                >
                  {{ job.status }}
                </span>
              </td>
              <td>{{ job.createdBy | displayCreatorName }}</td>
              <td>
                <a
                  class="btn btn-eye"
                  routerLink="/deployments/{{ job.jobId }}"
                  *canSeeDeploymentDetails
                >
                  <i class="fas fa-eye" aria-hidden="true"></i>
                </a>
              </td>
            </ng-container>
          </ng-template>
          <ng-template #filtersTemplate>
            <ng-container [formGroup]="formGroupLocalFilters">
              <th scope="col" class="pl-0 pr-3 pt-0">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Creation date"
                  formControlName="createdAtStr"
                />
              </th>
              <th scope="col" class="pl-0 pr-3 pt-0">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Job name"
                  formControlName="jobId"
                />
              </th>
              <th scope="col" class="pl-0 pr-3 pt-0">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Metaversion"
                  formControlName="metaversionId"
                />
              </th>
              <th scope="col" class="pl-0 pr-3 pt-0">
                <select class="form-select" formControlName="singleOrMulti">
                  <option [ngValue]="null">All</option>
                  <option value="SINGLE">Single</option>
                  <option value="MULTI">Multi</option>
                  <option value="GROUP">Group</option>
                </select>
              </th>
              <th scope="col" class="pl-0 pr-3 pt-0">
                <select
                  class="form-select"
                  formControlName="thingType"
                  [disabled]="(thingTypes$ | async) == null"
                >
                  <option [ngValue]="null">All</option>
                  <option
                    *ngFor="let thingType of thingTypes$ | async"
                    [ngValue]="thingType"
                  >
                    {{ thingType }}
                  </option>
                </select>
              </th>
              <th scope="col" class="pl-0 pr-3 pt-0">
                <select class="form-select" formControlName="status">
                  <option [ngValue]="null">All</option>
                  <option value="COMPLETED">Completed</option>
                  <option value="IN_PROGRESS">In progress</option>
                  <option value="CANCELED">Canceled</option>
                  <option value="FAILED">Failed</option>
                </select>
              </th>
              <th scope="col" class="pl-0 pr-3 pt-0">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Creator"
                  formControlName="createdBy"
                />
              </th>
            </ng-container>
          </ng-template>
        </app-ngx-table-with-query-params-persistence>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingBlock>
  <app-spinner></app-spinner>
</ng-template>
<ng-template #loadingSmall>
  <div class="mr-1">
    <app-spinner-small></app-spinner-small>
  </div>
</ng-template>
