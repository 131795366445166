<div class="container-fluid">
  <div class="card shadow mb-4">
    <div class="card-header">
      <h1>Forbidden</h1>
    </div>
    <div class="card-body">
      <p>You are not allowed to access the requested url.</p>
      <p>To request access, please file a ticket to SEB support (8787).</p>
    </div>
  </div>
</div>
