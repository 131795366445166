import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AwsService } from '../lib/aws.service';
import { ListThingTypesCommandOutput } from '@aws-sdk/client-iot';

@Injectable({
  providedIn: 'root',
})
export class ThingTypesService {
  constructor(private aws: AwsService) {}

  getThingTypes(): Observable<string[]> {
    return from(this.aws.iot()).pipe(
      switchMap((iot) => iot.listThingTypes()),
      map(
        (response: ListThingTypesCommandOutput) =>
          response.thingTypes?.map((value) => value.thingTypeName ?? '') ?? [],
      ),
    );
  }
}
