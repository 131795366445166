<div class="table-responsive">
  <app-ngx-table-with-query-params-persistence
    [columns]="columns"
    [configuration]="configuration"
    [data]="jobs"
    [filtersFormGroup]="formGroup"
    [filtersTemplate]="thingsFilters"
    prefix="jobs"
  >
    <ng-template let-rawJob>
      <ng-container *ngIf="typeJob(rawJob) as job">
        <td>{{ job.dateStr }}</td>
        <td>{{ job.jobId }}</td>
        <td>{{ job.metaversionId }}</td>
        <td>
          <div class="btn-group">
            <a class="btn btn-eye" routerLink="/deployments/{{ job.jobId }}">
              <i class="fas fa-eye" aria-hidden="true"></i>
            </a>
          </div>
        </td>
      </ng-container>
    </ng-template>
    <ng-template #thingsFilters>
      <ng-container [formGroup]="formGroup">
        <th scope="col">
          <input
            class="form-control"
            type="text"
            placeholder="Creation date"
            formControlName="dateStr"
          />
        </th>
        <th scope="col">
          <input
            class="form-control"
            type="text"
            placeholder="Name"
            formControlName="jobId"
          />
        </th>
        <th scope="col">
          <input
            class="form-control"
            type="text"
            placeholder="Metaversion"
            formControlName="metaversionId"
          />
        </th>
      </ng-container>
    </ng-template>
  </app-ngx-table-with-query-params-persistence>
</div>
