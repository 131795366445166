<div class="container-fluid" *ngIf="group$ | async as group">
  <div
    *ngIf="!editingName; else editName"
    class="d-flex flex-row align-items-center"
  >
    <h1 class="text-gray-800 text-break">{{ group.groupName }}</h1>
    <i
      class="fas fa-pen ml-2 clickable"
      (click)="editingName = true"
      *canEditGroupOfThings
      ngbTooltip="Edit group name"
      aria-hidden="true"
    ></i>
    <app-audit-creator
      [type]="AuditType.THING_GROUP"
      [resourceId]="group.groupId"
    />
  </div>

  <ng-template #editName>
    <form class="form-inline pb-3" (ngSubmit)="saveName()">
      <div class="input-group mr-2">
        <input
          type="text"
          class="form-control"
          [formControl]="groupNameControl"
        />
        <button
          type="submit"
          class="btn btn-outline-primary"
          [disabled]="
            groupNameControl.pristine ||
            groupNameControl.invalid ||
            editNameLoading
          "
        >
          <i class="fas fa-check" aria-hidden="true"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          (click)="cancelNameEdit()"
          [disabled]="editNameLoading"
        >
          <i class="fas fa-times" aria-hidden="true"></i>
        </button>
      </div>
      <app-spinner-small *ngIf="editNameLoading"></app-spinner-small>
    </form>
  </ng-template>

  <div class="card shadow mb-2">
    <div class="card-body d-flex flex-row justify-content-between w-100">
      <div>
        <span>Thingtype : {{ group.thingType }}</span>
        <span *ngIf="group.criteriaType !== 'THINGTYPE'" class="ml-3">
          {{ group.criteriaType }} :
          <span
            ngbTooltip="Determined from the first product added to the group"
            >{{ group.criteriaValue ?? "N/A" }}</span
          >
        </span>
      </div>
      <div>
        <div class="btn-group">
          <button
            class="btn btn-success"
            (click)="addThingToGroup(group)"
            *canEditGroupOfThings
          >
            <i class="fas fa-plus-circle mr-1" aria-hidden="true"></i>
            Add Thing
          </button>
          <a
            class="btn btn-primary"
            routerLink="/metaversions/group/{{ group.groupId }}"
            *canDeployOnGroupOfThings
            [state]="{ group, things: things$ | async }"
          >
            <i class="fas fa-cloud-upload-alt" aria-hidden="true"></i>
            Upgrade firmware
          </a>
          <button
            class="btn btn-danger"
            (click)="delete(group)"
            *canDeleteGroupOfThings
          >
            <i class="fas fa-trash-alt mr-1" aria-hidden="true"></i>
            Delete group
          </button>
        </div>
      </div>
    </div>
  </div>

  <app-tab-view>
    <app-tab tabTitle="Group devices" tabId="devices">
      <ng-template #tabContent>
        <div class="card shadow mt-2">
          <ng-container *ngIf="things$ | async as things; else loading">
            <div class="card-header d-flex flex-row align-items-end">
              <h2 class="mb-0">Group Devices</h2>
              <span class="ml-4"
                >Total count of devices : {{ group.numberOfThings }}</span
              >
            </div>
            <div class="card-body">
              <div
                class="alert alert-primary"
                role="alert"
                *ngIf="thingsUpdating"
              >
                <span
                  >Refreshing data... <app-spinner-small></app-spinner-small
                ></span>
              </div>
              <app-group-of-things-details-things
                [things]="things ?? []"
                [group]="group ?? undefined"
                (deletedThing)="deletedThing()"
              ></app-group-of-things-details-things>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </app-tab>
    <app-tab tabTitle="Deployments" tabId="deployments" *canListDeployments>
      <ng-template #tabContent>
        <div class="card shadow mt-2">
          <div class="card-header d-flex flex-row align-items-end">
            <h2 class="mb-0">Deployments</h2>
          </div>
          <div class="card-body">
            <ng-container *ngIf="jobs$ | async as jobs; else loading">
              <app-group-of-things-details-jobs
                [jobs]="jobs ?? undefined"
              ></app-group-of-things-details-jobs>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </app-tab>
    <app-tab tabTitle="Firmwares distribution" tabId="firmwares">
      <ng-template #tabContent>
        <div class="card shadow mt-2">
          <div class="card-header d-flex flex-row align-items-end">
            <h2 class="mb-0">Firmwares distribution</h2>
          </div>
          <div class="card-body" *ngIf="things$ | async as _things">
            <ng-container>
              <p
                class="text-warning"
                *ngIf="_things.length !== group?.numberOfThings"
              >
                If you just added or removed things to this group, you may need
                to refresh this page in a minute for the charts to be updated
                accordingly.
              </p>
              <app-group-of-thing-details-firmware-distribution
                [things]="_things || []"
              ></app-group-of-thing-details-firmware-distribution>
            </ng-container>
          </div>
        </div>
      </ng-template>
    </app-tab>
  </app-tab-view>
</div>

<ng-template #loading>
  <app-spinner></app-spinner>
</ng-template>
