import { AuditLog } from '@common/audit-log/models/AuditLog';
import * as moment from 'moment';
import { CriteriaType, Firmware } from '../models/firmware';
import { MetaVersion } from '../models/metaversion/meta-version';

export class SearchableMetaversion extends MetaVersion {
  public dateStr: string;
  public firmwareFiles: string;
  public criteria: CriteriaType | '-';
  public enabledState: 'ENABLED' | 'DISABLED';
  public createdBy: string | undefined;

  constructor(
    id: string,
    thingType: string,
    validationAccepted: string,
    priority: string,
    updateValidation: boolean,
    wifiFirmware: Firmware | undefined,
    uiFirmware: Firmware | undefined,
    date: string,
    enabledDate: string | undefined,
    activated: number,
    dateStr: string,
    firmwareFiles: string,
    criteria: CriteriaType | '-',
    enabledState: 'ENABLED' | 'DISABLED',
    createdBy: string | undefined,
  ) {
    super(
      id,
      thingType,
      validationAccepted,
      priority,
      updateValidation,
      wifiFirmware,
      uiFirmware,
      date,
      enabledDate,
      activated,
    );
    this.dateStr = dateStr;
    this.firmwareFiles = firmwareFiles;
    this.criteria = criteria;
    this.enabledState = enabledState;
    this.createdBy = createdBy;
  }

  public static fromMetaVersion(
    meta: MetaVersion,
    auditLog?: AuditLog,
  ): SearchableMetaversion {
    const smv = meta as SearchableMetaversion;
    smv.dateStr = moment(meta.date).format('YYYY-MM-DD HH:mm:ss');
    smv.firmwareFiles = meta.displayFirmwares();
    smv.criteria =
      (meta?.uiFirmware || meta?.wifiFirmware)?.criteriaType || '-';
    smv.enabledState = meta.enabledDate ? 'ENABLED' : 'DISABLED';
    if (meta.enabledDate) {
      meta.enabledDate = moment(meta.enabledDate).format('YYYY-MM-DD HH:mm:ss');
    }

    smv.createdBy = auditLog?.userId;

    return smv;
  }
}
