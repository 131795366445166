import { EventEmitter, Injectable } from '@angular/core';
import { User } from '../models/user';
import { FeatureGroupEnum } from '../shared/user-rights-management/feature-group.enum';
import { AuthSession } from 'aws-amplify/auth';

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  session?: Promise<AuthSession>;
  user?: User;

  isInitialized = false;
  initEvent = new EventEmitter<void>();

  initStore(session: AuthSession): void {
    this.session = Promise.resolve(session);
    this.user = new User(session);
    this.isInitialized = true;
    this.initEvent.emit();
  }

  /**
   * Return true if the connected user is part of the specified group in cognito, else returns false
   *
   * @param group
   */
  userHasGroup(group: FeatureGroupEnum): boolean {
    return this.user?.groups.includes(group) ?? false;
  }
}
