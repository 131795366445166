import { Pipe, PipeTransform } from '@angular/core';
import { ArnUtils } from '../utils/arn.utils';

@Pipe({
  name: 'displayThingArn',
})
export class DisplayThingArnPipe implements PipeTransform {
  transform(value: string): string {
    return ArnUtils.getResourceId(value) ?? '';
  }
}
