import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AWSCosts } from '../../../models/AWSCost';
import { ChartDataset, ChartOptions } from 'chart.js';
import { MonthProductNumbers } from '../../../models/device-stats';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-cost-repartition-chart',
  templateUrl: './cost-repartition-chart.component.html',
  styleUrls: ['./cost-repartition-chart.component.css'],
})
export class CostRepartitionChartComponent implements OnChanges {
  @Input() costsRecalculatedByMonth?: AWSCosts;
  @Input() numberOfProducts?: MonthProductNumbers;
  @Input() colors: string[] = [];

  _chartLabels: string[] = [];
  _chartDataSets: ChartDataset[] = [];

  _costsData: number[] = [];
  _productNumbers: number[] = [];

  readonly _chartOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        mode: 'index',
        callbacks: {
          label(tooltipItem): string[] {
            console.log(tooltipItem);
            const label: string[] = [];

            label[0] = `${tooltipItem.label}: `;

            label[1] = `${(tooltipItem.raw as number).toFixed(1)} % of costs`;

            label[2] = `${tooltipItem.chart.data.datasets?.[1].data?.[tooltipItem.dataIndex ?? 0]} active units`;

            return label;
          },
        },
      },
      legend: {
        position: 'bottom',
      },
    },
  };

  ngOnChanges(changes: SimpleChanges): void {
    let datasetChanged = false;

    if (changes.costsRecalculatedByMonth?.currentValue) {
      this._chartLabels =
        this.costsRecalculatedByMonth?.costs.map((cost) => cost.key) ?? [];

      this._costsData =
        this.costsRecalculatedByMonth?.costs.map(
          (cost) =>
            cost.monthCosts[cost.monthCosts.length - 2] *
            (this.numberOfProducts?.productsNumbers?.find(
              (numbers) => numbers.productName === cost.key,
            )?.monthNumbers?.[cost.monthCosts.length - 2] ?? 1),
        ) ?? [];

      const total = this._costsData.reduce((a, b) => a + b);

      this._costsData = this._costsData.map((data) => (100 * data) / total);

      datasetChanged = true;
    }

    if (
      (changes.numberOfProducts?.currentValue && this._chartLabels) || // Value changed and chart is initialized
      // Chart is initialized, raw data present, but chart data not initialized yet
      (!this._productNumbers?.length &&
        this.numberOfProducts &&
        this._chartLabels)
    ) {
      this._productNumbers = this._chartLabels.map(
        (productKey) =>
          this.numberOfProducts?.productsNumbers.find(
            (product) => productKey === product.productName,
          )?.monthNumbers[this.numberOfProducts.monthLabels.length - 2] ?? 0,
      );
      datasetChanged = true;
    }

    if (datasetChanged) {
      this._chartDataSets = [
        {
          label: 'Costs',
          data: this._costsData,
        },
        {
          label: 'Number of products',
          data: this._productNumbers,
          hidden: true,
        },
      ];
    }
  }
}
