import { Pipe, PipeTransform } from '@angular/core';
import { Firmware } from '../../models/firmware';
import { MetaVersion } from '../../models/metaversion/meta-version';

@Pipe({
  name: 'displayFirmwareIdAndNumberOfFiles',
})
export class DisplayFirmwareIdAndNumberOfFilesPipe implements PipeTransform {
  transform(firmware: Firmware | undefined): string {
    if (firmware) {
      return MetaVersion.formatFirmwareId(firmware);
    }
    return '';
  }
}
