export const environment = {
  version: 'v2.21.1',
  production: true,
  title: 'IOT PREPROD',
  apiUrl: 'https://preprod.api.groupe-seb.com',
  apiKey: 'puVdQHQNS3rjx2e8RDWMhkTmyGkXLCdC',
  backendUrl: 'https://preprod.iot.api.groupe-seb.com/api/v1',
  metaVersionsTable: 'Meta-version',
  metaVersionsIndex: 'activated-thingType-index',
  firmwaresTable: 'Firmwares',
  firmwaresIndex: 'activated-thingType-index',
  iotDevicesTable: 'IOTDevices',
  iotDevicesIndex: 'macAddress-index',
  iotDevicesStatsTable: 'IOTDevicesStats',
  firmwareBrandAreaTable: 'FirmwareBrandArea',
  metaversionJobsTable: 'Meta-version-job',
  metaversionJobsIndex: 'metaversionId-index',
  metaversionJobsGroupIdIndex: 'groupId-jobId-index',
  groupOfThingsTable: 'GroupOfThings',
  groupOfThingsthingTypeIndex: 'thingType-groupId-index',
  firmwaresBucket: 'iot-security-com-groupeseb-firmwares',
  temporaryFirmwaresBucket: 'iot-preprod-com-groupeseb-temporary-firmwares',
  userLoginHistoryTable: 'ConsoleUserLoginHistory',
  userHistoryTTL: 4320, // hours
  cognitoIdpName: 'adfs',
  cognitoUserPoolId: 'eu-west-1_xIU5eFvVO',
  cognitoClientId: '2tb5crp6b47oag56cm948jcsmb',
  cognitoIdentityPoolId: 'eu-west-1:2cc3a661-6181-4dc9-a332-6191ba8d95f8',
  cognitoDomain: 'preprod-iot-api-groupe-seb.auth.eu-west-1.amazoncognito.com',
  cognitoSignInUrl: 'https://iot.console.preprod.groupe-seb.com/loading',
  cognitoSignOutUrl: 'https://iot.console.preprod.groupe-seb.com',
  webDomainNameV2: 'iot.console.preprod.groupe-seb.com',
  cognitoReponseType: 'code',
  awsRegion: 'eu-west-1',
  iotEndpoint: 'a2dfodpfquonys-ats.iot.eu-west-1.amazonaws.com',
  iotPolicyName: 'AdminConsole-IotPolicy-1T1BP2AC5U595',
  unsignedFolder: 'preprod/unsigned',
  signedFolder: 'preprod/signed',
  domainCertificateARN: 'arn:aws:acm:us-east-1:107574604831:certificate/18900698-bffc-40bc-9c3b-7d2ecea3573b',
  searchMaxResults: 20,
  thingArnPrefix: 'arn:aws:iot:eu-west-1:107574604831:thing/',
  crossOriginLambda: 'CallCrossOrigin',
  teamsWebhookUrl: 'https://groupeseb.webhook.office.com/webhookb2/79f88e84-15e1-4ea1-8666-5ebef28ac4ba@bcccbda4-a4dc-4cf5-b7cb-7a3869ac0856/IncomingWebhook/65e534c4cd7f4788a4350474d4f3fa5d/50fd6373-8fbd-4619-9d4d-454b55b3a12a',
  teamsMessageColor: 'FFFF00',
  envTag: 'preproduction',
  s3ProxyReadLambda: 'S3ProxyRead',
  s3ProxyWriteLambda: 'S3ProxyWrite',
};
