<div class="tab">
  <button
    *ngFor="let tab of tabs"
    (click)="selectTab(tab.tabId)"
    [class.active]="tab.tabId === (activatedRoute.queryParams | async).tab"
    class="tablinks"
  >
    {{ tab.tabTitle }}
  </button>
</div>
<ng-content></ng-content>
