import { CostExplorer } from '@aws-sdk/client-cost-explorer';
import { DynamoDBDocument } from '@aws-sdk/lib-dynamodb';
import { DynamoDB } from '@aws-sdk/client-dynamodb';
import { IoT } from '@aws-sdk/client-iot';
import { IoTDataPlane } from '@aws-sdk/client-iot-data-plane';
import { Lambda } from '@aws-sdk/client-lambda';
import { S3 } from '@aws-sdk/client-s3';
import { Injectable } from '@angular/core';
import CONFIG from '../../config';
import { AuthorizationService } from '../api/authorization.service';

@Injectable({
  providedIn: 'root',
})
export class AwsService {
  private dynamoDBDocument?: DynamoDBDocument;
  private s3Client?: S3;
  private iotClient?: IoT;
  private iotDataClient?: IoTDataPlane;
  private costExplorerClient?: CostExplorer;
  private lambdaClient?: Lambda;

  constructor(private readonly authorizationService: AuthorizationService) {}

  async dynamodb(): Promise<DynamoDBDocument> {
    if (
      !this.dynamoDBDocument ||
      (await this.authorizationService.isAuthTokenExpired())
    ) {
      this.dynamoDBDocument = DynamoDBDocument.from(
        new DynamoDB({
          region: CONFIG.awsRegion,
          credentials: (await this.authorizationService.getSession())
            .credentials,
        }),
        {
          marshallOptions: {
            convertClassInstanceToMap: true,
            removeUndefinedValues: true,
          },
        },
      );
    }

    return this.dynamoDBDocument;
  }

  async s3(): Promise<S3> {
    if (
      !this.s3Client ||
      (await this.authorizationService.isAuthTokenExpired())
    ) {
      this.s3Client = new S3({
        region: CONFIG.awsRegion,
        credentials: (await this.authorizationService.getSession()).credentials,
      });
    }

    return this.s3Client;
  }

  async iot(): Promise<IoT> {
    if (
      !this.iotClient ||
      (await this.authorizationService.isAuthTokenExpired())
    ) {
      const session = await this.authorizationService.getSession();

      this.iotClient = new IoT({
        region: CONFIG.awsRegion,
        credentials: session.credentials,
      });

      await this.iotClient.attachPolicy({
        policyName: CONFIG.iotPolicyName,
        target: session.identityId,
      });
    }

    return this.iotClient;
  }

  async iotData(): Promise<IoTDataPlane> {
    if (
      !this.iotDataClient ||
      (await this.authorizationService.isAuthTokenExpired())
    ) {
      this.iotDataClient = new IoTDataPlane({
        endpoint: CONFIG.iotEndpoint,
        region: CONFIG.awsRegion,
        credentials: (await this.authorizationService.getSession()).credentials,
      });
    }

    return this.iotDataClient;
  }

  async costExplorer(): Promise<CostExplorer> {
    if (
      !this.costExplorerClient ||
      (await this.authorizationService.isAuthTokenExpired())
    ) {
      this.costExplorerClient = new CostExplorer({
        region: 'us-east-1',
        credentials: (await this.authorizationService.getSession()).credentials,
      });
    }

    return this.costExplorerClient;
  }

  async lambda(): Promise<Lambda> {
    if (
      !this.lambdaClient ||
      (await this.authorizationService.isAuthTokenExpired())
    ) {
      this.lambdaClient = new Lambda({
        region: CONFIG.awsRegion,
        credentials: (await this.authorizationService.getSession()).credentials,
      });
    }

    return this.lambdaClient;
  }
}
