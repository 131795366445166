import { AuditLog } from '@common/audit-log/models/AuditLog';
import * as moment from 'moment';
import {
  CriteriaType,
  Firmware,
  NewS3Object,
  S3Object,
} from '../models/firmware';

export class SearchableFirmware extends Firmware {
  dateStr: string;
  brandAreaStr: string;
  public createdBy: string | undefined;

  constructor(
    id: string,
    version: string,
    type: string,
    thingType: string,
    date: string,
    activated: number,
    s3Key: S3Object,
    newS3Key: NewS3Object,
    releaseNote: string,
    criteriaType: CriteriaType,
    dateStr: string,
    brandAreaStr: string,
    createdBy?: string,
  ) {
    super(
      id,
      version,
      type,
      thingType,
      date,
      activated,
      s3Key,
      newS3Key,
      releaseNote,
      criteriaType,
    );
    this.dateStr = dateStr;
    this.brandAreaStr = brandAreaStr;
    this.createdBy = createdBy;
  }

  public static fromFirmware(
    fmw: Firmware,
    auditLog?: AuditLog,
  ): SearchableFirmware {
    const sfmw = fmw as SearchableFirmware;

    sfmw.dateStr = moment(fmw.date).format('YYYY-MM-DD HH:mm:ss');
    sfmw.brandAreaStr = fmw.getFirmwareBrandArea().join('|');
    sfmw.createdBy = auditLog?.userId;

    return sfmw;
  }
}
