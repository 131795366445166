import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-job-type-badge',
  templateUrl: './job-type-badge.component.html',
  styleUrls: ['./job-type-badge.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobTypeBadgeComponent {
  @Input() jobType?: string;
}
