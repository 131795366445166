<nav
  class="navbar navbar-expand navbar-light bg-white topbar mb-2 static-top shadow"
>
  <ul class="navbar-nav ml-auto">
    <li ngbDropdown class="nav-item dropdown no-arrow">
      <a
        ngbDropdownToggle
        class="nav-link dropdown-toggle"
        id="dropdown01"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span class="mr-2 d-inline text-gray-600 small btn">{{username}}</span>
      </a>
      <div
        ngbDropdownMenu
        class="dropdown-menu dropdown-menu-right shadow animated--grow-in"
        aria-labelledby="dropdown01"
      >
        <a class="dropdown-item" routerLink="/login" (click)="logout()"
          ><i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
          Logout</a
        >
      </div>
    </li>
  </ul>
</nav>
