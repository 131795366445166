import * as moment from 'moment';

export interface ProductLogS3Object {
  filename: string;
  lastModifiedDate: string;
  size: number;
}

export interface DeletedLog {
  success: boolean;
  filename: string;
}

export interface DeletedAllLogs {
  success: boolean;
}

export class ProductLog {
  readonly filename: string;
  readonly timestamp: Date;
  readonly displayDate: string;
  readonly bytes: number;
  readonly originThingnameOrMac: string;

  constructor(originThingNameOrMac: string, raw: ProductLogS3Object) {
    this.filename = raw.filename;
    const timestampMilliseconds = Number.parseInt(raw.filename, 10) * 1000;
    this.timestamp = new Date(timestampMilliseconds);
    this.displayDate = moment(timestampMilliseconds).format(
      'YYYY-MM-DD HH:mm:ss',
    );
    this.bytes = raw.size;
    this.originThingnameOrMac = originThingNameOrMac;
  }
}

export class DownloadedLog {
  readonly serialOrMac: string;
  readonly firmwareVersion: string;
  readonly logSource: string;
  readonly uuid: string;
  readonly timestamp: Date;
  readonly key: string;
  readonly logType: string;
  readonly logLevel: string;

  readonly contents: string;

  constructor(rawFile: string) {
    const lines = rawFile.split('\r\n');

    const [
      serialOrMac,
      firmwareVersion,
      logSource,
      uuid,
      timestampStr,
      key,
      logType,
      logLevel,
    ] = lines[0].split('#');

    this.serialOrMac = serialOrMac;
    this.firmwareVersion = firmwareVersion;
    this.logSource = logSource;
    this.uuid = uuid;
    this.timestamp = new Date(Number.parseInt(timestampStr, 10) * 1000);
    this.key = key;
    this.logType = logType;
    this.logLevel = logLevel;

    this.contents = lines.map((line) => line.split('#').pop()).join('\r\n');
  }

  getSavableBlob(): Blob {
    const headers: string = [
      `serial or mac: ${this.serialOrMac}`,
      `firmware version: ${this.firmwareVersion}`,
      `log source: ${this.logSource}`,
      `session id: ${this.uuid}`,
      `timestamp: ${this.timestamp.toISOString()}`,
      `log id: ${this.key}`,
      `log type: ${this.logType}`,
      `log level: ${this.logLevel}`,
      '================= contents below ===================',
      '',
    ].join('\r\n');

    return new Blob([headers, this.contents]);
  }
}
