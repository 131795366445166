import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { AwsService } from '../lib/aws.service';
import { GetStatisticsCommandOutput } from '@aws-sdk/client-iot';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IotStatisticsService {
  constructor(private aws: AwsService) {}

  getThingCount(params: {
    thingType?: string;
    connected?: boolean;
  }): Observable<number> {
    return from(this.aws.iot()).pipe(
      switchMap((iot) =>
        iot.getStatistics({
          queryString: this.buildQueryString(params),
        }),
      ),
      map(
        (response: GetStatisticsCommandOutput) =>
          response.statistics?.count ?? 0,
      ),
    );
  }

  private buildQueryString(params: {
    thingType?: string;
    connected?: boolean;
  }): string {
    const queryChunks: string[] = [];

    if (params.connected != null) {
      queryChunks.push(`connectivity.connected:${params.connected}`);
    }

    if (params.thingType) {
      queryChunks.push(`thingTypeName:${params.thingType}`);
    }

    return queryChunks.join(' AND ');
  }
}
