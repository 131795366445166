import { Directive } from '@angular/core';
import { AbstractGroupBasedDirective } from './abstract-group-based.directive';
import { FeatureGroupEnum } from '../feature-group.enum';

@Directive({
  selector: '[canDeleteProductLogs]',
  standalone: true,
})
export class DeleteProductLogsDirective extends AbstractGroupBasedDirective {
  protected override getSpecificGroup(): FeatureGroupEnum {
    return FeatureGroupEnum.DELETE_PRODUCT_LOGS;
  }
}
