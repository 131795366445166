import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DeviceMetaConsents } from '../../../../models/device-meta-consents';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConsentsService {
  private readonly backendUrl = environment.backendUrl;

  constructor(private readonly http: HttpClient) {}

  getConsents(thingName: string): Observable<DeviceMetaConsents> {
    return this.http.get<DeviceMetaConsents>(
      `${this.backendUrl}/device-consents/${thingName}`,
    );
  }
}
