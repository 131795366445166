<div class="card shadow">
  <div class="card-header">
    <h1>Delete group</h1>
  </div>
  <div class="card-body">
    <div class="d-flex flex-row text-break">
      <i
        class="warning-icon fas fa-exclamation-triangle"
        aria-hidden="true"
      ></i>
      <div>
        Delete group <strong>{{ group?.groupName }}</strong> ?<br />This cannot
        be undone.
      </div>
    </div>
  </div>
  <div class="card-footer d-flex flex-row w-100 justify-content-end">
    <button
      class="btn btn-outline-secondary mr-2"
      (click)="cancel()"
      [disabled]="deleting"
    >
      <i class="fas fa-times mr-1" aria-hidden="true"></i>
      Cancel
    </button>
    <button class="btn btn-danger" (click)="delete()" [disabled]="deleting">
      <i class="fas fa-trash-alt mr-1" aria-hidden="true"></i>
      Delete
      <app-spinner-small *ngIf="deleting"></app-spinner-small>
    </button>
  </div>
</div>
