import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Forbidden403Component } from './403/403.component';
import { AfterLoginRedirectionComponent } from './after-login-redirection/after-login-redirection.component';
import { BillingComponent } from './billing/billing.component';
import { DeployementsComponent } from './deployements/deployements.component';
import { DeploymentComponent } from './deployment/deployment.component';
import { FactoryFirmwareCreateComponent } from './factory-firmwares/factory-firmware-create/factory-firmware-create.component';
import { FactoryFirmwareDetailComponent } from './factory-firmwares/factory-firmware-detail/factory-firmware-detail.component';
import { FactoryFirmwaresComponent } from './factory-firmwares/factory-firmwares.component';
import { FirmwarefileComponent } from './firmwarefile/firmwarefile.component';
import { FirmwaresComponent } from './firmwares/firmwares.component';
import { GroupOfThingsDetailsComponent } from './groups-of-things/group-of-things-details/group-of-things-details.component';
import { GroupsOfThingsListComponent } from './groups-of-things/groups-of-things-list/groups-of-things-list.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { MetaversionComponent } from './metaversion/metaversion.component';
import { MetaversionsComponent } from './metaversions/metaversions.component';
import { NewFirmwareComponent } from './new-firmware/new-firmware.component';
import { AuthResolver } from './routing/resolvers/auth-resolver';
import { roleAuthGuard } from './routing/guards/role-auth/role-auth.guard';
import { SearchComponent } from './search/search.component';
import { FeatureGroupEnum } from './shared/user-rights-management/feature-group.enum';
import { StatisticsComponent } from './statistics/statistics.component';
import { ThingComponent } from './thing/thing.component';
import { UserManifestComponent } from './user-manifest/user-manifest.component';
import { loginActivateGuard } from './routing/guards/login-activate/login-activate.guard';

export const routes: Routes = [
  {
    path: 'loading',
    component: AfterLoginRedirectionComponent,
    data: { title: 'Redirecting' },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'login' },
    canActivate: [loginActivateGuard],
  },
  {
    path: '403',
    component: Forbidden403Component,
    data: { title: 'Forbidden' },
  },
  {
    path: '',
    canActivate: [loginActivateGuard],
    resolve: { auth: AuthResolver },
    children: [
      {
        path: '',
        component: HomeComponent,
        data: { title: 'Home' },
        canActivate: [roleAuthGuard(FeatureGroupEnum.SEE_THINGS_HOME)],
      },
      {
        path: 'search',
        component: SearchComponent,
        data: { title: 'Search' },
        canActivate: [roleAuthGuard(FeatureGroupEnum.SEARCH_THING)],
      },
      {
        path: 'metaversions',
        component: MetaversionsComponent,
        data: { title: 'Metaversions' },
        canActivate: [roleAuthGuard(FeatureGroupEnum.LIST_METAVERSIONS)],
      },
      {
        path: 'metaversions/group/:groupId',
        component: MetaversionsComponent,
        data: { title: 'Group Metaversions', titleRouteParam: 'groupId' },
        canActivate: [
          roleAuthGuard(FeatureGroupEnum.DEPLOY_ON_GROUP_OF_THINGS),
        ],
      },
      {
        path: 'metaversions/thing/:thingName',
        component: MetaversionsComponent,
        data: { title: 'Thing Metaversions', titleRouteParam: 'thingName' },
        canActivate: [roleAuthGuard(FeatureGroupEnum.DEPLOY_ON_ONE_THING)],
      },
      {
        path: 'metaversions/:metaversionId',
        component: MetaversionComponent,
        data: { title: 'Metaversion ', titleRouteParam: 'metaversionId' },
        canActivate: [roleAuthGuard(FeatureGroupEnum.SEE_METAVERSION_DETAILS)],
      },
      {
        path: 'firmwares',
        component: FirmwaresComponent,
        data: { title: 'Firmwares' },
        canActivate: [roleAuthGuard(FeatureGroupEnum.LIST_FIRMWARES)],
      },
      {
        path: 'firmwarefile/:firmwarefileId',
        component: FirmwarefileComponent,
        data: { title: 'Firmware', titleRouteParam: 'firmwarefileId' },
        canActivate: [roleAuthGuard(FeatureGroupEnum.SEE_FIRMWARE_DETAILS)],
      },
      {
        path: 'deployments',
        component: DeployementsComponent,
        data: { title: 'Deployments' },
        canActivate: [roleAuthGuard(FeatureGroupEnum.LIST_DEPLOYMENTS)],
      },
      {
        path: 'deployments/:jobId',
        component: DeploymentComponent,
        data: { title: 'Deployment', titleRouteParam: 'jobId' },
        canActivate: [roleAuthGuard(FeatureGroupEnum.SEE_DEPLOYMENT_DETAILS)],
      },
      {
        path: 'statistics',
        component: StatisticsComponent,
        data: { title: 'Statistics' },
        canActivate: [roleAuthGuard(FeatureGroupEnum.SEE_STATISTICS)],
      },
      {
        path: 'things/:deviceId',
        component: ThingComponent,
        data: { title: 'Device', titleRouteParam: 'deviceId' },
        canActivate: [roleAuthGuard(FeatureGroupEnum.SEE_THING_DETAILS)],
      },
      {
        path: 'newfirmware',
        component: NewFirmwareComponent,
        data: { title: 'New Metaversion' },
        canActivate: [roleAuthGuard(FeatureGroupEnum.CREATE_EDIT_METAVERSION)],
      },
      {
        path: 'billing',
        component: BillingComponent,
        canActivate: [roleAuthGuard(FeatureGroupEnum.SEE_BILLING)],
        data: { title: 'Billing' },
      },
      {
        path: 'groups',
        component: GroupsOfThingsListComponent,
        canActivate: [roleAuthGuard(FeatureGroupEnum.LIST_GROUP_OF_THINGS)],
        data: { title: 'Groups' },
      },
      {
        path: 'groups/:groupId',
        component: GroupOfThingsDetailsComponent,
        canActivate: [
          roleAuthGuard(FeatureGroupEnum.SEE_GROUP_OF_THINGS_DETAILS),
        ],
        data: { title: 'Group', titleRouteParam: 'groupId' },
      },
      {
        path: 'factory-firmwares',
        component: FactoryFirmwaresComponent,
        canActivate: [roleAuthGuard(FeatureGroupEnum.LIST_FACTORY_FIRMWARES)],
        data: { title: 'Factory firmwares' },
      },
      {
        path: 'factory-firmwares/new',
        component: FactoryFirmwareCreateComponent,
        canActivate: [
          roleAuthGuard(FeatureGroupEnum.CREATE_DELETE_FACTORY_FIRMWARE),
        ],
        data: { title: 'Create factory firmware' },
      },
      {
        path: 'factory-firmwares/:version',
        component: FactoryFirmwareDetailComponent,
        canActivate: [
          roleAuthGuard(FeatureGroupEnum.SEE_FACTORY_FIRMWARE_DETAILS),
        ],
        data: { title: 'Factory firmwares', titleRouteParam: 'version' },
      },
      {
        path: 'manifest',
        component: UserManifestComponent,
        canActivate: [roleAuthGuard(FeatureGroupEnum.SEE_MANIFEST)],
        data: { title: 'User Manifest' },
      },
    ],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
