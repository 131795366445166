<div class="card shadow mb-4">
  <div
    class="card-header d-flex flex-row justify-content-between align-items-center"
  >
    <span class="d-flex flex-row align-items-center">
      <h6 class="m-0 font-weight-bold text-primary">Log files</h6>
      <button class="btn btn-primary btn-sm ml-3" (click)="refreshFiles()">
        <i class="fas fa-sync" aria-hidden="true"></i>
      </button>
      <button
        class="btn btn-danger btn-sm ml-3"
        (click)="purgeLogs()"
        *canDeleteProductLogs
      >
        Purge logs <i class="fas fa-trash" aria-hidden="true"></i>
      </button>
    </span>
    <span *ngIf="autoRefreshFiles"
      >This screen will auto-refresh every 5s while logs are enabled on this
      product.</span
    >
    <div
      class="d-flex flex-column align-items-end"
      *ngIf="shadow$ | async as shadow"
    >
      <span class="form-check form-switch" *canEnableProductLogs>
        <input
          type="checkbox"
          id="logs-enable"
          [formControl]="_enableLogsControl"
          class="form-check-input"
        />
        <label for="logs-enable">Enable logs for this product</label>
      </span>
      <p class="hint mb-0">
        &nbsp;
        <!-- Keep an nbsp in the <p> tag at all times to avoid layout flickering when the hint is rendered -->
        <ng-container *ngIf="desiredAndReportedAreDifferent(shadow)"
          ><app-spinner-small class="mr-2"></app-spinner-small>Waiting for
          product confirmation...</ng-container
        >
      </p>
    </div>
  </div>
  <!-- Begin content -->
  <div class="card-body">
    <div class="table-responsive">
      <app-logs-list
        [thingname]="thingname"
        [mac]="mac"
        [autoRefresh]="autoRefreshFiles"
        [refresh$]="_refreshFiles$"
      ></app-logs-list>
    </div>
  </div>
</div>
