import { Injectable } from '@angular/core';
import { MetaVersion } from '../../../../models/metaversion/meta-version';
import { Observable } from 'rxjs';
import { CreateMetaversionRequestBody } from '../../../../models/backend/metaversion/create-metaversion-request-body';
import { CreateMetaversionResult } from '../../../../models/backend/metaversion/create-metaversion-result';
import { map } from 'rxjs/operators';
import { getPriorityFromString } from '../../../../models/backend/metaversion/priority';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MetaversionService {
  private readonly backendUrl = environment.backendUrl;

  constructor(private readonly http: HttpClient) {}

  /**
   * Creates a metaversion by calling the dedicated backend endpoint
   *
   * @returns the created metaversion id
   */
  createMetaversion(metaversion: MetaVersion): Observable<string> {
    const requestBody: CreateMetaversionRequestBody = {
      thingType: metaversion.thingType,
      validationAccepted: metaversion.validationAccepted ?? '',
      criteriaType: metaversion.uiFirmware?.criteriaType,
      updateValidation: metaversion.updateValidation,
      priority: getPriorityFromString(metaversion.priority),
      wifiFirmwareId: metaversion.wifiFirmware?.id,
      uiFirmwareId: metaversion.uiFirmware?.id,
    };

    return this.http
      .put<CreateMetaversionResult>(
        `${this.backendUrl}/metaversions/${metaversion.id}`,
        requestBody,
      )
      .pipe(map((res) => res.metaversionId));
  }
}
