import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-table-export',
  templateUrl: './table-export.component.html',
  styleUrls: ['./table-export.component.scss'],
})
export class TableExportComponent {
  @Input() fileName?: string;

  @Input() tables: {
    table: HTMLTableElement;
    name: string;
  }[] = [];

  exportCsv(): void {
    let csv = '';

    this.tables.forEach((table) => {
      csv += `${table.name}\r\n`;
      csv += XLSX.utils.sheet_to_csv(XLSX.utils.table_to_sheet(table.table));
    });

    FileSaver.saveAs(
      new Blob([csv], { type: 'text/csv' }),
      this.fileName + '_' + new Date().toISOString() + '.csv',
    );
  }

  exportExcel(): void {
    const workbook = XLSX.utils.book_new();

    this.tables.forEach((table) => {
      XLSX.utils.book_append_sheet(
        workbook,
        XLSX.utils.table_to_sheet(table.table),
        table.name,
      );
    });

    const excelBuffer: BlobPart = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer);
  }

  saveAsExcelFile(buffer: BlobPart): void {
    const data: Blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    FileSaver.saveAs(
      data,
      this.fileName + '_' + new Date().toISOString() + '.xlsx',
    );
  }

  enabled(): boolean {
    if (!this.tables?.length) {
      return false;
    }

    for (const table of this.tables) {
      if (table.table) {
        return true;
      }
    }

    return false;
  }
}
