import { Component, Input } from '@angular/core';
import { FactoryFirmwaresService } from '../../../api/backend/services/factory-firmwares/factory-firmwares.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../../shared/notification.service';
import { ServiceException } from '@smithy/smithy-client';

@Component({
  selector: 'app-factory-firmware-delete',
  templateUrl: './factory-firmware-delete.component.html',
  styleUrls: ['./factory-firmware-delete.component.scss'],
})
export class FactoryFirmwareDeleteComponent {
  @Input() version?: string;
  @Input() thingType?: string;

  deleting = false;

  constructor(
    private readonly factoryFirmwaresService: FactoryFirmwaresService,
    private readonly modal: NgbActiveModal,
    private readonly notif: NotificationService,
  ) {}

  cancel(): void {
    this.modal.close(false);
  }

  delete(): void {
    if (!this.version || !this.thingType) {
      return;
    }

    this.deleting = true;

    this.factoryFirmwaresService
      .deleteFactoryFirmware(this.thingType, this.version)
      .subscribe(
        () => this.modal.close(true),
        (err: ServiceException) => {
          this.modal.close(false);
          this.notif.showError(
            `Error deleting factory firmware : ${err.message}`,
            err,
          );
        },
      );
  }
}
