import { Pipe, PipeTransform } from '@angular/core';
import { Shadow } from '../../models/shadow';
import { UtilsService } from '../../lib/utils.service';

@Pipe({
  name: 'displayShadowLastConnection',
})
export class DisplayShadowLastConnectionPipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}

  transform(json?: string | null): string {
    if (!json) {
      return '-';
    }

    const shadow = new Shadow(JSON.parse(json));
    return this.utilsService.formatDate(shadow.lastConnection());
  }
}
