import { Pipe, PipeTransform } from '@angular/core';
import { CriteriaKey, Firmware } from '../../models/firmware';

@Pipe({
  name: 'displayFirmwareSigningError',
})
export class DisplayFirmwareSigningErrorPipe implements PipeTransform {
  transform(firmware: Firmware, criteriaKey?: string): unknown {
    if (firmware) {
      return firmware.getSigningError(criteriaKey as CriteriaKey);
    }
    return '';
  }
}
