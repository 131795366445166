import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, of, Subject, switchMap, timer } from 'rxjs';
import { catchError, filter, shareReplay, tap } from 'rxjs/operators';
import { ThingsService } from '../../api/backend/services/things/things.service';
import { Shadow } from '../../models/shadow';
import { DeleteProductLogDialogComponent } from '../../shared/delete-product-log-dialog/delete-product-log-dialog.component';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-product-logs',
  templateUrl: './product-logs.component.html',
  styleUrls: ['./product-logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductLogsComponent implements OnInit, OnDestroy {
  @Input() thingname?: string;
  @Input() mac?: string;
  shadow$?: Observable<Shadow>;

  _enableLogsControl = new FormControl<boolean>(false, { nonNullable: true });

  _refreshFiles$ = new Subject<void>();
  autoRefreshFiles = false;
  private autoRefreshStatus = true;

  constructor(
    private readonly thingService: ThingsService,
    private readonly notif: NotificationService,
    private readonly modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    const shadowUpdated = this._enableLogsControl.valueChanges.pipe(
      tap(() => {
        this._enableLogsControl.disable({ emitEvent: false });
      }),
      switchMap((enablement) => {
        if (!this.thingname) {
          throw new Error('Missing thingname');
        }

        return this.thingService
          .updateThingShadow(this.thingname, { logUpload: enablement })
          .pipe(
            catchError((err) => {
              this.notif.showError('Error enabling logs', err);
              return of([]);
            }),
          );
      }),
      tap(() => this._enableLogsControl.enable({ emitEvent: false })),
      shareReplay(1),
    );

    this.shadow$ = merge(
      timer(0, 5000).pipe(filter(() => this.autoRefreshStatus)),
      shadowUpdated,
    ).pipe(
      switchMap(() => {
        if (!this.thingname) {
          throw new Error('Missing thingname');
        }

        return this.thingService.getThingShadow(this.thingname);
      }),
      catchError((err) => {
        this.notif.showError('Error loading thing shadow', err);
        return of(new Shadow({}));
      }),
      tap((shadow) => {
        this.autoRefreshStatus = this.desiredAndReportedAreDifferent(shadow);
        this.autoRefreshFiles = shadow.logUploadReported();

        this._enableLogsControl.setValue(shadow.logUploadDesired(), {
          emitEvent: false,
        });
      }),
      shareReplay(1),
    );
  }

  desiredAndReportedAreDifferent(shadow: Shadow | null): boolean {
    if (!shadow) {
      return false;
    }

    return (
      (shadow.logUploadDesired() && !shadow.logUploadReported()) ||
      (!shadow.logUploadDesired() && shadow.logUploadReported())
    );
  }

  ngOnDestroy(): void {
    this._refreshFiles$.complete();
  }

  refreshFiles(): void {
    this._refreshFiles$.next();
  }

  purgeLogs(): Promise<void> {
    if (!this.thingname || !this.mac) {
      throw new Error('Missing thingname or mac');
    }

    const modalInstance = this.modalService.open(
      DeleteProductLogDialogComponent,
      {
        ariaLabelledBy: 'modal-basic-title',
        backdrop: 'static',
        centered: true,
      },
    );

    modalInstance.componentInstance.thingName = this.thingname;
    modalInstance.componentInstance.mac = this.mac;

    return modalInstance.result.then(() => {
      this.refreshFiles();
    });
  }
}
