import { Component } from '@angular/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent {
  hasLowercaseChar(str: string): boolean {
    const regex = /(?=.*[a-z])/;
    return regex.test(str);
  }

  hasUppercaseChar(str: string): boolean {
    const regex = /(?=.*[A-Z])/;
    return regex.test(str);
  }

  hasNumber(str: string): boolean {
    const regex = /(?=.*\d)/;
    return regex.test(str);
  }

  hasSymbol(str: string): boolean {
    const regex = /(?=.*\W)/;
    return regex.test(str);
  }
}
