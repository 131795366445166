@for (file of files; track file.name; let last = $last) {
  <div class="d-inline-block mr-1">
    <small>{{ file.type | uppercase }} {{ file.version }}</small>

    @if (
      firmwares[getFirmwareIdFromFile(file, thingTypeName)]?.versionFlag ===
      VersionFlag.OFFICIAL
    ) {
      <span class="badge text-bg-success ml-1">Official</span>
    }

    @if (!last) {
      <span class="ml-1">|</span>
    }
  </div>
}
