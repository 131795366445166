import { Component, Input } from '@angular/core';
import { AuditAction, AuditType } from '@common/audit-log/models/AuditLog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuditService } from '../../../api/backend/services/audit/audit.service';
import { GroupsOfThingsService } from '../../../api/groups-of-things.service';
import { GroupOfThings } from '../../../models/Group-of-things.model';
import { ThingData } from '../../../models/thingtype';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-remove-thing-from-group',
  templateUrl: './remove-thing-from-group.component.html',
  styleUrls: ['./remove-thing-from-group.component.scss'],
})
export class RemoveThingFromGroupComponent {
  @Input() thing?: ThingData;
  @Input() group?: GroupOfThings;

  deleting = false;

  constructor(
    private readonly groupsService: GroupsOfThingsService,
    private readonly modal: NgbActiveModal,
    private readonly notif: NotificationService,
    private readonly auditService: AuditService,
  ) {}

  cancel(): void {
    this.modal.close(undefined);
  }

  delete(): void {
    if (!this.group || !this.thing) {
      return;
    }

    this.deleting = true;

    this.groupsService.removeFromGroup(this.group, this.thing).subscribe(
      () => {
        this.auditService.pushEvent({
          type: AuditType.THING_GROUP,
          action: AuditAction.REMOVE_THING,
          resourceId: this.group?.groupId,
          additionalData: {
            thingname: this.thing?.thingName,
            thing_group_name: this.group?.groupName,
          },
        });
        this.deleting = false;
        this.notif.showSuccess('Thing removed from group');
        this.modal.close(this.thing);
      },
      (err: Error) => {
        this.deleting = false;
        console.error(`Error deleting thing from Group : ${err.message}`, err);
        this.notif.showError(
          `Error deleting thing from Group : ${err.message}`,
          err,
        );
      },
    );
  }
}
