import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { AWSCost, AWSCosts } from '../../../models/AWSCost';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-recalculated-per-year-chart',
  templateUrl: './recalculated-per-year-chart.component.html',
  styleUrls: ['./recalculated-per-year-chart.component.css'],
})
export class RecalculatedPerYearChartComponent implements OnChanges {
  @Input() costs?: AWSCosts;

  _chartData: ChartDataset[] = [];

  readonly _chartOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        mode: 'index',
        callbacks: {
          label(tooltipItem): string {
            let label = tooltipItem.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (typeof tooltipItem.raw === 'number') {
              label += tooltipItem.raw.toFixed(2);
            } else {
              label += tooltipItem.raw;
            }

            label += ' $';

            return label;
          },
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          boxHeight: 0,
        },
      },
    },
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.costs?.currentValue?.costs) {
      this._chartData = changes.costs.currentValue.costs.map(
        (cost: AWSCost) => ({
          label: cost.key,
          data: cost.monthCosts,
          fill: false,
        }),
      );
    }
  }
}
