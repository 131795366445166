import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ThingsService } from '../../api/backend/services/things/things.service';
import { NotificationService } from '../notification.service';

@Component({
  selector: 'app-delete-product-log-dialog',
  templateUrl: './delete-product-log-dialog.component.html',
  styleUrls: ['./delete-product-log-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteProductLogDialogComponent {
  @Input() thingName?: string;
  @Input() mac?: string;
  @Input() logfile?: string;

  deleting = false;

  constructor(
    private readonly thingService: ThingsService,
    private readonly modal: NgbActiveModal,
    private readonly notif: NotificationService,
  ) {}

  cancel(): void {
    this.modal.close(false);
  }

  confirm(): void {
    if (this.deleting) {
      return;
    }

    if (!this.thingName && !this.mac) {
      throw new Error('Missing thing name & mac address');
    }

    this.deleting = true;

    const deleteObservable$ = this.logfile
      ? this.thingService.deleteLog(this.thingName, this.mac, this.logfile)
      : this.thingService.purgeLogs(this.thingName, this.mac);

    deleteObservable$
      .pipe(
        catchError((err) => {
          this.notif.showError('Error while deleting log', err);
          return of(false);
        }),
      )
      .subscribe((deleted) => {
        this.deleting = false;
        this.modal.close(deleted);
      });
  }
}
