import { Location } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuditAction,
  AuditType,
  MappedAuditLog,
} from '@common/audit-log/models/AuditLog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Columns, Config, DefaultConfig, Event } from 'ngx-easy-table';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { shareReplay, take } from 'rxjs/operators';
import { AuditService } from '../api/backend/services/audit/audit.service';
import { DeploymentsService } from '../api/backend/services/deployments/deployments.service';
import { DatabaseService } from '../api/database.service';
import { RegistryService } from '../api/registry.service';
import { ThingTypesService } from '../api/thing-types.service';
import { NgxEvent, NgxSortEventValue } from '../generic/ngx/ngx-event.model';
import { UtilsService } from '../lib/utils.service';
import { BrandArea, BrandAreaKey } from '../models/brandarea';
import { CriteriaKey, S3Object } from '../models/firmware';
import { GroupOfThings } from '../models/Group-of-things.model';
import { JobType } from '../models/meta-version-job.model';
import { MetaVersion } from '../models/metaversion/meta-version';
import { ThingListDisplay } from '../models/thing-list-display.model';
import { ThingData, ThingType } from '../models/thingtype';
import { NotificationService } from '../shared/notification.service';
import { TitleService } from '../shared/title.service';
import { SearchableMetaversion } from './SearchableMetaversion';
import { VersionFlag } from '../models/backend/firmware/version-flag.enum';

@Component({
  selector: 'app-metaversions',
  templateUrl: './metaversions.component.html',
  styleUrls: ['./metaversions.component.css'],
})
export class MetaversionsComponent implements OnInit, OnDestroy {
  protected readonly VersionFlag = VersionFlag;

  @ViewChild('content') content?: ElementRef;
  @ViewChild('deployModal') deployModal?: ElementRef;

  routerState?: { group?: GroupOfThings; things?: ThingListDisplay[] };

  isLoading = false;
  activated?: number;

  deployType: JobType = 'MULTI';
  thingType?: ThingType;

  thingName?: string;
  things?: ThingData[];

  groupId?: string;
  group?: GroupOfThings;

  metaVersions: SearchableMetaversion[] = [];
  globalSearchInput = new UntypedFormControl('');
  activeMetaversionControl = new UntypedFormControl(true);

  // ngx configuration
  configuration: Config = {
    ...DefaultConfig,
    searchEnabled: true,
    paginationEnabled: true,
    rows: 10,
  };
  columns: Columns[] = [
    { key: 'dateStr', title: 'Creation Date', width: '8%' },
    { key: 'thingType', title: 'Thing Type', width: '7%' },
    { key: 'id', title: 'Name' },
    { key: 'criteriaType', title: 'Criteria', width: '5%' },
    { key: 'firmwareFiles', title: 'Firmware Files', width: '10%' },
    {
      key: 'enabledDate',
      title: 'Deploy On New Devices',
      width: '5%',
      searchEnabled: false,
    },
    { key: 'createdBy', title: 'Creator', width: '5%' },
    {
      key: 'actions',
      title: 'Actions',
      orderEnabled: false,
      searchEnabled: false,
      width: '10%',
    },
  ];

  formGroupLocalFilters = new UntypedFormGroup({
    dateStr: new UntypedFormControl(),
    thingType: new UntypedFormControl(null),
    id: new UntypedFormControl(),
    criteria: new UntypedFormControl(null),
    firmwareFiles: new UntypedFormControl(),
    enabledState: new UntypedFormControl(null),
    createdBy: new UntypedFormControl(null),
  });

  thingTypes$?: Observable<string[]>;

  private destroyed$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly metaversionService: RegistryService,
    private readonly notif: NotificationService,
    private readonly databaseService: DatabaseService,
    private readonly thingTypesService: ThingTypesService,
    private readonly deploymentService: DeploymentsService,
    private readonly modalService: NgbModal,
    private readonly location: Location,
    private readonly utilsService: UtilsService,
    private readonly titleService: TitleService,
    private readonly auditService: AuditService,
  ) {
    this.routerState = this.router.getCurrentNavigation()?.extras?.state;
  }

  ngOnInit(): void {
    this.setIsLoading(true);

    this.deployType = 'MULTI';
    this.group = undefined;
    this.things = [];

    this.thingTypes$ = this.thingTypesService
      .getThingTypes()
      .pipe(shareReplay(1));

    this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      if (params?.showInactive === 'true') {
        this.activeMetaversionControl.setValue(false);
      }
    });

    this.activatedRoute.paramMap.pipe(take(1)).subscribe(async (params) => {
      if (params.get('thingName')) {
        this.deployType = 'SINGLE';
        this.thingName = params.get('thingName') as string;
        this.things = [await this.getThing(this.thingName)];
        this.thingType = this.things[0].thingType;
      } else if (params.get('groupId')) {
        this.deployType = 'GROUP';
        this.groupId = params.get('groupId') as string;
        this.group = this.routerState?.group;
        this.things =
          this.routerState?.things?.map(ThingData.fromThingListDisplay) ?? [];

        if (this.group == null || !this?.things?.length) {
          this.utilsService.redirect(`/groups/${this.groupId}`); // can't use router in ngOnInit
          return;
        }

        this.titleService.replaceInTitle(
          this.group.groupId,
          this.group.groupName,
        );

        this.thingType = this.group.thingType;
      }

      this.search().catch((err: Error) =>
        this.notif.showError(err.message, err),
      );
    });

    this.activeMetaversionControl.valueChanges.subscribe((val) => {
      this.search().catch((err: Error) =>
        this.notif.showError(err.message, err),
      );
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          showInactive: !val || null,
          page: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
        state: this.routerState,
      });
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  resetFilter(): void {
    this.globalSearchInput.setValue('');
    this.globalSearchInput.markAsPristine();

    // Use timeouts to reset each category of filters after some time to prevent query params conflicts
    setTimeout(() => this.formGroupLocalFilters.reset(), 50);
    setTimeout(() => {
      if (!this.activeMetaversionControl.value) {
        this.activeMetaversionControl.setValue(true);
        this.activeMetaversionControl.markAsPristine();
      }
    }, 100);
  }

  /**
   * Filters the MetaVersions depending on the Things' criteria keys
   *
   * @private
   */
  public async filterResultsForThings(): Promise<void> {
    if (!this?.things?.length) {
      return;
    }

    const brandAreas = (await this.databaseService.listBrandAreas()).filter(
      (_ba) => _ba.thingType === this.thingType,
    );

    let criteriaKeys: CriteriaKey[] = [];
    try {
      criteriaKeys = this.things.map((_thing) =>
        this.buildCriteriaKey(_thing, brandAreas),
      );
    } catch (err) {
      console.error(err);
      this.notif.showError((err as Error).message, err);
      this.location.back();
    }

    // using a Set to make the criteria keys unique when several Things have the same attributes
    const uniqueCriteriaKeys = [...new Set(criteriaKeys)];

    // checks if it is a deployment on a Group with a criteria of "ThingType",
    // which means there is no need to filter MetaVersions other than by their ThingType
    const isAThingTypeDeploymentForGroup =
      this.deployType === 'GROUP' && this.group?.criteriaType === 'THINGTYPE';

    try {
      this.metaVersions = this.metaVersions.filter(
        (_metaversion) =>
          _metaversion.thingType === this.thingType &&
          (isAThingTypeDeploymentForGroup ||
            uniqueCriteriaKeys.every((_uck) =>
              _metaversion.doesAnyFirmwareLooselyMatchCriteriaKey(_uck),
            )),
      );
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  /**
   * Builds the criteria key from
   *
   * @param _thing
   * @param brandAreas
   * @throws Error if the Thing doesn't have Brand Area values when its Thing Type requires them
   */
  buildCriteriaKey(_thing: ThingData, brandAreas: BrandArea[]): CriteriaKey {
    let thingBrandArea: BrandAreaKey = 'NA' as BrandAreaKey;
    if (_thing?.attributes?.area && _thing?.attributes?.brand) {
      thingBrandArea =
        `${_thing.attributes.brand}.${_thing.attributes.area}` as BrandAreaKey;
    }

    if (brandAreas.length !== 0 && thingBrandArea === 'NA') {
      const errMsg = `Upgrading of ${_thing.thingName} not available. Brand-Area still not set for this product.`;
      throw new Error(errMsg);
    }

    const range = _thing?.attributes?.range;
    const cmmf = _thing?.attributes?.CMMF;
    const techIncrement = _thing?.attributes?.technicalIncrement;

    return S3Object.buildCriteriaKey(
      range,
      cmmf,
      techIncrement,
      thingBrandArea,
    );
  }

  async deployMetaVersionOnThings(
    metaversion: MetaVersion,
    modalRef: TemplateRef<unknown>,
  ): Promise<void> {
    this.modalService
      .open(modalRef, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(async (pressedDeploy) => {
        if (!pressedDeploy) {
          return;
        }

        if (!this?.things?.length || !metaversion.id) {
          return;
        }

        this.setIsLoading(true);

        this.deploymentService
          .startDeploymentJob(
            metaversion.id,
            this.things.map((thing) => thing.thingName),
            this.groupId,
          )
          .subscribe(
            (createdJobId: string): void => {
              if (!this?.things?.length) {
                return;
              }

              this.notif.showSuccess(
                `Deployment in progress, Job id is ${createdJobId}`,
              );
              if (this.deployType === 'SINGLE') {
                this.router.navigateByUrl(`/things/${this.thingName}`);
              } else if (this.deployType === 'GROUP') {
                this.router.navigateByUrl(`/groups/${this.groupId}`);
              }
            },
            (err) => {
              this.notif.showError(err?.message ?? 'An error occurred', err);
            },
          );
      })
      .catch((e) => {
        if (e?.message) {
          this.notif.showError((e as Error).message, e);
        }
      })
      .finally(() => {
        this.setIsLoading(false);
      });
  }

  async toggleMetaVersion(b: boolean, id: string): Promise<void> {
    await this.databaseService.updateDateMetaVersion(id, b);
    this.auditService.pushEvent({
      type: AuditType.METAVERSION,
      action: b ? AuditAction.DISABLE : AuditAction.ENABLE,
      resourceId: id,
    });
    await this.search();
  }

  async deleteMetaVersion(
    id: string,
    modalContent: TemplateRef<unknown>,
  ): Promise<void> {
    try {
      await this.modalService
        .open(modalContent, { ariaLabelledBy: 'modal-basic-title' })
        .result.then(async (result) => {
          if (result) {
            await this.databaseService.updateMetaVersion(id, 0);
            this.notif.showSuccess(
              `Firmware ${id} successfully set as deprecated`,
            );
            this.auditService.pushEvent({
              type: AuditType.METAVERSION,
              action: AuditAction.DEPRECATE,
              resourceId: id,
            });
            await this.toggleMetaVersion(true, id);
          }
        });
    } catch (e) {
      if (e && (e as Error).message) {
        this.notif.showError((e as Error).message, e);
      }
    }
  }

  async activateMetaVersion(
    id: string,
    modalContent: TemplateRef<unknown>,
  ): Promise<void> {
    try {
      this.modalService
        .open(modalContent, { ariaLabelledBy: 'modal-basic-title' })
        .result.then(async (result) => {
          if (result) {
            await this.databaseService.updateMetaVersion(id, 1);
            this.notif.showSuccess(`Firmware ${id} successfully set as active`);
            await this.search();
          }
        });
    } catch (e) {
      this.notif.showError((e as Error).message, e);
    }
  }

  setIsLoading(val: boolean): void {
    this.isLoading = val;
    if (this.configuration) {
      this.configuration.isLoading = val;
    }
  }

  ngxEventEmitted($event: NgxEvent): void {
    if ($event.event === Event.onOrder) {
      const value = $event.value as NgxSortEventValue;

      const ascending = value.order === 'asc';
      if (value.key === 'firmwareFiles') {
        this.sortByFirmwareFiles(ascending);
      }
    }
  }

  sortByFirmwareFiles(asc: boolean): void {
    const sortedMetaversions = [...this.metaVersions].sort(
      MetaVersion.compareFirmwareFiles(asc),
    );
    this.metaVersions = [...sortedMetaversions];
  }

  convertToSearchableMetaVersion(
    metaVersions: MetaVersion[],
    auditLogs?: MappedAuditLog,
  ): SearchableMetaversion[] {
    return metaVersions.map((_metaversion) => {
      const id = _metaversion.id as string;
      return SearchableMetaversion.fromMetaVersion(
        _metaversion,
        auditLogs?.[id],
      );
    });
  }

  /**
   * Allows IDEs to correctly infer the object type to provide completion and checks in HTML template
   */
  typedSearchableMetaversion(
    metaversion: SearchableMetaversion,
  ): SearchableMetaversion {
    return metaversion;
  }

  private async search(): Promise<void> {
    this.setIsLoading(true);
    const searchedMetaversions = await this.databaseService.listMetaVersions(
      this.activeMetaversionControl.value ? 1 : 0,
    );

    const resourceIds = searchedMetaversions
      .map((m) => m.id)
      .filter((id) => id !== undefined) as string[];

    const latestEvents = await lastValueFrom(
      this.auditService.getLatestEvent(
        AuditType.METAVERSION,
        AuditAction.CREATE,
        resourceIds,
      ),
    );

    this.metaVersions = this.convertToSearchableMetaVersion(
      searchedMetaversions,
      latestEvents,
    );

    await this.filterResultsForThings();

    this.activated = this.activeMetaversionControl.value ? 1 : 0;
    this.setIsLoading(false);
  }

  private getThing(thingName: string): Promise<ThingData> {
    this.setIsLoading(true);

    const getThingPromise = this.metaversionService
      .getThing(thingName)
      .then(ThingData.fromDescribeResponse);

    getThingPromise
      .catch((e) => {
        console.error((e as Error).message);
        this.notif.showError(`Unknown thing : ${thingName}`, e);
      })
      .finally(() => {
        this.setIsLoading(false);
      });

    return getThingPromise;
  }
}
