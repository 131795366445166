import CONFIG from '../../config';
import { AwsService } from '../lib/aws.service';
import { Injectable } from '@angular/core';
import { from, Observable, switchMap } from 'rxjs';
import { AuthSession } from 'aws-amplify/auth';
import { UpdateCommandOutput } from '@aws-sdk/lib-dynamodb';

@Injectable({
  providedIn: 'root',
})
export class UserLoginHistoryService {
  constructor(private aws: AwsService) {}

  /**
   * Adds/Updates a line in ConsoleUserLoginHistory dynamo table to set the last connection time
   *
   * @param userSession the CognitoUserSession object to retrieve the Cognito Id ("sub")
   */
  saveUserLogin(userSession: AuthSession): Observable<UpdateCommandOutput> {
    const username = userSession.tokens!.idToken!.payload['cognito:username'];

    const ttl = new Date();

    // configured TTL, or default = ~6months worth of *HOURS*
    const confHoursTTL = CONFIG.userHistoryTTL || 6 * 30 * 24;
    ttl.setHours(new Date().getHours() + confHoursTTL); // new date = now + conf TTL hours

    return from(this.aws.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.update({
          TableName: CONFIG.userLoginHistoryTable,
          Key: {
            username,
          }, // the user id
          UpdateExpression: 'set expirationTime = :expirationTime',
          ExpressionAttributeValues: {
            ':expirationTime': Math.floor(ttl.getTime() / 1000), // removes milliseconds for dynamo TTL
          },
        }),
      ),
    );
  }
}
