import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuditAction, AuditType } from '@common/audit-log/models/AuditLog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvokeCommandOutput } from '@aws-sdk/client-lambda';
import { AuditService } from '../../api/backend/services/audit/audit.service';
import { DeleteThingService } from '../../api/delete-thing.service';
import { NotificationService } from '../../shared/notification.service';

@Component({
  selector: 'app-delete-thing',
  templateUrl: './delete-thing.component.html',
  styleUrls: ['./delete-thing.component.css'],
})
export class DeleteThingComponent {
  @Input() thingName?: string;
  @Input() macAddress?: string;

  ongoing = false;

  constructor(
    private readonly auditService?: AuditService,
    private readonly modal?: NgbActiveModal,
    private readonly deleteThingService?: DeleteThingService,
    private readonly notif?: NotificationService,
    private readonly router?: Router,
  ) {}

  confirm(): void {
    if (!this.thingName) {
      this.modal?.close();
      return;
    }

    this.ongoing = true;

    this.deleteThingService?.deleteThing(this.thingName).subscribe(
      async (res: InvokeCommandOutput) => {
        if (res.StatusCode === 200) {
          this.auditService?.pushEvent({
            action: AuditAction.DELETE,
            type: AuditType.THING,
            resourceId: `${this.thingName} (${this.macAddress})`,
          });
          this.notif?.showSuccess(
            `Successfully requested ${this.thingName} to be deleted`,
          );
          this.router?.navigateByUrl('home');
          this.modal?.close();
        } else {
          this.notif?.showError(
            `Error deleting thing ${this.thingName} : ${res.StatusCode} - ${res.FunctionError} - ${res.Payload}`,
          );
          this.ongoing = false;
        }
      },
      (err: Error) => {
        this.notif?.showError(
          `Error deleting thing ${this.thingName} : ${err.message}`,
          err,
        );
        this.ongoing = false;
      },
    );
  }

  cancel(): void {
    this.modal?.dismiss();
  }
}
