import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import {
  DeletedAllLogs,
  DeletedLog,
  DownloadedLog,
  ProductLog,
  ProductLogS3Object,
} from '../../../../models/backend/product-logs';
import { Shadow, ShadowState, UpdateShadow } from '../../../../models/shadow';
import {
  GetThingPropertiesBody,
  SimpleThingProperties,
} from './thing-properties';
import { SearchBody, SearchResult } from './things.model';

@Injectable({
  providedIn: 'root',
})
export class ThingsService {
  private readonly backendUrl = environment.backendUrl;

  constructor(private readonly http: HttpClient) {}

  /**
   * Fetches a thing's shadow
   *
   * @param thingName
   */
  getThingShadow(thingName: string): Observable<Shadow> {
    return this.http
      .get<{
        state: ShadowState;
      }>(`${this.backendUrl}/things/${thingName}/shadow`)
      .pipe(map((result) => new Shadow(result.state)));
  }

  /**
   * Updates a thing's shadow
   *
   * @param thingname
   * @param payload
   */
  updateThingShadow(
    thingname: string,
    payload: UpdateShadow,
  ): Observable<Partial<ShadowState>> {
    return this.http
      .put<{
        state: Partial<ShadowState>;
      }>(`${this.backendUrl}/things/${thingname}/shadow`, payload)
      .pipe(map((result) => result.state));
  }

  /**
   * updates the nickname attribute of the Thing
   * @param thingName
   * @param mac
   * @param nickname
   */
  updateNickname(
    thingName: string,
    mac: string,
    nickname: string | null,
  ): Observable<void> {
    return this.http.put<void>(
      `${this.backendUrl}/things/${thingName}/properties`,
      {
        macAddress: mac,
        properties: {
          nickname,
        },
      },
    );
  }

  /**
   * searches for Things by the given search term & Thing Type
   * @param searchTerm
   * @param thingType
   * @param connectivity
   * @param nextToken
   */
  search(
    searchTerm: string,
    thingType: string | undefined,
    connectivity?: boolean | undefined,
    nextToken?: string,
  ): Observable<SearchResult> {
    return this.http.post<SearchResult>(`${this.backendUrl}/things/search`, {
      search: searchTerm,
      thingType,
      connectivity,
      nextToken,
    } as SearchBody);
  }

  /**
   * Fetches the (simplified) ThingProperties for a given array of serials
   * @param serials
   */
  getThingProperties(serials: string[]): Observable<SimpleThingProperties[]> {
    return this.http.post<SimpleThingProperties[]>(
      `${this.backendUrl}/things/properties`,
      {
        serialnumber: serials,
      } as GetThingPropertiesBody,
    );
  }

  /**
   * Gets a list of existing product logs files, whether they are registered on the thing's name or its mac address.
   *
   * @param thingName
   * @param mac
   */
  getProductLogsList(thingName: string, mac: string): Observable<ProductLog[]> {
    return combineLatest([
      this.http
        .get<
          ProductLogS3Object[]
        >(`${this.backendUrl}/products/${thingName}/logs`)
        .pipe(
          map((thingNameLogs) =>
            thingNameLogs.map((rawLog) => new ProductLog(thingName, rawLog)),
          ),
        ),
      this.http
        .get<ProductLogS3Object[]>(`${this.backendUrl}/products/${mac}/logs`)
        .pipe(
          map((macNameLogs) =>
            macNameLogs.map((rawLog) => new ProductLog(mac, rawLog)),
          ),
        ),
    ]).pipe(map((logs: ProductLog[][]) => logs.flat()));
  }

  /**
   * Downloads a product logs file by generating a presigned url on the bakend, then calling S3 for download.
   *
   * @param thingNameOrMac
   * @param filename
   */
  downloadLogFile(
    thingNameOrMac: string,
    filename: string,
  ): Observable<DownloadedLog> {
    return this.http
      .get<{
        presignedUrl: string;
      }>(`${this.backendUrl}/products/${thingNameOrMac}/logs/${filename}`)
      .pipe(
        switchMap((getPresignedResult) =>
          this.http.get(
            `${this.backendUrl}${getPresignedResult.presignedUrl}`,
            {
              responseType: 'blob',
            },
          ),
        ),
        switchMap((blob) => from(blob.text())),
        map((rawFileStr) => new DownloadedLog(rawFileStr)),
      );
  }

  /**
   * Gets a list of existing product logs files, whether they are registered on the thing's name or its mac address.
   *
   * @param thingName
   * @param mac
   */
  purgeLogs(thingName?: string, mac?: string): Observable<boolean> {
    return combineLatest([
      thingName
        ? this.http.delete<DeletedAllLogs>(
            `${this.backendUrl}/products/${thingName}/logs`,
          )
        : of(undefined),
      mac
        ? this.http.delete<DeletedAllLogs>(
            `${this.backendUrl}/products/${mac}/logs`,
          )
        : of(undefined),
    ]).pipe(map((logs) => logs.every((_res) => !!_res?.success)));
  }

  /**
   * Deletes the given logfile for a given product
   *
   * @param thingName
   * @param mac
   * @param filename
   */
  deleteLog(
    thingName: string | undefined,
    mac: string | undefined,
    filename: string,
  ): Observable<boolean> {
    return combineLatest([
      thingName
        ? this.http.delete<DeletedLog>(
            `${this.backendUrl}/products/${thingName}/logs/${filename}`,
          )
        : of(undefined),
      mac
        ? this.http.delete<DeletedLog>(
            `${this.backendUrl}/products/${mac}/logs/${filename}`,
          )
        : of(undefined),
    ]).pipe(map((logs) => logs.every((_res) => !!_res?.success)));
  }
}
