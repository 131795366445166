import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../../lib/utils.service';

@Pipe({
  name: 'prettyPrintJson',
})
export class PrettyPrintJsonPipe implements PipeTransform {
  constructor(private utlisService: UtilsService) {}

  transform(value: string): unknown {
    if (value) {
      return this.utlisService.prettyPrintJson(value);
    }
    return '';
  }
}
