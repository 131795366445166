import { Component, Input, OnInit } from '@angular/core';
import {
  AuditAction,
  AuditLog,
  AuditType,
} from '@common/audit-log/models/AuditLog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuditService } from '../../api/backend/services/audit/audit.service';

@Component({
  selector: 'app-audit-creator',
  templateUrl: './audit-creator.component.html',
  styleUrls: ['./audit-creator.component.scss'],
})
export class AuditCreatorComponent implements OnInit {
  @Input() resourceId?: string;
  @Input() type?: AuditType;
  @Input() action?: AuditAction = AuditAction.CREATE;
  @Input() phrasing?: string = 'created by';

  createdBy$?: Observable<AuditLog | undefined>;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(private readonly auditService: AuditService) {}

  ngOnInit(): void {
    this.createdBy$ =
      this.type && this.action && this.resourceId
        ? this.auditService
            .getLatestEvent(this.type, this.action, this.resourceId)
            .pipe(
              map((logs) => {
                this.loading$.next(false);
                this.loading$.complete();
                return logs[this.resourceId as string];
              }),
            )
        : of();
  }
}
