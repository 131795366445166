<div class="btn btn-primary btn-left" disabled="true">
  <i class="fa fa-file-export" aria-hidden="true"></i>
  Export to
</div>
<button
  class="btn btn-primary btn-center"
  (click)="exportExcel()"
  [disabled]="!enabled()"
>
  <i class="fa fa-file-excel" aria-hidden="true"></i>
  XLSX
</button>
<button
  class="btn btn-primary btn-right"
  (click)="exportCsv()"
  [disabled]="!enabled()"
>
  <i class="fa fa-file-csv" aria-hidden="true"></i>
  CSV
</button>
