import { Pipe, PipeTransform } from '@angular/core';
import { Firmware } from '../../models/firmware';
import { MetaVersion } from '../../models/metaversion/meta-version';

@Pipe({
  name: 'displayMetaVersionUi',
})
export class DisplayMetaVersionUiPipe implements PipeTransform {
  transform(metaversion: Firmware): string {
    if (metaversion) {
      return MetaVersion.formatFirmwareId(metaversion);
    }
    return '';
  }
}
