import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GroupOfThings } from '../../../models/Group-of-things.model';
import { GroupsOfThingsService } from '../../../api/groups-of-things.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../../shared/notification.service';
import { ServiceException } from '@smithy/smithy-client';

@Component({
  selector: 'app-groups-of-things-delete',
  templateUrl: './groups-of-things-delete.component.html',
  styleUrls: ['./groups-of-things-delete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupsOfThingsDeleteComponent {
  @Input() group?: GroupOfThings;

  deleting = false;

  constructor(
    private readonly groupsService: GroupsOfThingsService,
    private readonly modal: NgbActiveModal,
    private readonly notif: NotificationService,
  ) {}

  cancel(): void {
    this.modal.close(false);
  }

  delete(): void {
    if (!this.group) {
      return;
    }

    this.deleting = true;

    this.groupsService.deleteGroup(this.group).subscribe(
      () => this.modal.close(true),
      (err: ServiceException) =>
        this.notif.showError(`Error deleting group : ${err.message}`, err),
    );
  }
}
