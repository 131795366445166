<form
  [formGroup]="_formGroup"
  (ngSubmit)="updateFilters()"
  (reset)="resetFilters($event)"
  class="d-flex flex-row"
>
  <div class="d-flex flex-column">
    <label for="startMonth" class="font-weight-bold"
      >Start month (inclusive)</label
    >
    <ng-month-picker
      id="startMonth"
      formControlName="startMonth"
      displayFormat="MM YYYY"
    >
    </ng-month-picker>
  </div>
  <div class="d-flex flex-column ml-4">
    <label for="endMonth" class="font-weight-bold"
      >End month (exclusive)&nbsp;</label
    >
    <ng-month-picker
      id="endMonth"
      formControlName="endMonth"
      displayFormat="MM YYYY"
    >
    </ng-month-picker>
  </div>
  <div class="d-flex flex-column justify-content-end">
    <div class="d-flex flex-row ml-4">
      <button
        type="submit"
        [disabled]="_formGroup.pristine || _formGroup.invalid"
        class="btn btn-primary"
      >
        <i class="fa fa-check" aria-hidden="true"></i>
        Update filters
      </button>
      <button
        type="reset"
        class="btn btn-secondary ml-2"
        [disabled]="!_filtersChanged && _formGroup.pristine"
      >
        <i class="fa fa-trash" aria-hidden="true"></i>
        Reset filters
      </button>
    </div>
  </div>
</form>
