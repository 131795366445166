import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateDeploymentResponse } from '../../../../models/backend/deployment/create-deployment-response';
import { CreateDeploymentRequest } from '../../../../models/backend/deployment/create-deployment-request';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DeploymentsService {
  private readonly backendUrl = environment.backendUrl;

  constructor(private readonly http: HttpClient) {}

  /**
   * Creates a deployment job by calling backend API.
   *
   * @returns the newly created job id
   */
  startDeploymentJob(
    metaversionId: string,
    thingNames: string[],
    groupId?: string,
  ): Observable<string> {
    const requestBody: CreateDeploymentRequest = {
      metaversionId,
      thingNames,
      groupId,
    };

    return this.http
      .post<CreateDeploymentResponse>(
        `${this.backendUrl}/deployments`,
        requestBody,
      )
      .pipe(map((res) => res.jobId));
  }
}
