<div class="container-fluid">
  <div class="card shadow">
    <div
      class="card-header d-flex flex-row justify-content-between align-items-center"
    >
      <h1>Create a new factory firmware</h1>
      <div>
        <div class="btn-group">
          <button
            class="btn btn-secondary"
            routerLink="/factory-firmwares"
            [queryParams]="{ thingType: preSelectedThingType }"
          >
            <i class="fas fa-arrow-left mr-1" aria-hidden="true"></i>
            Cancel
          </button>
          <button
            class="btn btn-primary"
            (click)="create()"
            [disabled]="formGroup.invalid || loading || fileUploadLoading"
          >
            <i
              class="fas fa-check"
              aria-hidden="true"
              *ngIf="!loading; else loader"
            ></i>
            Create
          </button>
        </div>
      </div>
    </div>
    <form class="form card-body d-flex flex-row" [formGroup]="formGroup">
      <div class="d-flex flex-column mr-5">
        <h2>Version information</h2>
        <span
          class="d-flex flex-row align-items-center mb-2 justify-content-between"
        >
          <label for="thingType" class="text-nowrap mr-3 mb-0"
            >Thing Type*</label
          >
          <select
            class="form-select d-inline-block"
            aria-label="Default select example"
            id="thingType"
            name="thingType"
            formControlName="thingType"
          >
            <option [value]="null">Thing type</option>
            <option *ngFor="let thingType of thingTypes$ | async">
              {{ thingType }}
            </option>
          </select>
        </span>
        <span
          class="d-flex flex-row align-items-center justify-content-between"
        >
          <label for="versionId" class="text-nowrap mr-3 mb-0">Version*</label>
          <input
            class="form-control"
            type="text"
            id="versionId"
            formControlName="version"
          />
        </span>
      </div>
      <div class="d-flex flex-column">
        <h2>Version UI files</h2>
        <input
          type="file"
          class="file-input"
          (change)="onFileSelected($event)"
          #fileUpload
          multiple
        />
        <div class="file-upload">
          <ul
            *ngIf="formGroup.value.files!.length !== 0; else filesMessage"
            class="files-list"
          >
            <li
              *ngFor="let filename of formGroup.controls.files.value"
              class="mb-1"
            >
              <div class="btn-group text-left" (click)="removeFile(filename)">
                <button class="btn btn-outline-primary" disabled>
                  {{ filename | displayTemporaryFilename }}
                </button>
                <button class="btn btn-danger">
                  <i class="fas fa-trash" aria-hidden="true"></i>
                </button>
              </div>
            </li>
          </ul>
          <br />
          <button
            class="btn btn-primary"
            (click)="fileUpload.click()"
            [disabled]="fileUploadLoading"
          >
            <i
              class="fas fa-plus mr-1"
              aria-hidden="true"
              *ngIf="!fileUploadLoading; else loader"
            ></i>
            Add file
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #loader>
  <app-spinner-small></app-spinner-small>
</ng-template>

<ng-template #filesMessage> Please add at least one file. </ng-template>
