import { Injectable } from '@angular/core';
import { from, Observable, switchMap } from 'rxjs';
import { AwsService } from '../lib/aws.service';
import { InvokeCommandOutput } from '@aws-sdk/client-lambda';

@Injectable({
  providedIn: 'root',
})
export class DeleteThingService {
  constructor(private awsService: AwsService) {}

  deleteThing(thingName: string): Observable<InvokeCommandOutput> {
    return from(this.awsService.lambda()).pipe(
      switchMap((lambda) =>
        lambda.invoke({
          FunctionName: 'DeleteThing',
          InvocationType: 'RequestResponse',
          Payload: JSON.stringify({
            body: JSON.stringify({ thingName }),
          }),
        }),
      ),
    );
  }
}
