import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Columns, Config } from 'ngx-easy-table';
import { ThingListDisplay } from '../../models/thing-list-display.model';
import { AddThingToGroupComponent } from '../../groups-of-things/groups-of-things-list/add-thing-to-group/add-thing-to-group.component';
import { ThingData } from '../../models/thingtype';
import { UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { NotificationService } from '../notification.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-thing-list-table',
  templateUrl: './thing-list-table.component.html',
  styleUrls: ['./thing-list-table.component.scss'],
})
export class ThingListTableComponent {
  @Input() devices?: ThingListDisplay[];
  @Input() thingsCount$?: Observable<number | string>;
  @Input() disablePreviousPage = true;
  @Input() disableNextPage = true;
  @Input() pageNumber = 0;

  @Input() isLoading = false;
  @Input() connected?: boolean;
  @Input() thingType?: string;

  @Input() columns: Columns[] = [];
  @Input() configuration?: Config;
  @Input() formGroupLocalFilters?: UntypedFormGroup;

  @Input() nextDevices?: (event: Event) => Promise<void>;
  @Input() previousDevices?: () => void;

  constructor(
    private readonly ngbModal: NgbModal,
    private clipboardService: ClipboardService,
    private notif: NotificationService,
  ) {}

  copieKey(str: string): void {
    this.clipboardService.copy(str);
    this.notif.showInfo('Payload copied');
  }

  typeDevice = (device: ThingListDisplay): ThingListDisplay => device;

  async addThingToAGroup(thing: ThingListDisplay): Promise<void> {
    const modal = this.ngbModal.open(AddThingToGroupComponent, {
      backdrop: 'static',
      centered: true,
    });

    modal.componentInstance.thing = ThingData.fromThingListDisplay(thing);
  }
}
