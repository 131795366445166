import {
  Component,
  ContentChildren,
  Input,
  QueryList,
  TemplateRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css'],
})
export class TabComponent {
  @Input() tabTitle?: string;
  @Input() tabId?: string;

  @ContentChildren('tabContent') tabContent?: QueryList<TemplateRef<void>>;

  constructor(readonly activatedRoute: ActivatedRoute) {}
}
