import { Injectable } from '@angular/core';
import { GetStatisticsCommandOutput } from '@aws-sdk/client-iot';
import CONFIG from '../../config';
import { ThingType } from '../models/thingtype';
import { AwsService } from './aws.service';
import { NativeAttributeValue } from '@aws-sdk/util-dynamodb';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsManagerService {
  constructor(private awsService: AwsService) {}

  async fetchStatsDays(
    days: string[],
  ): Promise<(Record<string, NativeAttributeValue> | undefined)[]> {
    // Fetching from cache
    const needsRefresh: string[] = [];
    const stats: (Record<string, NativeAttributeValue> | undefined)[] =
      days.map((day) => {
        const keyPrefix = `groupeseb:days:${day}`;
        const cacheKey = this.getTimedKey(keyPrefix);
        const raw = localStorage.getItem(cacheKey);
        if (raw) {
          return JSON.parse(raw);
        }
        needsRefresh.push(day);
      });

    if (needsRefresh.length === 0) {
      return stats;
    }

    // Clearing old cache
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!key) {
        break;
      }
      for (const day of needsRefresh) {
        if (key.indexOf(`groupeseb:days:${day}`) === 0) {
          localStorage.removeItem(key);
        }
      }
    }

    // Fetching from DB if not in cache
    const res = await (
      await this.awsService.dynamodb()
    ).batchGet({
      RequestItems: {
        [CONFIG.iotDevicesStatsTable]: {
          Keys: needsRefresh.map((_) => ({ day: _ })),
        },
      },
    });
    if (res.Responses) {
      for (let i = 0; i < days.length; i++) {
        if (stats[i] !== undefined) {
          continue;
        }
        const day = days[i];
        const keyPrefix = `groupeseb:days:${day}`;
        const cacheKey = this.getTimedKey(keyPrefix);
        let item = res.Responses[CONFIG.iotDevicesStatsTable].find(
          (_) => _.day === day,
        );
        if (item === undefined) {
          item = { day };
        }
        stats[i] = item;
        // Writing to cache
        localStorage.setItem(cacheKey, JSON.stringify(item));
      }
    }

    // Returning
    return stats;
  }

  async fetchStatsVersions(
    thingType: ThingType,
    aggregationField: string,
    version?: string,
  ): Promise<GetStatisticsCommandOutput> {
    // Fetching from cache
    const keyPrefix = `groupeseb:versions:${thingType}:${aggregationField}:${version}`;
    const cacheKey = this.getTimedKey(keyPrefix);
    const raw = localStorage.getItem(cacheKey);
    if (raw) {
      return JSON.parse(raw);
    }

    // Fetching from DB if not in cache
    const res = await (
      await this.awsService.iot()
    ).getStatistics({
      aggregationField: `attributes.${aggregationField}`,
      indexName: 'AWS_Things',
      queryString: version
        ? `thingTypeName: ${thingType} AND attributes.${aggregationField}:${version}`
        : `thingTypeName: ${thingType}`,
    });

    // Clearing old cache
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (!key) {
        break;
      }
      if (key.indexOf(keyPrefix) === 0) {
        localStorage.removeItem(key);
      }
    }

    // Writing to cache
    localStorage.setItem(cacheKey, JSON.stringify(res));

    // Returning
    return res;
  }

  private getTimedKey(prefix: string): string {
    const millis = 1000;
    const minute = 60;
    const timestamp = Math.round(new Date().getTime() / millis);
    const currentMinute = timestamp - (timestamp % minute);
    return `${prefix}_${currentMinute}`;
  }
}
