import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MetaVersionJob } from '../../../models/meta-version-job.model';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-group-of-things-details-jobs',
  templateUrl: './group-of-things-details-jobs.component.html',
  styleUrls: ['./group-of-things-details-jobs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupOfThingsDetailsJobsComponent {
  @Input() jobs?: MetaVersionJob[];

  columns: Columns[] = [
    { key: 'dateStr', title: 'Creation date', width: '10%' },
    { key: 'jobId', title: 'Job name' },
    { key: 'metaversionId', title: 'Metaversion' },
    {
      key: 'actions',
      title: 'Actions',
      orderEnabled: false,
      searchEnabled: false,
      width: '5%',
    },
  ];

  configuration: Config = {
    ...DefaultConfig,
    searchEnabled: true,
    paginationEnabled: true,
    rows: 10,
    orderEnabled: true,
  };

  formGroup = new UntypedFormGroup({
    jobId: new UntypedFormControl(),
    dateStr: new UntypedFormControl(),
    metaversionId: new UntypedFormControl(),
  });

  typeJob = (job: MetaVersionJob): MetaVersionJob => job;
}
