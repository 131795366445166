import { HttpErrorResponse } from '@angular/common/http';

export class BackendError extends Error {
  details: string[];
  origin: HttpErrorResponse;

  constructor(message: string, details: string[], origin: HttpErrorResponse) {
    super(message);
    this.details = details;
    this.origin = origin;
  }
}
