<app-ngx-table-with-query-params-persistence
  [columns]="columns"
  [configuration]="configuration"
  [data]="groups"
  [filtersTemplate]="filtersTemplate"
  [filtersFormGroup]="formGroupLocalFilters"
  [customMatchers]="customColumnFilters"
  class="table"
>
  <ng-template let-rawGroup>
    <ng-container *ngIf="typeGroup(rawGroup) as group">
      <td>{{ group.creationDate | displayMetaVersionDate }}</td>
      <td class="text-break">{{ group.groupName }}</td>
      <td>{{ group.thingType }}</td>
      <td>{{ group.numberOfThings }}</td>
      <td>
        <ng-container
          *ngTemplateOutlet="actionsTemplate; context: { $implicit: group }"
        ></ng-container>
      </td>
    </ng-container>
  </ng-template>
  <ng-template #filtersTemplate>
    <ng-container [formGroup]="formGroupLocalFilters">
      <th scope="col" class="pl-0 pr-3 pt-0">
        <input
          class="form-control"
          type="text"
          placeholder="Creation date"
          formControlName="creationDateStr"
        />
      </th>
      <th scope="col" class="pl-0 pr-3 pt-0">
        <input
          class="form-control"
          type="text"
          placeholder="Name"
          formControlName="groupName"
        />
      </th>
      <th></th>
      <th scope="col" class="pl-0 pr-3 pt-0">
        <div class="input-group">
          <input
            class="form-control"
            type="number"
            min="0"
            placeholder="More than"
            formControlName="minNumberOfThings"
          />
          <input
            class="form-control"
            type="number"
            min="0"
            placeholder="Less than"
            formControlName="maxNumberOfThings"
          />
        </div>
      </th>
    </ng-container>
  </ng-template>
</app-ngx-table-with-query-params-persistence>
