import { Injectable } from '@angular/core';
import { SearchIndexCommandOutput, ThingDocument } from '@aws-sdk/client-iot';
import { QueryCommandOutput, ScanCommandOutput } from '@aws-sdk/lib-dynamodb';
import type { NativeAttributeValue } from '@aws-sdk/util-dynamodb';
import { AuditAction, AuditType } from '@common/audit-log/models/AuditLog';
import {
  BehaviorSubject,
  forkJoin,
  from,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { filter, map, mapTo, scan, switchMap, tap } from 'rxjs/operators';
import CONFIG from '../../config';
import { AwsService } from '../lib/aws.service';
import { GroupOfThings } from '../models/Group-of-things.model';
import { MetaVersionJob } from '../models/meta-version-job.model';
import { ThingListDisplay } from '../models/thing-list-display.model';
import { ThingData } from '../models/thingtype';
import { UuidUtils } from '../shared/utils/uuid.utils';
import { AuditService } from './backend/services/audit/audit.service';

@Injectable({
  providedIn: 'root',
})
export class GroupsOfThingsService {
  /**
   * To validate a whole customGroups string
   */
  static CUSTOM_GROUPS_REGEX =
    /^@,([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12},)*,?@/i;
  /**
   * to match all the group ids in a customGroups string
   */
  static CUSTOM_GROUPS_MATCH =
    /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/gi;

  constructor(
    private awsService: AwsService,
    private auditService: AuditService,
  ) {}

  /**
   * Checks if a given customGroups string is valid following the format : @,id1,id2,...,@
   * where each id is a 36 char UUIDv4
   *
   * @example both examples would return true
   * @,,@ // no associated group
   * @,d6be6020-d693-11ec-85d4-bda1e8a19eb4,759389e0-7d03-11ec-845b-a5bcd8c8714b,@ // 2 associated groups
   * @param customGroups the customGroups string to check against
   */
  static isCustomGroupsValid(customGroups: string): boolean {
    return customGroups?.trim?.()?.length
      ? this.CUSTOM_GROUPS_REGEX.test(customGroups)
      : false;
  }

  /**
   * Returns an array of ids from a "customGroups" string
   * an empty array if the string is empty or no id could match
   *
   * @param customGroups the thing's customGroups attribute, with the format : @,id1,id2,...,@
   */
  static parseCustomGroups(customGroups: string): string[] {
    if (
      !customGroups?.trim?.()?.length ||
      !this.isCustomGroupsValid(customGroups)
    ) {
      return [];
    }

    return customGroups.match(this.CUSTOM_GROUPS_MATCH) ?? [];
  }

  /**
   * Returns a "customGroups" string from an array of ids
   * following the format : @,id1,id2,...,@
   *
   * @param customGroups the array of ids
   */
  static formatCustomGroups(customGroups: string[]): string {
    const cleanGroups = [...new Set(customGroups?.length ? customGroups : [])] // removes duplicates
      .filter(UuidUtils.isValidUuid) // removes ids in the wrong format
      .join(',');

    return `@,${cleanGroups},@`;
  }

  /**
   * Returns true if the Thing can be added to the Group, depending on the group's Criteria and ThingType, and the Thing's attributes
   *
   * @param thing The thing to check against the group's attributes
   * @param group The reference group
   */
  static isThingSuitedForGroup(
    thing: ThingData,
    group: GroupOfThings,
  ): boolean {
    if (group.thingType !== thing.thingType) {
      return false; // ThingTypes don't even match
    }
    // if no criteriaValue is set, then the group is not yet fully defined
    if (group.criteriaValue == null) {
      return true;
    }

    switch (group.criteriaType) {
      case 'CMMF':
        return group.criteriaValue === thing.attributes?.CMMF;
      case 'RANGE':
        return group.criteriaValue === thing.attributes?.range;
      case 'THINGTYPE':
      default:
        return group.thingType === thing.thingType;
    }
  }

  /**
   * Lists existing groups by querying dynamoDB.
   *
   * @param thingType The thingType to filter the query
   */
  public listGroups(thingType?: string): Observable<GroupOfThings[]> {
    let dynamoOutput$: Observable<ScanCommandOutput | QueryCommandOutput>;

    if (!thingType) {
      dynamoOutput$ = from(this.awsService.dynamodb()).pipe(
        switchMap((dynamodb) =>
          dynamodb.scan({
            TableName: CONFIG.groupOfThingsTable,
          }),
        ),
      );
    } else {
      dynamoOutput$ = from(this.awsService.dynamodb()).pipe(
        switchMap((dynamodb) =>
          dynamodb.query({
            TableName: CONFIG.groupOfThingsTable,
            IndexName: CONFIG.groupOfThingsThingTypeIndex,
            KeyConditionExpression: '#thingType = :thingType',
            ExpressionAttributeNames: { '#thingType': 'thingType' },
            ExpressionAttributeValues: { ':thingType': thingType },
          }),
        ),
      );
    }

    return dynamoOutput$.pipe(
      map(
        (dynamoOutput) =>
          dynamoOutput.Items?.map((itm: Record<string, NativeAttributeValue>) =>
            GroupOfThings.parseDynamoDB(itm),
          ) ?? [],
      ),
    );
  }

  /**
   * Creates a new group in dynamoDB after generating a new random UUID
   *
   * @param group the group to create
   * @return the generated uuid
   */
  public createGroup(group: {
    groupName: string;
    thingType: string;
    criteriaType: string;
  }): Observable<string> {
    const uuid: string = UuidUtils.getRandomUuid();

    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.put({
          TableName: CONFIG.groupOfThingsTable,
          Item: {
            ...group,
            groupName: group.groupName.trim(),
            groupId: uuid,
            creationDate: new Date().toISOString(),
            numberOfThings: 0,
          },
        }),
      ),
      tap(() => {
        this.auditService.pushEvent({
          type: AuditType.THING_GROUP,
          action: AuditAction.CREATE,
          resourceId: uuid,
          additionalData: {
            thing_group_name: group.groupName,
          },
        });
      }),
      mapTo(uuid),
    );
  }

  /**
   * Fetches a group's details.
   *
   * @param groupId id of the group
   */
  public getGroup(groupId: string): Observable<GroupOfThings> {
    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.get({
          TableName: CONFIG.groupOfThingsTable,
          Key: {
            groupId,
          },
        }),
      ),
      map((dynamoResponse) => GroupOfThings.parseDynamoDB(dynamoResponse.Item)),
    );
  }

  /**
   * Deletes a group and removes its id from associated things and jobs
   *
   * @param group the group to delete
   */
  public deleteGroup(group: GroupOfThings): Observable<void> {
    return forkJoin([
      this.removeGroupFromThings(group),
      this.removeGroupFromJobs(group),
    ]).pipe(
      switchMap(() => this.deleteGroupFromDynamo(group)),
      tap(() => {
        this.auditService.pushEvent({
          type: AuditType.THING_GROUP,
          action: AuditAction.DELETE,
          resourceId: group.groupId,
          additionalData: {
            thing_group_name: group.groupName,
          },
        });
      }),
    );
  }

  /**
   * Adds the given group to the Thing's customGroups attributes
   * Before adding, checks if
   * - The group has an id
   * - The Thing has a name and attributes
   * - The thing isn't already associated to the group
   * - The thing can be added to the group
   *
   * @param group the group to add the Thing to
   * @param thing the thing to add to the group
   */
  public addToGroup(
    group: GroupOfThings | undefined,
    thing: ThingData | undefined,
  ): Observable<ThingData> {
    if (!group?.groupId) {
      console.error(group);
      return throwError(new Error('No group provided'));
    }
    if (!thing?.thingName || !thing?.attributes) {
      console.error(thing);
      return throwError(new Error('Provided Thing is missing attributes'));
    }
    if (thing.attributes?.customGroups?.includes(group.groupId)) {
      console.error(
        'Already in group',
        group.groupId,
        thing.thingName,
        thing.attributes?.customGroups,
      );
      return throwError(new Error('Thing already in group.'));
    }
    if (!GroupsOfThingsService.isThingSuitedForGroup(thing, group)) {
      console.error(
        'Incompatible',
        group.criteriaType,
        group.criteriaValue,
        thing,
      );
      return throwError(
        new Error("Provided Thing isn't compatible with this group."),
      );
    }

    const groupsOfThing = GroupsOfThingsService.parseCustomGroups(
      thing?.attributes?.customGroups ?? '',
    );
    groupsOfThing.push(group.groupId);

    return this.updateCustomGroups(thing.thingName, groupsOfThing).pipe(
      switchMap(() => this.incrementNumberOfThings(group)),
      switchMap(() => this.setGroupCriteriaFromFirstThing(group, thing)),
      mapTo(thing),
    );
  }

  /**
   * Removes the given Thing from the given Group
   * It also checks if it is the last Thing in the Group to clear its criteriaValue
   *
   * @param group the group to remove the Thing from
   * @param thing the thing to remove from the group
   */
  public removeFromGroup(
    group: GroupOfThings | undefined,
    thing: ThingData | undefined,
  ): Observable<void> {
    if (!group?.groupId) {
      console.error(group);
      return throwError(new Error('No group provided'));
    }
    if (!thing?.thingName || !thing?.attributes) {
      console.error(thing);
      return throwError(new Error('Provided Thing is missing attributes'));
    }
    if (!thing.attributes?.customGroups?.includes(group.groupId)) {
      console.log(
        'Already removed from group',
        group.groupId,
        thing.thingName,
        thing.attributes.customGroups,
      );
      return throwError(new Error('Thing is already removed from group.'));
    }

    const newGroupIds = GroupsOfThingsService.parseCustomGroups(
      thing.attributes?.customGroups ?? '',
    ).filter((_groupId) => _groupId !== group.groupId);

    return this.handleRemovingGroupFromThing(
      thing.thingName,
      newGroupIds,
      group,
    );
  }

  public handleRemovingGroupFromThing(
    thingName: string,
    newGroupIds: string[],
    group: GroupOfThings,
  ): Observable<void> {
    return this.updateCustomGroups(thingName, newGroupIds).pipe(
      switchMap(() => this.incrementNumberOfThings(group, -1)),
      switchMap((_group) => this.clearGroupCriteriaIfEmpty(_group)),
      tap(() => {
        this.auditService.pushEvent({
          type: AuditType.THING_GROUP,
          action: AuditAction.REMOVE_THING,
          resourceId: group.groupId,
          additionalData: {
            thingname: thingName,
            thing_group_name: group.groupName,
          },
        });
      }),
      map(() => void 0),
    );
  }

  /**
   * Updates the "customGroups" attribute given an array of group ids
   *
   * @param thingName the Thing's ThingName to update
   * @param groupIds the array of group ids to associate to the Thing
   */
  public updateCustomGroups(
    thingName: string,
    groupIds: string[],
  ): Observable<void> {
    return from(this.awsService.iot()).pipe(
      switchMap((iot) =>
        iot.updateThing({
          thingName,
          attributePayload: {
            merge: true,
            attributes: {
              customGroups: GroupsOfThingsService.formatCustomGroups(groupIds),
            },
          },
        }),
      ),
      map(() => void 0),
    );
  }

  /**
   * Increments the group's numberOfThings by "increment"
   *
   * @param group the group to update
   * @param increment increment value, default to 1. Could be -1 to decrement
   */
  public incrementNumberOfThings(
    group: GroupOfThings,
    increment = 1,
  ): Observable<GroupOfThings> {
    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.update({
          TableName: CONFIG.groupOfThingsTable,
          Key: { groupId: group.groupId },
          UpdateExpression: 'ADD #numberOfThings :increment',
          ExpressionAttributeNames: { '#numberOfThings': 'numberOfThings' },
          ExpressionAttributeValues: { ':increment': increment },
        }),
      ),
      map((_response) => {
        group.numberOfThings = group.numberOfThings + increment;
        return group;
      }),
    );
  }

  /**
   * Sets the group's criteriaValue based on the Thing
   * Only if the group doesn't already have a criteriaValue
   *
   * @param group the group to update
   * @param thing the Thing to get the data from
   */
  public setGroupCriteriaFromFirstThing(
    group: GroupOfThings,
    thing: ThingData,
  ): Observable<void> {
    if (
      group.criteriaType === 'THINGTYPE' ||
      group.criteriaValue !== undefined
    ) {
      return of(void 0);
    }

    let criteriaValue: string | undefined;

    switch (group.criteriaType) {
      case 'RANGE':
        criteriaValue = thing.attributes?.range;
        break;
      case 'CMMF':
        criteriaValue = thing.attributes?.CMMF;
        break;
    }

    if (!criteriaValue?.trim?.()?.length) {
      return throwError(
        new Error(`Thing is missing its ${group.criteriaType}`),
      );
    }

    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.update({
          TableName: CONFIG.groupOfThingsTable,
          Key: { groupId: group.groupId },
          UpdateExpression: 'SET #criteriaValue = :criteriaValue',
          ExpressionAttributeNames: { '#criteriaValue': 'criteriaValue' },
          ExpressionAttributeValues: { ':criteriaValue': criteriaValue },
        }),
      ),
      map(() => void 0),
    );
  }

  /**
   * Resets the group's criteriaValue if it is empty
   *
   * @param group the group to update
   */
  public clearGroupCriteriaIfEmpty(group: GroupOfThings): Observable<void> {
    if (
      group.criteriaType === 'THINGTYPE' ||
      group.criteriaValue === undefined ||
      group.numberOfThings >= 1
    ) {
      return of(void 0);
    }

    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.update({
          TableName: CONFIG.groupOfThingsTable,
          Key: { groupId: group.groupId },
          UpdateExpression: 'REMOVE #criteriaValue',
          ExpressionAttributeNames: { '#criteriaValue': 'criteriaValue' },
        }),
      ),
      map(() => void 0),
    );
  }

  /**
   * Fetches a group's things, iterating over all endpoint pages in order to fetch all things at once.
   *
   * @param groupId the id of the group to look for
   */
  public getGroupThings(groupId: string): Observable<ThingListDisplay[]> {
    const nextToken$ = new BehaviorSubject<string | undefined>(void 0);

    return nextToken$.pipe(
      switchMap((nextToken) =>
        from(this.awsService.iot()).pipe(
          switchMap((iot) =>
            iot.searchIndex({
              indexName: 'AWS_Things',
              queryString: `attributes.customGroups: @*,${groupId},*@`,
              nextToken,
            }),
          ),
        ),
      ),
      scan(
        // Cumulate the previous answers (provided by the scan operator in acc param) with the new ones
        (
          acc: SearchIndexCommandOutput | undefined,
          val: SearchIndexCommandOutput,
        ): SearchIndexCommandOutput => ({
          ...val,
          things: [...(acc?.things ?? []), ...(val.things ?? [])],
        }),
      ),
      filter((val) => {
        if (val.nextToken) {
          // If a nextToken is present, emit it in the subject to trigger fetching the next page
          nextToken$.next(val.nextToken);
          return false;
        }
        // If no nextToken, then emit the final value
        nextToken$.complete();
        return true;
      }),
      map(
        (val) =>
          val.things?.map((thingDocument: ThingDocument) =>
            ThingListDisplay.fromThingDocument(thingDocument),
          ) ?? [],
      ),
    );
  }

  /**
   * Returns a list of custom groups for a thing.
   * This service splits the input string for group ids then fetches all groups details from dynamo.
   *
   * @param customGroups The string contained in the 'customGroups' attribute of a thing,formatted as <br/>`@,{group1Id},{group2Id},...,@`
   */
  public getGroupsForThing(customGroups: string): Observable<GroupOfThings[]> {
    const groupIds = GroupsOfThingsService.parseCustomGroups(customGroups);
    if (!groupIds?.length) {
      // null = empty or no match
      return of([]);
    }

    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.batchGet({
          RequestItems: {
            [CONFIG.groupOfThingsTable]: {
              Keys: groupIds.map((groupId) => ({ groupId })),
            },
          },
        }),
      ),
      map((response) => response.Responses?.[CONFIG.groupOfThingsTable] ?? []),
      map((items) => items.map((item) => GroupOfThings.parseDynamoDB(item))),
    );
  }

  /**
   * Renames a group in dynamo
   *
   * @param groupId the id of the group to be renamed
   * @param newName the group new name
   */
  public editGroupName(groupId: string, newName: string): Observable<string> {
    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.update({
          TableName: CONFIG.groupOfThingsTable,
          Key: { groupId },
          UpdateExpression: 'SET #groupName = :newName',
          ExpressionAttributeNames: { '#groupName': 'groupName' },
          ExpressionAttributeValues: { ':newName': newName },
        }),
      ),
      map(() => groupId),
    );
  }

  /**
   * Fetches the jobs for a group by querying dynamoDB
   *
   * @param groupId the group id to look for
   */
  public getJobsForGroup(groupId: string): Observable<MetaVersionJob[]> {
    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.query({
          TableName: CONFIG.metaversionJobsTable,
          IndexName: CONFIG.metaversionJobsGroupIdIndex,
          KeyConditionExpression: '#groupId = :groupId',
          ExpressionAttributeValues: {
            ':groupId': groupId,
          },
          ExpressionAttributeNames: {
            '#groupId': 'groupId',
          },
        }),
      ),
      map((output: QueryCommandOutput): MetaVersionJob[] =>
        MetaVersionJob.mapDynamoOutputToMetaversionJobs(output.Items ?? []),
      ),
    );
  }

  private removeGroupFromThings(group: GroupOfThings): Observable<void> {
    return this.getGroupThings(group.groupId).pipe(
      switchMap((things) => {
        if (!things.length) {
          return of(void 0);
        }

        const observables: Observable<void>[] = things.map((thing) =>
          this.removeGroupFromThing(thing, group),
        );

        return forkJoin(observables);
      }),
      map(() => void 0),
    );
  }

  private removeGroupFromThing(
    thing: ThingListDisplay,
    group: GroupOfThings,
  ): Observable<void> {
    if (!thing.groups?.length) {
      // Nothing to do if thing has no groups
      return of(void 0);
    }

    const newGroups = GroupsOfThingsService.parseCustomGroups(
      thing.groups,
    ).filter((split) => split !== group.groupId);

    return this.handleRemovingGroupFromThing(thing.name, newGroups, group);
  }

  private removeGroupFromJobs(group: GroupOfThings): Observable<void> {
    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.query({
          TableName: CONFIG.metaversionJobsTable,
          IndexName: CONFIG.metaversionJobsGroupIdIndex,
          KeyConditionExpression: '#groupId = :groupId',
          ExpressionAttributeNames: { '#groupId': 'groupId' },
          ExpressionAttributeValues: { ':groupId': group.groupId },
        }),
      ),
      map(
        (queryResult: QueryCommandOutput) =>
          queryResult.Items as MetaVersionJob[] | undefined,
      ),
      switchMap((jobs) => {
        if (!jobs?.length) {
          return of(void 0);
        }

        const observables: Observable<void>[] = jobs.map(
          (job: MetaVersionJob) =>
            this.removeGroupFromJob(job.jobId, group.groupName),
        );

        return forkJoin(observables);
      }),
      map(() => void 0),
    );
  }

  private removeGroupFromJob(
    jobId: string,
    groupName: string,
  ): Observable<void> {
    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.update({
          TableName: CONFIG.metaversionJobsTable,
          Key: { jobId },
          UpdateExpression: 'SET #groupId = :groupId',
          ExpressionAttributeNames: { '#groupId': 'groupId' },
          ExpressionAttributeValues: { ':groupId': `[deleted]${groupName}` },
        }),
      ),
      map(() => void 0),
    );
  }

  private deleteGroupFromDynamo(group: GroupOfThings): Observable<void> {
    return from(this.awsService.dynamodb()).pipe(
      switchMap((dynamodb) =>
        dynamodb.delete({
          TableName: CONFIG.groupOfThingsTable,
          Key: { groupId: group.groupId },
        }),
      ),
      map(() => void 0),
    );
  }
}
