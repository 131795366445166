import { Pipe, PipeTransform } from '@angular/core';
import { JobExecutionSummary } from '@aws-sdk/client-iot';
import { UtilsService } from '../../lib/utils.service';

@Pipe({
  name: 'displayDateDevice',
})
export class DisplayDateDevicePipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}

  transform(value?: JobExecutionSummary): unknown {
    if (value && value.lastUpdatedAt) {
      return this.utilsService.formatDate(value.lastUpdatedAt.toISOString());
    }
    return '-';
  }
}
