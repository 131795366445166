export class ArnUtils {
  /**
   * Splits the ARN by "/" to get the last part of it, i.e. the resource id
   * @example
   *  `arn:aws:iot:eu-west-1:423795814412:thing/VAC001-xxxx-yyyy`
   *  returns
   *  `VAC001-xxxx-yyyy`
   *  @param arn the arn to split
   */
  public static getResourceId(arn: string): string | undefined {
    return arn && arn.includes('/') ? arn.split('/')[1] : undefined;
  }
}
