<div class="card shadow" *ngIf="log">
  <div class="card-header d-flex flex-row justify-content-between">
    <h2>Log file from {{ log.displayDate }}</h2>
    <div class="btn-group" *ngIf="logFile$ | async as downloaded">
      <button
        class="btn btn-primary"
        (click)="saveFile(downloaded.file, downloaded.filename)"
      >
        <i class="fas fa-cloud-download-alt" aria-hidden="true"></i>
      </button>
      <button
        class="btn btn-danger"
        (click)="deleteLog()"
        *canDeleteProductLogs
      >
        <i class="fas fa-trash" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="card-body" *ngIf="logFile$ | async as downloaded; else loader">
    <ng-container *ngIf="downloaded.file as logFile">
      <div class="log-reader-headers">
        <p class="mr-2">
          <strong>Firmware version : </strong>{{ logFile.firmwareVersion }}
        </p>
        <p class="mr-2">
          <strong>Log source : </strong>{{ logFile.logSource }}
        </p>
        <p class="mr-2"><strong>Session id : </strong>{{ logFile.uuid }}</p>
        <p class="mr-2">
          <strong>Timestamp : </strong>{{ logFile.timestamp.toISOString() }}
        </p>
        <p class="mr-2"><strong>Log id : </strong>{{ logFile.key }}</p>
        <p class="mr-2"><strong>Log type : </strong>{{ logFile.logType }}</p>
        <p class="mr-2"><strong>Log level : </strong>{{ logFile.logLevel }}</p>
      </div>
      <div class="log-reader-contents">
        <pre>{{ logFile.contents }}</pre>
      </div>
    </ng-container>
  </div>
  <div class="card-footer d-flex flex-row w-100 justify-content-end">
    <button class="btn btn-primary" (click)="close()">
      <i class="fas fa-times mr-1" aria-hidden="true"></i>
      Close
    </button>
  </div>
</div>

<ng-template #loader>
  <app-spinner></app-spinner>
</ng-template>
