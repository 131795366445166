import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditType } from '@common/audit-log/models/AuditLog';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  of,
  Subject,
} from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs/operators';
import { GroupsOfThingsService } from '../../api/groups-of-things.service';
import {
  AddToGroupType,
  GroupOfThings,
} from '../../models/Group-of-things.model';
import { MetaVersionJob } from '../../models/meta-version-job.model';
import { ThingListDisplay } from '../../models/thing-list-display.model';
import { ThingData } from '../../models/thingtype';
import { NotificationService } from '../../shared/notification.service';
import { TitleService } from '../../shared/title.service';
import { AddThingToGroupComponent } from '../groups-of-things-list/add-thing-to-group/add-thing-to-group.component';
import { GroupsOfThingsDeleteComponent } from '../groups-of-things-list/groups-of-things-delete/groups-of-things-delete.component';

@Component({
  selector: 'app-group-of-things-details',
  templateUrl: './group-of-things-details.component.html',
  styleUrls: ['./group-of-things-details.component.scss'],
})
export class GroupOfThingsDetailsComponent implements OnInit {
  groupId$?: Observable<string>;
  group$?: Observable<GroupOfThings>;
  things$?: Observable<ThingListDisplay[]>;
  jobs$?: Observable<MetaVersionJob[]>;

  thingsUpdating = false;
  thingsUpdated$ = new Subject<void>();
  refreshThings$ = new BehaviorSubject<void>(undefined);
  refreshDebounceTime = 30000; // ms

  editingName = false;
  editNameLoading = false;
  groupInitialName?: string;
  groupNameControl = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(3),
    Validators.maxLength(120),
    Validators.pattern(/^\S.{1,118}\S$/),
  ]);
  saveGroupName$ = new Subject<string>();

  readonly AuditType = AuditType;

  private readonly modalOptions: NgbModalOptions = {
    backdrop: 'static',
    centered: true,
  };

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly groupService: GroupsOfThingsService,
    private readonly notif: NotificationService,
    private readonly ngbModal: NgbModal,
    private titleService: TitleService,
  ) {}

  ngOnInit(): void {
    this.groupNameControl.valueChanges.subscribe((value) =>
      this.groupNameControl.setValue(value?.trimLeft(), { emitEvent: false }),
    );

    this.groupId$ = this.route.params.pipe(
      map((params) => {
        const groupId = params.groupId;
        if (!groupId) {
          throw new Error('Missing group id');
        }
        return groupId;
      }),
      shareReplay(1),
    );

    const editGroup$: Observable<string> = combineLatest([
      this.saveGroupName$,
      this.groupId$,
    ]).pipe(
      switchMap(([newName, groupId]) =>
        this.groupService.editGroupName(groupId, newName).pipe(
          catchError((err) => {
            this.notif.showError(
              `Couldn't edit group name : ${err.message ?? err}`,
              err,
            );
            return of(groupId);
          }),
        ),
      ),
      tap(() => {
        this.editNameLoading = false;
        this.editingName = false;
      }),
      shareReplay(1),
    );

    this.group$ = merge(this.groupId$, editGroup$).pipe(
      switchMap((groupId) => this.groupService.getGroup(groupId)),
      tap(
        (group) => {
          this.groupNameControl.reset(group.groupName);
          this.groupInitialName = group.groupName;

          this.titleService.replaceInTitle(group.groupId, group.groupName);
        },
        (err) =>
          this.notif.showError(
            `Couldn't fetch group information : ${err.message ?? err}`,
            err,
          ),
      ),
      shareReplay(1),
    );

    this.things$ = combineLatest([this.groupId$, this.refreshThings$]).pipe(
      switchMap(([groupId]) => this.groupService.getGroupThings(groupId)),
      tap(
        () => void 0,
        (err) =>
          this.notif.showError(
            `Couldn't fetch group things list : ${err.message ?? err}`,
            err,
          ),
      ),
      shareReplay(1),
    );

    this.jobs$ = this.groupId$.pipe(
      switchMap((groupId) => this.groupService.getJobsForGroup(groupId)),
      tap(
        () => void 0,
        (err) =>
          this.notif.showError(
            `Couldn't fetch jobs information : ${err.message ?? err}`,
            err,
          ),
      ),
      shareReplay(1),
    );

    // On adding or removing a thing in the list, refreshes
    this.thingsUpdated$
      .pipe(
        tap(() => {
          this.thingsUpdating = true;
        }),
        debounceTime(this.refreshDebounceTime),
      )
      .subscribe(() => {
        this.thingsUpdating = false;
        this.refreshThings();
      });
  }

  refreshThings(): void {
    this.refreshThings$.next();
  }

  deletedThing(): void {
    this.thingsUpdated$.next();
  }

  addThingToGroup(group: GroupOfThings): void {
    const modal = this.ngbModal.open(
      AddThingToGroupComponent,
      this.modalOptions,
    );

    modal.componentInstance.typeOfAdding = 'CHOOSE_THING' as AddToGroupType;
    modal.componentInstance.group = group;

    modal.closed.subscribe((addedThingData: ThingData) => {
      if (addedThingData) {
        this.thingsUpdated$.next();
      }
    });
  }

  saveName(): void {
    if (this.groupNameControl.invalid) {
      this.notif.showError('Invalid name');
      return;
    }

    this.editNameLoading = true;
    this.saveGroupName$.next(this.groupNameControl.value);
  }

  cancelNameEdit(): void {
    this.groupNameControl.reset(this.groupInitialName);
    this.editingName = false;
  }

  delete(group: GroupOfThings): void {
    const modal = this.ngbModal.open(
      GroupsOfThingsDeleteComponent,
      this.modalOptions,
    );

    modal.componentInstance.group = group;

    modal.result.then((res) => {
      if (res) {
        this.notif.showSuccess('Group successfully deleted');
        this.router.navigateByUrl('/groups');
      }
    });
  }
}
