import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ThingListDisplay } from '../../../models/thing-list-display.model';
import { GroupOfThings } from '../../../models/Group-of-things.model';
import { ThingData } from '../../../models/thingtype';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistryService } from '../../../api/registry.service';
import { RemoveThingFromGroupComponent } from '../../groups-of-things-list/remove-thing-from-group/remove-thing-from-group.component';

@Component({
  selector: 'app-group-of-things-details-things',
  templateUrl: './group-of-things-details-things.component.html',
  styleUrls: ['./group-of-things-details-things.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupOfThingsDetailsThingsComponent {
  @Input() group?: GroupOfThings;
  @Input() things: ThingListDisplay[] = [];

  @Output() deletedThing = new EventEmitter<ThingData>();

  columns: Columns[] = [
    { key: 'name', title: 'Name', width: '22%' },
    { key: 'connectionTimeStr', title: 'Last Connection', width: '10%' },
    { key: 'currentFirmware', title: 'Current Firmware' },
    { key: 'nextFirmwareReported', title: 'Next Firmware (reported)' },
    { key: 'nextFirmwareDesired', title: 'Next Firmware (desired)' },
    {
      key: 'actions',
      title: 'Actions',
      orderEnabled: false,
      searchEnabled: false,
      width: '10%',
    },
  ];

  configuration: Config = {
    ...DefaultConfig,
    searchEnabled: true,
    paginationEnabled: true,
    rows: 10,
    orderEnabled: true,
  };

  formGroup = new UntypedFormGroup({
    name: new UntypedFormControl(),
    connectionTimeStr: new UntypedFormControl(),
    currentFirmware: new UntypedFormControl(),
    nextFirmwareReported: new UntypedFormControl(),
    nextFirmwareDesired: new UntypedFormControl(),
  });

  constructor(
    private readonly ngbModal: NgbModal,
    private readonly registryService: RegistryService,
  ) {}

  async removeThingFromGroup(
    group?: GroupOfThings,
    thing?: ThingListDisplay,
  ): Promise<void> {
    if (thing == null || group == null) {
      return;
    }

    const modal = this.ngbModal.open(RemoveThingFromGroupComponent, {
      backdrop: 'static',
      centered: true,
    });

    const describeThingResponse = await this.registryService.getThing(
      thing.name,
    );

    modal.componentInstance.group = group;
    modal.componentInstance.thing = ThingData.fromDescribeResponse(
      describeThingResponse,
    );

    modal.closed.subscribe((_removedThing: ThingData) => {
      if (_removedThing != null) {
        this.deletedThing.emit(_removedThing);
      }
    });
  }

  typeThing = (thing: ThingListDisplay): ThingListDisplay => thing;
}
