import { UuidUtils } from '../shared/utils/uuid.utils';

export class ThingUtils {
  static thingTypeFromThingName(thingName: string): string {
    const THING_TYPE = 'thingType';
    const regex = new RegExp(`^(?<${THING_TYPE}>\\w+)-.*$`);
    const thingType = regex.exec(thingName)?.groups?.[THING_TYPE];

    if (!thingType) {
      throw `Bad input, thingName must be of format "**-**".\nthingName: ${thingName}`;
    }

    return thingType;
  }

  static isSerialNumber(serial: string): boolean {
    return UuidUtils.isValidUuid(serial);
  }

  static removeThingType(thingName: string): string {
    return thingName.replace(/^[A-Z]{3}[0-9]{3}-/, '');
  }
}
