<!-- Loader -->
<ng-template #loader>
  <app-spinner></app-spinner>
</ng-template>

<h5 class="ml-4">Cost recalculated by product for one year ($)</h5>
<canvas
  baseChart
  *ngIf="costs; else loader"
  [datasets]="_chartData"
  [labels]="costs.monthLabels"
  type="line"
  [options]="_chartOptions"
></canvas>
