<div class="card shadow">
  <h2 class="card-header">An error occurred</h2>
  <div class="card-body bg-translucent-danger">
    <div class="d-flex flex-row text-break">
      <i
        class="warning-icon fas fa-exclamation-circle text-danger"
        aria-hidden="true"
      ></i>
      <div class="pl-2 pt-2">
        <span>
          {{ errorMessage }}
        </span>
        <ul>
          <li *ngFor="let detail of getHumanReadableErrorDetails()">
            {{ detail }}
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="card-footer d-flex flex-row w-100 justify-content-end">
    <button
      class="btn btn-outline-primary mr-2"
      (click)="showHelpForm = !showHelpForm"
    >
      <i class="fas fa-envelope mr-1" aria-hidden="true"></i>
      Ask for help
    </button>
    <button class="btn btn-primary" (click)="close()">
      <i class="fas fa-times mr-1" aria-hidden="true"></i>
      Close
    </button>
  </div>
</div>
<div class="card shadow" *ngIf="showHelpForm">
  <div class="card-header">
    <h3>Ask for help</h3>
  </div>
  <div class="card-body">
    <form [formGroup]="helpFormGroup">
      <p>
        Please explain what you were trying to do when the error occurred.<br />Technical
        details will automatically be attached to your message.
      </p>
      <textarea class="form-control" formControlName="comments"></textarea>
    </form>
  </div>
  <div class="card-footer d-flex flex-row w100 justify-content-end">
    <button
      class="btn btn-success"
      (click)="sendTeamsMessage()"
      [disabled]="sendingReport"
    >
      <i
        class="fas fa-check mr-1"
        aria-hidden="true"
        *ngIf="!sendingReport; else sending"
      ></i>
      Send
    </button>
  </div>
</div>

<ng-template #sending>
  <app-spinner-small></app-spinner-small>
</ng-template>
