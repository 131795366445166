import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { AuditAction, AuditType } from '@common/audit-log/models/AuditLog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { AuditService } from '../../../api/backend/services/audit/audit.service';
import { GroupsOfThingsService } from '../../../api/groups-of-things.service';
import { RegistryService } from '../../../api/registry.service';
import {
  AddToGroupType,
  GroupOfThings,
} from '../../../models/Group-of-things.model';
import { ThingData } from '../../../models/thingtype';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-add-thing-to-group',
  templateUrl: './add-thing-to-group.component.html',
  styleUrls: ['./add-thing-to-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddThingToGroupComponent implements OnInit {
  @Input() typeOfAdding: AddToGroupType = 'CHOOSE_GROUP';

  @Input() group?: GroupOfThings;
  @Input() thing?: ThingData;

  groups$?: Observable<GroupOfThings[]>;

  formGroup = new UntypedFormGroup({});

  constructor(
    private readonly groupsService: GroupsOfThingsService,
    private readonly modal: NgbActiveModal,
    private readonly registryService: RegistryService,
    private readonly notif: NotificationService,
    private readonly auditService: AuditService,
  ) {}

  ngOnInit(): void {
    switch (this.typeOfAdding) {
      case 'CHOOSE_THING': // Choosing a Thing to add to a group
        this.formGroup.addControl(
          'serialNumber',
          new UntypedFormControl(null, [
            Validators.required,
            Validators.maxLength(43),
          ]),
        );
        break;
      case 'CHOOSE_GROUP': // Adding a thing by choosing a Group
        this.groups$ = (
          this.groupsService.listGroups(this.thing?.thingType) ?? of([])
        ).pipe(
          map((_groups) =>
            _groups.filter((_g) =>
              GroupsOfThingsService.isThingSuitedForGroup(
                this.thing as ThingData,
                _g,
              ),
            ),
          ),
          shareReplay(1),
        );
        this.formGroup.addControl(
          'group',
          new UntypedFormControl(
            '',
            [Validators.required],
            [this.validateGroup.bind(this)],
          ),
        );
        break;
    }
  }

  selectGroup(chosenGroup: GroupOfThings): void {
    this.group = chosenGroup;
  }

  clearGroup(): void {
    this.group = undefined;
  }

  submitForm(event?: Event): void {
    event?.preventDefault();

    if (!this.group || this.formGroup.invalid) {
      return;
    }

    switch (this.typeOfAdding) {
      case 'CHOOSE_THING':
        const submittedSerial = this.formGroup.value.serialNumber;
        if (!submittedSerial?.trim?.()?.length) {
          this.notif.showError(
            'Enter a valid Thing identifier (Mac Address, Serial Number, or Thing Name)',
          );
          return;
        }

        this.searchThingThenAddToGroup(submittedSerial, this.group);

        break;
      case 'CHOOSE_GROUP':
        if (!this.thing) {
          console.error('No Thing linked', this.thing);
          this.notif.showError('Thing not found');
          return;
        }

        if (this.thing.attributes?.customGroups?.includes(this.group.groupId)) {
          console.log(
            `Thing ${this.thing.thingName} already in group ${this.group.groupId}`,
          );
          this.notif.showInfo(
            `Thing already in group "${this.group.groupName}"`,
          );
          return;
        }

        this.addThingToGroup(this.thing, this.group);

        break;
    }
  }

  public searchThingThenAddToGroup(
    submittedSerial: string,
    group: GroupOfThings,
  ): void {
    from(this.registryService.searchThing(group.thingType, submittedSerial))
      .pipe(switchMap((thing) => this.groupsService.addToGroup(group, thing)))
      .subscribe(
        (addedThing) => {
          this.auditService.pushEvent({
            type: AuditType.THING_GROUP,
            action: AuditAction.ADD_THING,
            resourceId: group.groupId,
            additionalData: {
              thingname: addedThing.thingName,
              thing_group_name: group.groupName,
            },
          });
          this.notif.showSuccess('Thing added to group');
          this.modal.close(addedThing);
        },
        (error) => {
          console.error(error.message, error);
          this.notif.showError(error.message, error);
          this.modal.close(undefined);
        },
      );
  }

  public addThingToGroup(thing: ThingData, group: GroupOfThings): void {
    this.groupsService.addToGroup(group, thing).subscribe(
      (addedThing) => {
        this.auditService.pushEvent({
          type: AuditType.THING_GROUP,
          action: AuditAction.ADD_THING,
          resourceId: group.groupId,
          additionalData: {
            thingname: addedThing.thingName,
            thing_group_name: group.groupName,
          },
        });
        this.notif.showSuccess('Thing added to group');
        this.modal.close(addedThing);
      },
      (error) => {
        console.error(error.message, error);
        this.notif.showError(error.message, error);
        this.modal.close(undefined);
      },
    );
  }

  cancel(): void {
    this.modal.close(undefined);
  }

  typeGroup(untyped: GroupOfThings): GroupOfThings {
    return untyped;
  }

  private validateGroup(
    control: AbstractControl,
  ): Observable<ValidationErrors | null> {
    return (
      this.groups$?.pipe(
        map((groups) =>
          groups.includes(control.value)
            ? null
            : { unknownGroup: { value: control.value } },
        ),
      ) ?? of({ noGroup: true })
    );
  }
}
