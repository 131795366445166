import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../../lib/utils.service';
import { ShadowStateFirmware } from '../../models/shadow';

@Pipe({
  name: 'displayMetaVersion',
})
export class DisplayMetaVersionPipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}

  transform(value: ShadowStateFirmware | undefined): unknown {
    if (value) {
      return this.utilsService.getVersions(value);
    }
    return '';
  }
}
