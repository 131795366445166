<ng-template #filtersTemplate>
  <ng-container [formGroup]="localFiltersFormGroup">
    <th scope="col" class="pl-0 pr-3 pt-0">
      <span class="d-flex flex-row">
        <input
          class="form-control"
          type="text"
          formControlName="creationDateMin"
          placeholder="After"
        />
        <input
          class="form-control"
          type="text"
          formControlName="creationDateMax"
          placeholder="Before"
        />
      </span>
    </th>
    <th scope="col"><!-- No filter on size column --></th>
    <th scope="col">
      <button
        class="btn btn-primary"
        (click)="localFiltersFormGroup.reset()"
        ngbTooltip="Reset table filters"
      >
        <i class="fas fa-undo-alt" aria-hidden="true"></i>
      </button>
    </th>
  </ng-container>
</ng-template>

<app-ngx-table-with-query-params-persistence
  *ngIf="_logs$ | async as logs; else loader"
  [columns]="columns"
  [data]="logs!"
  [configuration]="configuration"
  [filtersFormGroup]="localFiltersFormGroup"
  [filtersTemplate]="filtersTemplate"
  [customMatchers]="customMatchers"
>
  <ng-template let-untypedProductLog>
    <ng-container *ngIf="typed(untypedProductLog) as productLog">
      <td>{{ productLog.displayDate }}</td>
      <td>{{ productLog.bytes | formatBytes }}</td>
      <td>
        <div class="btn-group">
          <button
            class="btn btn-primary"
            ngbTooltip="Download file and save on this computer"
            (click)="_download$.next(productLog)"
          >
            <i class="fas fa-cloud-download-alt" aria-hidden="true"></i>
          </button>
          <button class="btn btn-eye" (click)="showReader(productLog)">
            <i class="fas fa-eye" aria-hidden="true"></i>
          </button>
          <button
            class="btn btn-danger"
            (click)="deleteLog(productLog)"
            *canDeleteProductLogs
          >
            <i class="fas fa-trash" aria-hidden="true"></i>
          </button>
        </div>
      </td>
    </ng-container>
  </ng-template>
</app-ngx-table-with-query-params-persistence>

<ng-template #loader>
  <app-spinner></app-spinner>
</ng-template>
