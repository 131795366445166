import { Directive } from '@angular/core';
import { AbstractGroupBasedDirective } from './abstract-group-based.directive';
import { FeatureGroupEnum } from '../feature-group.enum';

@Directive({
  selector: '[canListFirmwares]',
  standalone: true,
})
export class ListFirmwaresDirective extends AbstractGroupBasedDirective {
  protected override getSpecificGroup(): FeatureGroupEnum {
    return FeatureGroupEnum.LIST_FIRMWARES;
  }
}
