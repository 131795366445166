<!-- Loader -->
<ng-template #loader>
  <app-spinner></app-spinner>
</ng-template>

<h5 class="ml-4">Number of products</h5>
<canvas
  baseChart
  *ngIf="numberOfProducts; else loader"
  [datasets]="_chartData"
  [labels]="numberOfProducts.monthLabels"
  type="line"
  [options]="_chartOptions"
></canvas>
