import {Directive} from '@angular/core';
import {FeatureGroupEnum} from '../feature-group.enum';
import {AbstractGroupBasedDirective} from './abstract-group-based.directive';

@Directive({
  selector: '[canSeeManifest]',
  standalone: true
})
export class SeeManifestDirective extends AbstractGroupBasedDirective{

  protected override getSpecificGroup(): FeatureGroupEnum {
    return FeatureGroupEnum.SEE_MANIFEST;
  }

}
