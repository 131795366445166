import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  QueryList,
} from '@angular/core';
import { TabComponent } from './tab/tab.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tab-view',
  templateUrl: './tab-view.component.html',
  styleUrls: ['./tab-view.component.scss'],
})
export class TabViewComponent implements AfterViewInit {
  @ContentChildren(TabComponent) tabs?: QueryList<TabComponent>;

  constructor(
    private readonly router: Router,
    readonly activatedRoute: ActivatedRoute,
  ) {}

  ngAfterViewInit(): void {
    // looks for a default tab to redirect to, if none is provided
    this.activatedRoute.queryParamMap.subscribe((params) => {
      if (params.get('tab') || !this.tabs?.length) {
        return;
      }

      const defaultTab = this.tabs?.get(0);
      if (defaultTab?.tabId) {
        this.selectTab(defaultTab.tabId);
      }
    });
  }

  selectTab(selectedTabId: string): void {
    // Update URL
    this.router.navigate([], {
      queryParams: {
        tab: selectedTabId,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }
}
