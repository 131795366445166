import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TagModel } from 'ngx-chips/core/tag-model';

@Component({
  selector: 'app-chips-input',
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.css'],
})
export class ChipsInputComponent {
  @Input() disable = false;
  @Input() inputId?: string;
  @Input() placeholder?: string;
  @Input() validationRegex = '.*';
  @Input() validationErrorMessage = 'Invalid tags : #tagNames';

  @Output() addingChip = new EventEmitter<string>();
  @Output() removingChip = new EventEmitter<string>();
  @Output() editingChip = new EventEmitter<string>();

  readonly chipsPasteSplitRegex = /[ \-;,:/\n]/i;
  readonly chipsSeparatorKeys = [' ', '-', ';', ',', ':', '/'];
  chipsModel: string[] = [];

  erroredTags = new Set<string>();
  errorMessage?: string;

  onAddingChip(tagModel: TagModel | string): Observable<TagModel> {
    const tagValue = typeof tagModel === 'object' ? tagModel.value : tagModel;

    const tagTrim = tagValue.trim();
    if (tagTrim === '') {
      return of();
    }

    const regExp = new RegExp(this.validationRegex);
    if (!regExp.test(tagTrim)) {
      this.erroredTags.add(tagTrim);
      const listErroredTags = [...this.erroredTags].join(', ');
      this.errorMessage = this.validationErrorMessage.replace(
        /#tagNames/gi,
        listErroredTags,
      );
      return of();
    }

    this.addingChip.emit(tagTrim);

    delete this.errorMessage;
    return of({ display: tagTrim, value: tagTrim });
  }

  onRemovingChip(removedTag: TagModel | string): Observable<TagModel> {
    if (typeof removedTag === 'object') {
      this.removingChip.emit(removedTag.value);
      return of(removedTag);
    }

    this.removingChip.emit(removedTag);
    return of({ display: removedTag, value: removedTag });
  }
}
