import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ShadowStateFirmware } from '../models/shadow';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public redirect(path: string): void {
    window.location.href = path;
  }

  getVersions(firmware?: ShadowStateFirmware): string {
    if (!firmware) {
      return '';
    }
    let res = '';
    if (firmware.files) {
      const wifi = firmware.files.find((_) => _?.type === 'wifi');
      const ui = firmware.files.find((_) => _?.type === 'ui');
      if (wifi) {
        res += `WIFI ${wifi.version}`;
      }
      if (ui) {
        res += ` UI ${ui.version}`;
      }
      if (res === '') {
        res = 'No files';
      }
    }
    return res;
  }

  formatDate(timestamp?: number | string | Date): string {
    return moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
  }

  compare(
    a: string | number | Date | undefined,
    b: string | number | Date | undefined,
    asc: boolean = true,
  ): number {
    let res = 0;

    if (!a && b) {
      res = 1;
    } else if (a && !b) {
      res = -1;
    } else if (!a && !b) {
      return 0;
    } else if (a && b) {
      if (a > b) {
        res = 1;
      } else {
        res = -1;
      }
    }

    return asc ? res : -res;
  }

  prettyPrintJson(content: string): string {
    let data = content;
    try {
      data = JSON.parse(content);
      return JSON.stringify(data, null, 2);
    } catch (e) {
      console.error('content not formated correctly', content, e);
      return '';
    }
  }
}
