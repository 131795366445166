<div class="d-inline-block w-50" *ngIf="_chartData.wifi.length">
  <h2 class="text-center">WIFI firmwares ({{ _totals.wifi }})</h2>
  <canvas
    baseChart
    chartType="doughnut"
    [data]="_chartData.wifi"
    [labels]="_chartLabels.wifi"
    [options]="_chartOptions"
  ></canvas>
</div>
<div class="d-inline-block w-50" *ngIf="_chartData.ui.length">
  <h2 class="text-center">UI firmwares ({{ _totals.ui }})</h2>
  <canvas
    baseChart
    chartType="doughnut"
    [data]="_chartData.ui"
    [labels]="_chartLabels.ui"
    [options]="_chartOptions"
  ></canvas>
</div>
<div class="w-100" *ngIf="_chartData.wifi.length + _chartData.ui.length === 0">
  <h2 class="text-center">No thing in group</h2>
</div>
