export class Utils {
  /**
   * Splits an array to fixed size bits<br/>
   * Example: array = [1, 2, 3, 4, 5], chunkSize = 2 returns [[1, 2], [3, 4], [5]]
   *
   * @param array The array to split
   * @param chunkSize The target chunkSize
   */
  public static splitArrayToFixedSizeChunks<T>(
    array: T[],
    chunkSize: number,
  ): T[][] {
    return array.reduce((resultArray: T[][], item: T, index) => {
      const chunkIndex = Math.floor(index / chunkSize);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  }

  /**
   * deletes keys from an object if its value is nullish
   * @example {a: null, b: undefined, c: false} => {c: false}
   * @param obj
   */
  public static deleteNullishValues<T extends {[key: string]: unknown} | undefined | null>(obj: T): T | undefined | null {
    if (obj == null) return obj;

    Object.keys(obj).forEach(key => obj[key] == null && delete obj[key]);

    return obj;
  }
}
