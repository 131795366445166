import type { NativeAttributeValue } from '@aws-sdk/util-dynamodb';
import { CriteriaKey, Firmware } from '../firmware';
import { PRIORITY } from '../priority';
import { VALIDATION_ACCEPTED } from '../validation-accepted';

export interface Component {
  type?: string;
  version?: string;
  name?: string;
}

export class MetaVersion {
  public id?: string;
  public thingType: string;
  public validationAccepted?: string;
  public priority?: string;
  public updateValidation: boolean;
  public wifiFirmware?: Firmware;
  public uiFirmware?: Firmware;
  public date: string;
  public enabledDate?: string;
  public activated: number;

  constructor(
    id: string,
    thingType: string,
    validationAccepted: string,
    priority: string,
    updateValidation: boolean,
    wifiFirmware: Firmware | undefined,
    uiFirmware: Firmware | undefined,
    date: string,
    enabledDate: string | undefined,
    activated: number,
  ) {
    this.id = id;
    this.thingType = thingType;
    this.validationAccepted = validationAccepted;
    this.priority = priority;
    this.updateValidation = updateValidation;
    this.wifiFirmware = wifiFirmware;
    this.uiFirmware = uiFirmware;
    this.date = date;
    this.enabledDate = enabledDate;
    this.activated = activated;
  }

  public static formatFirmwareId(firmware: Firmware): string {
    const s3CriteriaKeys = firmware.getS3CriteriaKeys();
    let firmwareId = firmware.id.split('_').slice(1).join(' ');

    if (s3CriteriaKeys.length > 0) {
      firmwareId += ` (${s3CriteriaKeys.length})`;
    }

    return firmwareId;
  }

  public static empty(): MetaVersion {
    return new MetaVersion(
      '',
      '',
      VALIDATION_ACCEPTED.all[0],
      PRIORITY.all[0],
      false,
      undefined,
      undefined,
      '',
      undefined,
      1,
    );
  }

  static compareFirmwareFiles(
    asc: boolean,
  ): (a: MetaVersion, b: MetaVersion) => number {
    return (a, b) => {
      const aWifi = a.wifiFirmware
        ? MetaVersion.formatFirmwareId(a.wifiFirmware)
        : null;
      const aUi = a.uiFirmware
        ? MetaVersion.formatFirmwareId(a.uiFirmware)
        : null;
      const aSeparator = aWifi && aUi ? ' ' : '';
      const aLabel = `${aWifi}${aSeparator}${aUi}`;

      const bWifi = b.wifiFirmware
        ? MetaVersion.formatFirmwareId(b.wifiFirmware)
        : null;
      const bUi = b.uiFirmware
        ? MetaVersion.formatFirmwareId(b.uiFirmware)
        : null;
      const bSeparator = bWifi && bUi ? ' ' : '';
      const bLabel = `${bWifi}${bSeparator}${bUi}`;

      return asc ? aLabel.localeCompare(bLabel) : bLabel.localeCompare(aLabel);
    };
  }

  public displayFirmwares(): string {
    const lines = [];
    if (this.wifiFirmware) {
      lines.push(MetaVersion.formatFirmwareId(this.wifiFirmware));
    }
    if (this.uiFirmware) {
      lines.push(MetaVersion.formatFirmwareId(this.uiFirmware));
    }
    return lines.join(' - ');
  }

  public doesAnyFirmwareLooselyMatchCriteriaKey(
    criteriaKey: CriteriaKey,
  ): boolean {
    // Wifi firmware set
    if (this.wifiFirmware && !this.uiFirmware) {
      if (!this.wifiFirmware.s3Key) {
        return false;
      }
      return this.wifiFirmware.doesAnyS3CriteriaKeyLooselyMatch(criteriaKey);
    }

    // ui firmware set
    if (this.uiFirmware && !this.wifiFirmware) {
      if (!this.uiFirmware.s3Key) {
        return false;
      }
      return this.uiFirmware.doesAnyS3CriteriaKeyLooselyMatch(criteriaKey);
    }

    // Combo metaversion
    if (this.wifiFirmware && this.uiFirmware) {
      if (!this.uiFirmware.s3Key || !this.wifiFirmware.s3Key) {
        return false;
      }
      return (
        this.wifiFirmware.doesAnyS3CriteriaKeyLooselyMatch(criteriaKey) &&
        this.uiFirmware.doesAnyS3CriteriaKeyLooselyMatch(criteriaKey)
      );
    }

    return false;
  }

  public getFirmwareBrandArea(): string[] | undefined {
    const wifiBrandAreas: string[] =
      this.wifiFirmware?.getFirmwareBrandArea?.() || [];
    const uiBrandAreas: string[] =
      this.uiFirmware?.getFirmwareBrandArea?.() || [];

    // wififirmware or uifirmware not set or with default brandArea
    if (wifiBrandAreas.length === 0 || uiBrandAreas.length === 0) {
      return wifiBrandAreas.concat(uiBrandAreas);
    }

    // wififirmware or uifirmware with same brand area
    if (
      wifiBrandAreas.length === uiBrandAreas.length &&
      wifiBrandAreas.every((ba) => uiBrandAreas.includes(ba))
    ) {
      return wifiBrandAreas;
    }
  }

  public getComponents(): Component[] {
    const res: Component[] = [];

    const wifiS3Keys = Object.keys(this.wifiFirmware?.s3Key || {});
    if (wifiS3Keys.length > 0) {
      const firstS3Key = wifiS3Keys[0];
      const splits =
        this.wifiFirmware?.s3Key?.[firstS3Key].file.split('/') ?? [];
      res.push({
        type: this.wifiFirmware?.type,
        version: this.wifiFirmware?.version,
        name: splits.pop(),
      });
    }

    const uiS3Keys = Object.keys(this.uiFirmware?.s3Key || {});
    if (uiS3Keys.length > 0) {
      const firstS3Key = uiS3Keys[0];
      const splits = this.uiFirmware?.s3Key?.[firstS3Key].file.split('/') ?? [];
      res.push({
        type: this.uiFirmware?.type,
        version: this.uiFirmware?.version,
        name: splits.pop(),
      });
    }

    return res;
  }

  public getS3CriteriaKeys(): CriteriaKey[] {
    return (
      this?.uiFirmware?.getS3CriteriaKeys?.() ||
      this?.wifiFirmware?.getS3CriteriaKeys?.() ||
      []
    );
  }

  public isSigned(): boolean {
    return (
      (this.wifiFirmware === undefined || this.wifiFirmware.isSigned()) &&
      (this.uiFirmware === undefined || this.uiFirmware.isSigned())
    );
  }

  public getFirmwares(): Firmware[] {
    const res: Firmware[] = [];
    if (this.wifiFirmware) {
      res.push(this.wifiFirmware);
    }
    if (this.uiFirmware) {
      res.push(this.uiFirmware);
    }
    return res;
  }

  public getFirmwareIds(): string[] {
    return this.getFirmwares().map((_firmware) => _firmware.id);
  }

  public setFirmwares(items: Record<string, NativeAttributeValue>[]): void {
    if (this.wifiFirmware) {
      for (const item of items) {
        if (item.id === this.wifiFirmware.id) {
          this.wifiFirmware = Firmware.parse(item);
          break;
        }
      }
    }
    if (this.uiFirmware) {
      for (const item of items) {
        if (item.id === this.uiFirmware.id) {
          this.uiFirmware = Firmware.parse(item);
          break;
        }
      }
    }
  }
}
