import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuditAction,
  AuditType,
  MappedAuditLog,
} from '@common/audit-log/models/AuditLog';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { Observable, of, Subject } from 'rxjs';
import {
  catchError,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ThingTypesService } from '../api/thing-types.service';
import {
  BaseFactoryFirmware,
  SearchableFactoryFirmware,
} from '../models/factory-firmware';
import { CustomFilters } from '../shared/ngx-table-with-query-params-persistence/ngx-table-with-query-params-persistence.component';
import { NotificationService } from '../shared/notification.service';
import { AuditService } from '../api/backend/services/audit/audit.service';
import { FactoryFirmwaresService } from '../api/backend/services/factory-firmwares/factory-firmwares.service';

@Component({
  selector: 'app-factory-firmwares',
  templateUrl: './factory-firmwares.component.html',
  styleUrls: ['./factory-firmwares.component.scss'],
})
export class FactoryFirmwaresComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  filters$?: Observable<string[]>;
  thingTypeControl = new FormControl<string>('');

  factoryFirmwares$?: Observable<SearchableFactoryFirmware[]>;

  loading = false;

  destroy$ = new Subject<void>();

  columns: Columns[] = [
    { key: 'id', title: 'Version' },
    { key: 'creationDate', title: 'Creation date', orderBy: 'desc' },
    { key: 'createdBy', title: 'Creator', width: '5%' },
    {
      key: 'actions',
      title: 'Actions',
      orderEnabled: false,
      searchEnabled: false,
      width: '2%',
    },
  ];

  configuration: Config = {
    ...DefaultConfig,
    searchEnabled: true,
    paginationEnabled: true,
    rows: 10,
  };

  localFiltersFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    createdBy: new UntypedFormControl(),
    creationDateMin: new UntypedFormControl(),
    creationDateMax: new UntypedFormControl(),
  });

  customMatchers: CustomFilters<BaseFactoryFirmware> = {
    creationDateMin: (
      controlValue: string | null,
      item: BaseFactoryFirmware,
    ) =>
      controlValue
        ? controlValue <= item.creationDate.substring(0, controlValue.length)
        : true,
    creationDateMax: (
      controlValue: string | null,
      item: BaseFactoryFirmware,
    ) =>
      controlValue
        ? controlValue >= item.creationDate.substring(0, controlValue.length)
        : true,
  };

  constructor(
    private readonly thingTypesService: ThingTypesService,
    private readonly notif: NotificationService,
    private readonly factoryFirmwaresService: FactoryFirmwaresService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly auditService: AuditService,
    private readonly router: Router,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.filters$ = this.thingTypesService.getThingTypes()?.pipe(
      catchError((err) => {
        this.notif.showError(err.message, err);
        return of([]);
      }),
      shareReplay(1),
    );

    this.factoryFirmwares$ = this.thingTypeControl.valueChanges.pipe(
      tap((newThingType) => {
        if (!newThingType) {
          return;
        }

        this.localFiltersFormGroup.reset();
        this.loading = true;
        this.thingTypeControl.disable({ emitEvent: false });
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: { thingType: newThingType },
          queryParamsHandling: 'merge',
        });
      }),
      switchMap((thingType): Observable<BaseFactoryFirmware[]> => {
        if (!thingType) {
          return of([]);
        }

        return this.factoryFirmwaresService
          .listFactoryFirmwares(thingType)
          .pipe(
            switchMap((firmwares: SearchableFactoryFirmware[]) => {
              if (firmwares.length === 0) {
                return of([]);
              }

              const resourceIds = firmwares.map((f) => f.id);

              return this.auditService
                .getLatestEvent(
                  AuditType.FACTORY_FIRMWARE,
                  AuditAction.UPLOAD,
                  resourceIds,
                )
                .pipe(
                  map((audits: MappedAuditLog) => {
                    firmwares.forEach((_f) => {
                      _f.createdBy = audits?.[_f.id]?.userId;
                    });
                    return firmwares;
                  }),
                  tap(() => {
                    this.loading = false;
                    this.thingTypeControl.enable({ emitEvent: false });
                  }),
                );
            }),
            tap(() => {
              this.loading = false;
              this.thingTypeControl.enable({ emitEvent: false });
            }),
            catchError((err) => {
              this.notif.showError(
                `Error loading factory firmwares for thingtype ${thingType}`,
                err,
              );
              return of([]);
            }),
          );
      }),
      tap(() => {
        this.loading = false;
        this.thingTypeControl.enable({ emitEvent: false });
      }),
      shareReplay(1),
    );
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        if (
          params.thingType &&
          this.thingTypeControl.value !== params.thingType
        ) {
          this.thingTypeControl.setValue(params.thingType);
        }
      });
  }

  typeFactoryFirmware(
    untyped: SearchableFactoryFirmware,
  ): SearchableFactoryFirmware {
    return untyped;
  }
}
