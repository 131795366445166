export type IotTopic = string & { _: 'IotTopic' };

export class IotMessage {
  public topic: IotTopic;
  public payload: string;
  public date: Date;
  public collapsed: boolean;

  constructor(topic: IotTopic, payload: string, date: Date) {
    this.topic = topic;
    this.payload = payload;
    this.date = date;
    this.collapsed = true;
  }

  public static fromRaw(destinationName: string, payload: string): IotMessage {
    return new IotMessage(destinationName as IotTopic, payload, new Date());
  }
}
