import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../../lib/utils.service';

@Pipe({
  name: 'displayFormatDate',
})
export class DisplayFormatDatePipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}

  transform(date: string): unknown {
    return this.utilsService.formatDate(date);
  }
}
