import * as moment from 'moment';
import { ThingType } from './thingtype';

export type GroupCriteriaType = 'THINGTYPE' | 'RANGE' | 'CMMF';
export type AddToGroupType = 'CHOOSE_THING' | 'CHOOSE_GROUP';

export class GroupOfThings {
  groupId: string;
  groupName: string;
  creationDate: Date;
  creationDateStr: string;
  thingType: ThingType;
  criteriaType: GroupCriteriaType;
  criteriaValue?: string;
  numberOfThings: number;

  constructor(
    groupId: string,
    groupName: string,
    creationDate: Date,
    creationDateStr: string,
    thingType: ThingType,
    criteriaType: GroupCriteriaType,
    numberOfThings: number,
    criteriaValue?: string,
  ) {
    this.groupId = groupId;
    this.groupName = groupName;
    this.creationDate = creationDate;
    this.creationDateStr = creationDateStr;
    this.thingType = thingType;
    this.criteriaType = criteriaType;
    this.criteriaValue = criteriaValue;
    this.numberOfThings = numberOfThings;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  public static parseDynamoDB(item: any): GroupOfThings {
    const creationDate = moment(item.creationDate);
    return {
      ...item,
      creationDate: creationDate.toDate(),
      creationDateStr: creationDate.format('YYYY-MM-DD HH:mm:ss'),
    };
  }
}
