import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  MonthProductNumbers,
  ProductNumbers,
} from '../../../models/device-stats';
import { ChartDataset, ChartOptions } from 'chart.js';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-number-of-products-chart',
  templateUrl: './number-of-products-chart.component.html',
  styleUrls: ['./number-of-products-chart.component.css'],
})
export class NumberOfProductsChartComponent implements OnChanges {
  @Input() numberOfProducts?: MonthProductNumbers;

  _chartData: ChartDataset[] = [];

  readonly _chartOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        mode: 'index',
      },
      legend: {
        position: 'bottom',
        labels: {
          boxHeight: 0,
        },
      },
    },
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.numberOfProducts?.currentValue?.productsNumbers) {
      this._chartData =
        changes.numberOfProducts.currentValue.productsNumbers.map(
          (productNumbers: ProductNumbers) => ({
            label: productNumbers.productName,
            data: productNumbers.monthNumbers,
            fill: false,
          }),
        );
    }
  }
}
