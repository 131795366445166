<!-- Begin Page Content -->
<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-flex justify-content-between align-items-center">
    <ng-container [ngSwitch]="deployType">
      <h1 class="text-gray-800" *ngSwitchCase="'SINGLE'">
        MetaVersions available for Thing : {{ this.thingName }}
      </h1>
      <h1 class="text-gray-800" *ngSwitchCase="'GROUP'">
        MetaVersions available for Group : {{ this.group?.groupName }}
      </h1>
      <h1 class="text-gray-800" *ngSwitchDefault>MetaVersions</h1>
    </ng-container>
    <div *canCreateEditMetaversion>
      <a class="btn btn-primary" routerLink="/newfirmware">
        <i class="fas fa-plus" aria-hidden="true"></i> New MetaVersion
      </a>
    </div>
  </div>
  <!-- Page Body -->
  <div class="card shadow mb-4">
    <div class="card-header py-3">
      <form action="" class="form-inline" *ngIf="!this.thingName">
        <div class="form-row align-items-center">
          <h6 class="m-0 font-weight-bold text-primary mr-4">
            Metaversions List
          </h6>
          <div class="form-row align-items-center">
            <div class="col-auto">
              <div class="form-check form-switch">
                <input
                  type="checkbox"
                  id="activated"
                  name="activated"
                  [formControl]="activeMetaversionControl"
                  class="form-check-input"
                />
                <label class="form-check-label" for="activated">
                  Active metaVersions
                </label>
              </div>
            </div>
            <div class="col-auto">
              <input
                class="form-control"
                [formControl]="globalSearchInput"
                placeholder="Global search"
              />
            </div>
            <div class="col-auto">
              <button class="btn btn-primary" (click)="resetFilter()">
                Reset filters
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <app-ngx-table-with-query-params-persistence
          [configuration]="configuration"
          [data]="metaVersions"
          [columns]="columns"
          (event)="ngxEventEmitted($event)"
          [globalSearchControl]="globalSearchInput"
          [filtersTemplate]="filtersTemplate"
          [filtersFormGroup]="formGroupLocalFilters"
          [strictMatchColumns]="['criteria']"
        >
          <ng-template let-untypedMetaversion>
            <!-- Allows IDEs to correctly infer the object type to provide completion and checks -->
            <ng-container
              *ngIf="
                typedSearchableMetaversion(untypedMetaversion) as metaversion
              "
            >
              <td
                [ngClass]="{
                  'metaversion-enabled': metaversion.enabledState === 'ENABLED',
                }"
              >
                {{ metaversion.dateStr }}
              </td>
              <td
                class="text-center"
                [ngClass]="{
                  'metaversion-enabled': metaversion.enabledState === 'ENABLED',
                }"
              >
                {{ metaversion.thingType }}
              </td>
              <td
                [ngClass]="{
                  'metaversion-enabled': metaversion.enabledState === 'ENABLED',
                }"
              >
                {{ metaversion.id }}
              </td>
              <td
                [ngClass]="{
                  'metaversion-enabled': metaversion.enabledState === 'ENABLED',
                }"
              >
                {{ metaversion.criteria }}
              </td>
              <td
                [ngClass]="{
                  'metaversion-enabled': metaversion.enabledState === 'ENABLED',
                }"
              >
                  <div class="d-flex align-items-center" *ngIf="metaversion.wifiFirmware">
                  <a
                    *canSeeFirmwareDetails
                    [ngStyle]="{
                      color: metaversion.wifiFirmware?.activated
                        ? 'green'
                        : 'red',
                    }"
                    class="a-link"
                    routerLink="{{
                      '/firmwarefile/' + metaversion.wifiFirmware.id
                    }}"
                  >
                    {{ metaversion.wifiFirmware | displayMetaVersionWifi }}
                  </a>
                  <span
                    *canSeeFirmwareDetails="false"
                    [ngStyle]="{
                      color: metaversion.wifiFirmware?.activated
                        ? 'green'
                        : 'red',
                    }"
                  >
                    {{ metaversion.wifiFirmware | displayMetaVersionWifi }}
                  </span>
                  @if(metaversion.wifiFirmware.versionFlag === VersionFlag.OFFICIAL){
                    <span class="badge text-bg-success ml-1">Official</span>
                  }
                  </div>

                <div class="d-flex align-items-center" *ngIf="metaversion.uiFirmware">
                  <a
                    *canSeeFirmwareDetails
                    [ngStyle]="{
                      color: metaversion.uiFirmware?.activated
                        ? 'green'
                        : 'red',
                    }"
                    class="a-link"
                    routerLink="{{
                      '/firmwarefile/' + metaversion.uiFirmware.id
                    }}"
                  >
                    {{ metaversion.uiFirmware | displayMetaVersionWifi }}
                  </a>
                  <span
                    *canSeeFirmwareDetails="false"
                    [ngStyle]="{
                      color: metaversion.uiFirmware?.activated
                        ? 'green'
                        : 'red',
                    }"
                  >
                    {{ metaversion.uiFirmware | displayMetaVersionWifi }}
                  </span>
                  @if(metaversion.uiFirmware.versionFlag === VersionFlag.OFFICIAL){
                    <span class="badge text-bg-success ml-1">Official</span>
                  }
                </div>
              </td>
              <td
                class="text-center"
                [ngClass]="{
                  'metaversion-enabled': metaversion.enabledState === 'ENABLED',
                }"
              >
                <div
                  class="badge text-wrap"
                  [ngClass]="{
                    'badge-secondary': metaversion.enabledState === 'DISABLED',
                    'badge-success': metaversion.enabledState === 'ENABLED',
                  }"
                >
                  {{ metaversion.enabledState }}<br />{{
                    metaversion.enabledDate
                  }}
                </div>
              </td>
              <td
                class="text-center"
                [ngClass]="{
                  'metaversion-enabled': metaversion.enabledState === 'ENABLED',
                }"
              >
                <span>{{ metaversion.createdBy | displayCreatorName }}</span>
              </td>
              <td
                class="text-center"
                [ngClass]="{
                  'metaversion-enabled': metaversion.enabledState === 'ENABLED',
                }"
              >
                <ng-container [ngSwitch]="deployType">
                  <div *ngSwitchDefault class="btn-group">
                    <ng-container *ngIf="activated === 1">
                      <a
                        class="btn btn-eye"
                        routerLink="/metaversions/{{ metaversion.id }}"
                        *canSeeMetaversionDetails
                      >
                        <i class="fas fa-eye" aria-hidden="true"></i>
                      </a>
                      <button
                        class="btn btn-danger"
                        *canDeprecateMetaversion
                        (click)="
                          deleteMetaVersion(metaversion.id, deprecateModal)
                        "
                        ngbTooltip="Deprecate"
                      >
                        <i class="fas fa-trash-alt" aria-hidden="true"></i>
                      </button>
                      <ng-container *ngIf="!metaversion.enabledDate">
                        <button
                          class="btn btn-success"
                          *canCreateEditMetaversion
                          (click)="toggleMetaVersion(false, metaversion.id)"
                        >
                          Enable
                        </button>
                      </ng-container>
                      <ng-container *ngIf="metaversion.enabledDate">
                        <button
                          class="btn btn-secondary"
                          *canCreateEditMetaversion
                          (click)="toggleMetaVersion(true, metaversion.id)"
                        >
                          Disable
                        </button>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="activated === 0">
                      <button
                        *canDeprecateMetaversion
                        class="btn btn-success"
                        (click)="
                          activateMetaVersion(metaversion.id, activateModal)
                        "
                      >
                        Activate
                      </button>
                    </ng-container>
                  </div>
                  <ng-container *ngIf="activated === 1">
                    <div *ngSwitchCase="'GROUP'" class="btn-group">
                      <button
                        class="btn btn-primary"
                        (click)="
                          deployMetaVersionOnThings(metaversion, deployModal)
                        "
                        *canDeployOnGroupOfThings
                      >
                        <i
                          class="fas fa-cloud-upload-alt"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                    <div *ngSwitchCase="'SINGLE'" class="btn-group">
                      <button
                        class="btn btn-primary"
                        (click)="
                          deployMetaVersionOnThings(metaversion, deployModal)
                        "
                        *canDeployOnOneThing
                      >
                        <i
                          class="fas fa-cloud-upload-alt"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </ng-container>
                </ng-container>
              </td>
              <ng-template #deployModal let-modal>
                <div class="modal-body">
                  <h2>Confirmation</h2>
                  <hr />
                  <ng-container [ngSwitch]="deployType">
                    <div *ngSwitchCase="'SINGLE'">
                      <p>
                        You are upgrading<br /><strong>{{ thingName }}</strong>
                      </p>
                      <p>
                        With version<br /><strong>{{ metaversion.id }}</strong>
                      </p>
                    </div>
                    <div *ngSwitchCase="'GROUP'">
                      <p>
                        You are upgrading the Group<br /><strong>{{
                          group.groupName
                        }}</strong>
                      </p>
                      <p>
                        Containing <strong>{{ things.length }}</strong> devices
                      </p>
                      <p>
                        With version<br /><strong>{{ metaversion.id }}</strong>
                      </p>
                    </div>
                  </ng-container>
                  <p>Are you sure?</p>
                  <hr />
                  <button
                    (click)="modal.close(true)"
                    class="btn btn-primary float-right button-model"
                  >
                    Deploy
                  </button>
                  <button
                    (click)="modal.close(false)"
                    class="btn btn-secondary float-right"
                  >
                    Cancel
                  </button>
                </div>
              </ng-template>
              <ng-template #deprecateModal let-modal>
                <div class="modal-body">
                  <h2>Confirmation</h2>
                  <p>
                    You are setting firmware
                    <strong>{{ metaversion.id }}</strong> as deprecated.<br />
                    Are you sure?
                  </p>
                  <button
                    (click)="modal.close(true)"
                    class="btn btn-primary float-right button-model"
                  >
                    Go
                  </button>
                  <button
                    (click)="modal.close(false)"
                    class="btn btn-secondary float-right"
                  >
                    Cancel
                  </button>
                </div>
              </ng-template>
              <ng-template #activateModal let-modal>
                <div class="modal-body">
                  <h2>Confirmation</h2>
                  <p>
                    You are setting firmware
                    <strong>{{ metaversion.id }}</strong> as active.<br />
                    Are you sure?
                  </p>
                  <button
                    (click)="modal.close(true)"
                    class="btn btn-primary float-right button-model"
                  >
                    Go
                  </button>
                  <button
                    (click)="modal.close(false)"
                    class="btn btn-secondary float-right"
                  >
                    Cancel
                  </button>
                </div>
              </ng-template>
            </ng-container>
          </ng-template>

          <ng-template #filtersTemplate>
            <ng-container [formGroup]="formGroupLocalFilters">
              <th scope="col" class="pl-0 pr-3 pt-0">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Creation date"
                  formControlName="dateStr"
                />
              </th>
              <th scope="col" class="pl-0 pr-3 pt-0">
                <select
                  class="form-select"
                  formControlName="thingType"
                  [disabled]="(thingTypes$ | async) == null"
                >
                  <option [ngValue]="null">All</option>
                  <option
                    *ngFor="let thingType of thingTypes$ | async"
                    [ngValue]="thingType"
                  >
                    {{ thingType }}
                  </option>
                </select>
              </th>
              <th scope="col" class="pl-0 pr-3 pt-0">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Name"
                  formControlName="id"
                />
              </th>
              <th scope="col" class="pl-0 pr-3 pt-0">
                <select class="form-select" formControlName="criteria">
                  <option [ngValue]="null">All</option>
                  <option ngValue="THINGTYPE">Thing type</option>
                  <option ngValue="RANGE">Range</option>
                  <option ngValue="CMMF">Cmmf</option>
                  <option ngValue="RANGE_INDICE">Range-indice</option>
                  <option ngValue="CMMF_INDICE">Cmmf-indice</option>
                </select>
              </th>
              <th scope="col" class="pl-0 pr-3 pt-0">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Firmware Files"
                  formControlName="firmwareFiles"
                />
              </th>
              <th scope="col">
                <select class="form-select" formControlName="enabledState">
                  <option [ngValue]="null">All</option>
                  <option ngValue="ENABLED">Enabled</option>
                  <option ngValue="DISABLED">Disabled</option>
                </select>
              </th>
              <th scope="col" class="pl-0 pr-3 pt-0">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Creator"
                  formControlName="createdBy"
                />
              </th>
            </ng-container>
          </ng-template>
        </app-ngx-table-with-query-params-persistence>
      </div>
    </div>
  </div>
</div>
