import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { SearchResult } from '../api/backend/services/things/things.model';
import { ThingsService } from '../api/backend/services/things/things.service';
import { ThingTypesService } from '../api/thing-types.service';
import { AddThingToGroupComponent } from '../groups-of-things/groups-of-things-list/add-thing-to-group/add-thing-to-group.component';
import { UtilsService } from '../lib/utils.service';
import { Shadow } from '../models/shadow';
import { ThingListDisplay } from '../models/thing-list-display.model';
import { ThingData, ThingType } from '../models/thingtype';
import { NotificationService } from '../shared/notification.service';
import { scrollToTop } from '../shared/utils/window.utils';
import { ThingUtils } from '../utils/thing.utils';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit {
  isLoading = false;
  thingTypes: ThingType[] = [];

  // Search related
  paginationCache = new Map<number, SearchResult>();
  thingType?: string;
  searchTerm?: string;
  devices: ThingListDisplay[] = [];
  pageNumber = 0;
  disablePreviousPage = true;
  disableNextPage = false;

  // Devices informations
  shadow?: Shadow;
  lastConnection?: string;
  reportedFirmware?: string;
  reportedNextFirmware?: string;
  desiredNextFirmware?: string;

  readonly columns = [
    { key: 'name', title: 'Name', width: '25%' },
    { key: 'connectionTimeStr', title: 'Last Connection', width: '10%' },
    { key: 'currentFirmware', title: 'Current Firmware', width: '10%' },
    {
      key: 'nextFirmwareReported',
      title: 'Next Firmware (reported)',
      searchEnabled: true,
      width: '10%',
    },
    {
      key: 'nextFirmwareDesired',
      title: 'Next Firmware (desired)',
      orderEnabled: true,
      width: '10%',
    },
    {
      key: 'actions',
      title: 'Actions',
      orderEnabled: false,
      searchEnabled: false,
      width: '4%',
    },
  ] as Columns[];

  configuration: Config = {
    ...DefaultConfig,
    searchEnabled: true,
    paginationEnabled: false,
    rows: 1000000,
    orderEnabled: true,
    isLoading: this.isLoading,
  };

  formGroupLocalFilters = new UntypedFormGroup({
    name: new UntypedFormControl(),
    connectionTimeStr: new UntypedFormControl(),
    currentFirmware: new UntypedFormControl(),
    nextFirmwareReported: new UntypedFormControl(),
    nextFirmwareDesired: new UntypedFormControl(),
  });

  constructor(
    private readonly router: Router,
    private readonly notif: NotificationService,
    private readonly toastrService: ToastrService,
    private readonly utilsService: UtilsService,
    private readonly ngbModal: NgbModal,
    private readonly thingTypesService: ThingTypesService,
    private readonly thingsService: ThingsService,
  ) {}

  ngOnInit(): void {
    this.getThingTypes();
  }

  onChangeSearchValue(event: Event): void {
    const searchTerm = (event.target as HTMLInputElement).value;

    const parts = searchTerm.split('-');
    const firstPartThingType = parts[0];
    const thingTypeProvided = this.thingTypes.includes(
      firstPartThingType as ThingType,
    );

    if (thingTypeProvided) {
      this.thingType = firstPartThingType;
      this.searchTerm = parts.splice(1).join('-');
    } else {
      this.searchTerm = searchTerm;
    }
  }

  async search(searchTerm: string = ''): Promise<void> {
    if (searchTerm?.length > 1 && searchTerm.startsWith('*')) {
      this.notif.showError("Search term can't start with a wildcard");
      return;
    }
    if (ThingUtils.isSerialNumber(searchTerm) && !this.thingType) {
      this.notif.showError('You must first select a Thing Type');
      return;
    }

    this.isLoading = true;
    this.pageNumber = 0;
    this.paginationCache.clear();

    let searchResult: SearchResult = { things: [] };

    try {
      searchResult = await this.getCacheOrSearch(
        searchTerm,
        this.pageNumber,
        this.thingType,
      );

      if (!searchResult.things.length) {
        this.toastrService.info('No device found');
      }
    } catch (e) {
      console.error(e);
      this.notif.showError((e as Error).message, e);
    }

    if (searchResult.things.length === 1) {
      const thingName = searchResult.things.shift()!.thingName;
      await this.router.navigateByUrl(`/things/${thingName}`);
      return;
    }

    this.devices = []; // to avoid emptying the table then waiting for result
    this.setResultAsCurrentPage(searchResult, 0);
  }

  async getCacheOrSearch(
    searchTerm: string,
    page: number,
    thingType: string | undefined,
    nextToken?: string | undefined,
  ): Promise<SearchResult> {
    const cachedResult = this.paginationCache.get(page);
    if (cachedResult) return cachedResult;

    const searchResult = await lastValueFrom(
      this.thingsService.search(searchTerm, thingType, undefined, nextToken),
    );

    this.paginationCache.set(page, searchResult);

    return searchResult;
  }

  setResultAsCurrentPage(result: SearchResult, page: number): void {
    this.isLoading = false;
    this.paginationCache.set(page, result);
    this.devices = ThingListDisplay.fromSearchThings(result.things);
    this.pageNumber = page;
    this.disableNextPage = !result.nextToken;
    this.disablePreviousPage = page === 0;
  }

  async nextDevices(): Promise<void> {
    const currentPageResult = this.paginationCache.get(this.pageNumber);
    if (!currentPageResult?.nextToken) return;

    this.isLoading = true;
    const nextPage = this.pageNumber + 1;

    try {
      const nextPageResult = await this.getCacheOrSearch(
        this.searchTerm ?? '',
        nextPage,
        this.thingType,
        currentPageResult.nextToken,
      );
      this.setResultAsCurrentPage(nextPageResult, nextPage);
      this.disablePreviousPage = false;
    } catch (e) {
      console.error(e);
      this.notif.showError((e as Error).message, e);
      this.isLoading = false;
      return;
    }
    scrollToTop();
  }

  previousDevices(): void {
    if (this.pageNumber <= 0) return;

    const previousPage = this.pageNumber - 1;
    const cachedResult = this.paginationCache.get(previousPage)!;
    this.setResultAsCurrentPage(cachedResult, previousPage);
    scrollToTop();
  }

  displayDate(str: string): void {
    this.shadow = new Shadow(JSON.parse(str || '{}'));
    this.lastConnection = this.utilsService.formatDate(
      this.shadow.lastConnection(),
    );

    this.reportedFirmware =
      this.utilsService.getVersions(this.shadow.reportedFirmware()) || '-';

    this.reportedNextFirmware =
      this.utilsService.getVersions(this.shadow.reportedNextFirmware()) || '-';

    this.desiredNextFirmware =
      this.utilsService.getVersions(this.shadow.desiredNextFirmware()) || '-';
  }

  getThingTypes(): void {
    this.isLoading = true;
    this.thingTypesService.getThingTypes().subscribe(
      (thingTypes) => {
        this.thingTypes = thingTypes as ThingType[];
      },
      (err) => {
        this.notif.showError(err.message, err);
      },
      () => {
        this.isLoading = false;
      },
    );
  }

  async addThingToAGroup(thing: ThingListDisplay): Promise<void> {
    const modal = this.ngbModal.open(AddThingToGroupComponent, {
      backdrop: 'static',
      centered: true,
    });

    modal.componentInstance.thing = ThingData.fromThingListDisplay(thing);
  }

  clearSearch(): void {
    this.searchTerm = undefined;
  }
}
