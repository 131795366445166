<app-ngx-table-with-query-params-persistence
  [configuration]="configuration"
  [data]="data"
  [columns]="columns"
>
  <ng-template let-untypedFirmware>
    <!-- Allows IDEs to correctly infer the object type to provide completion and checks -->
    <ng-container *ngIf="typedRow(untypedFirmware) as rowFirmwareValue">
      <td>{{ rowFirmwareValue.date | displayFormatDate }}</td>
      <td *ngIf="shouldDisplayRange">{{ rowFirmwareValue.range }}</td>
      <td *ngIf="shouldDisplayCmmf">{{ rowFirmwareValue.cmmf }}</td>
      <td *ngIf="shouldDisplayIndice">{{ rowFirmwareValue.indice }}</td>
      <td *ngIf="shouldDisplayBrandArea">{{ rowFirmwareValue.brandArea }}</td>
      <td>
        <div
          class="btn-group w-100 text-left"
          (click)="onDownloadFirmware(rowFirmwareValue)"
        >
          <button
            class="btn w-100"
            [ngClass]="{
              'btn-outline-primary': rowFirmwareValue.signed,
              'btn-outline-secondary': !rowFirmwareValue.signed,
            }"
            disabled
          >
            {{ rowFirmwareValue.fileName }}
          </button>
          <button
            class="btn"
            [ngClass]="{
              'btn-primary': rowFirmwareValue.signed,
              'btn-secondary text-white': !rowFirmwareValue.signed,
            }"
            [disabled]="!rowFirmwareValue.signed"
          >
            <i
              class="fas fa-cloud-download-alt m-auto"
              *ngIf="
                (rowFirmwareValue.loadingPresignUrl$ | async) === false;
                else downloadLoader
              "
              aria-hidden="true"
            ></i>
          </button>
        </div>
      </td>
      <td>{{ rowFirmwareValue.signing }}</td>
      <td>{{ rowFirmwareValue.bootloader }}</td>
      <td>{{ rowFirmwareValue.error }}</td>
    </ng-container>
  </ng-template>
</app-ngx-table-with-query-params-persistence>

<ng-template #downloadLoader>
  <app-spinner-small></app-spinner-small>
</ng-template>
