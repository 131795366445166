import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, switchMap } from 'rxjs';
import CONFIG from '../../../../../config';
import { AuthorizationService } from '../../../authorization.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly authorizationService: AuthorizationService) {}

  intercept(
    req: HttpRequest<unknown>,
    handler: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (!req.url.startsWith(CONFIG.backendUrl)) {
      return handler.handle(req);
    }

    return from(this.authorizationService.getSession()).pipe(
      switchMap((session) => {
        const newReq = req.clone({
          headers: req.headers.set(
            'Authorization',
            session.tokens!.idToken!.toString(),
          ),
        });

        return handler.handle(newReq);
      }),
    );
  }
}
