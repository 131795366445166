import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Product } from '../../models/product';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  private readonly apiUrl = environment.apiUrl;

  constructor(private readonly http: HttpClient) {}

  getProductByRange(range: string): Observable<Product> {
    return this.http.get<Product>(
      `${this.apiUrl}/pi2-service/ranges/${range}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }
}
