export interface ExportStatistics {
  presignedUrl: string;
}

export const exportableThingTypes = [
  'VAC001',
  'COO001',
  'COO002',
  'PUR001',
  'COM001',
  'COM002',
  'COF001',
  'MUL001',
  'KMC001',
];
