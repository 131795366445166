<div class="card shadow" *ngIf="group || thing; else nothingToShow">
  <div class="card-header">
    <h1>Add to Group</h1>
  </div>
  <form [formGroup]="formGroup" (ngSubmit)="submitForm($event)">
    <div class="card-body">
      <ng-container *ngIf="typeOfAdding === 'CHOOSE_THING'; else chooseGroup">
        <label for="serialNumber">
          MAC Address, Serial Number, or Thing Name
        </label>
        <input
          formControlName="serialNumber"
          class="form-control"
          id="serialNumber"
          name="serialNumber"
          type="text"
          (keydown.enter)="submitForm($event)"
        />
      </ng-container>
      <ng-template #chooseGroup>
        <label for="groupAutocomplete"> Group name </label>
        <ng-autocomplete
          formControlName="group"
          placeholder="Enter group name"
          notFoundText="No group found"
          *ngIf="groups$ | async as _groups; else loading"
          id="groupAutocomplete"
          [data]="_groups"
          initialValue=""
          searchKeyword="groupName"
          (selected)="selectGroup($event)"
          (inputCleared)="clearGroup()"
          [itemTemplate]="itemTemplate"
        ></ng-autocomplete>
        <ng-template #loading>
          <app-spinner-small></app-spinner-small>
        </ng-template>
        <ng-template #itemTemplate let-item>
          <a
            *ngIf="typeGroup(item) as _group"
            [innerHTML]="_group.groupName + ' (' + _group.numberOfThings + ')'"
          ></a>
        </ng-template>
      </ng-template>
      <br />
      <small class="form-text font-italic text-warning"
        >You may need to refresh the page in a minute for the list to be
        updated.</small
      >
    </div>
    <div class="card-footer d-flex flex-row w-100 justify-content-end">
      <button type="button" class="btn btn-secondary mr-2" (click)="cancel()">
        <i class="fas fa-times mr-1" aria-hidden="true"></i>
        Cancel
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="formGroup.invalid"
      >
        <i class="fas fa-check mr-1" aria-hidden="true"></i>
        Add
      </button>
    </div>
  </form>
</div>
<ng-template #nothingToShow>
  <div class="card shadow">
    <div class="card-header">
      <h3>No Group or Thing selected</h3>
    </div>
    <div class="card-body">
      <p>Please refresh the page and retry.</p>
    </div>
    <div class="card-footer d-flex flex-row w-100 justify-content-end">
      <button type="button" class="btn btn-secondary mr-2" (click)="cancel()">
        <i class="fas fa-times mr-1" aria-hidden="true"></i>
        Close
      </button>
    </div>
  </div>
</ng-template>
