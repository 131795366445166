import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import {
  DownloadedLog,
  ProductLog,
} from '../../../models/backend/product-logs';
import { DeleteProductLogDialogComponent } from '../../../shared/delete-product-log-dialog/delete-product-log-dialog.component';
import { NotificationService } from '../../../shared/notification.service';

@Component({
  selector: 'app-logs-reader',
  templateUrl: './logs-reader.component.html',
  styleUrls: ['./logs-reader.component.scss'],
})
export class LogsReaderComponent {
  @Input() log?: ProductLog;
  @Input() logFile$?: Observable<void | {
    file: DownloadedLog;
    filename: string;
  }>;
  @Input() thingName?: string;
  @Input() mac?: string;

  @Output() deleted = new EventEmitter<boolean>();

  private saveAs = FileSaver.saveAs;

  constructor(
    private readonly modalRef: NgbActiveModal,
    private readonly notif: NotificationService,
    private readonly modal: NgbModal,
  ) {}

  close(): void {
    this.modalRef.close();
  }

  saveFile(file: DownloadedLog, filename: string): void {
    this.saveAs(file.getSavableBlob(), filename);
  }

  deleteLog(): void {
    const dialogInstance = this.modal.open(DeleteProductLogDialogComponent, {
      backdrop: 'static',
      centered: true,
    });

    dialogInstance.componentInstance.thingName = this.thingName;
    dialogInstance.componentInstance.mac = this.mac;
    dialogInstance.componentInstance.logfile = this.log?.filename;

    dialogInstance.result.then((deleted) => {
      if (!deleted) {
        this.notif.showError('Unable to delete log file');
      } else {
        this.close();
      }
      this.deleted.next(deleted);
    });
  }
}
