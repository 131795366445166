import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'withMsDate',
  standalone: true,
})
export class WithMsDatePipe implements PipeTransform {
  transform(value: number): number {
    if (value.toString().length === 10) {
      return value * 1000;
    }

    return value;
  }
}
