export enum Priority {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export function getPriorityFromString(str?: string): Priority {
  return (
    Object.values(Priority).find((val: Priority) => val === str) ??
    Priority.MEDIUM
  );
}
