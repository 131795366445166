<!-- Begin Page Content -->
<div class="container-fluid" *ngIf="firmware$ | async as firmware">
  <!-- Page Heading -->
  <h1 class="text-gray-800">
    Factory firmware <strong>{{ firmware.id }}</strong>
  </h1>
  <app-audit-creator
    [type]="AuditType.FACTORY_FIRMWARE"
    [action]="AuditAction.UPLOAD"
    [resourceId]="firmware.id"
  />
  <!-- Page Body -->
  <div class="card shadow mb-4">
    <!-- Begin of filter bar -->
    <div class="card-header">
      <div class="d-flex flex-row justify-content-between">
        <div>
          <span
            class="badge"
            [ngClass]="{
              'badge-success': firmware.status === 'SIGNED',
              'badge-danger': firmware.status === 'SIGN_ERROR',
              'badge-warning': firmware.status === 'SIGN_IN_PROGRESS',
            }"
          >
            {{ firmware.status }}
          </span>
          <span class="ml-3"
            ><strong>Thing type : </strong>{{ firmware.thingType }}</span
          >
          <span class="ml-3"
            ><strong>Creation date : </strong
            >{{ firmware.creationDate | displayFormatDate }}</span
          >
        </div>
        <button class="btn btn-danger" (click)="delete(firmware)">
          <i class="fas fa-trash-alt mr-1" aria-hidden="true"></i>
          Delete version
        </button>
      </div>
    </div>
    <!-- end of filter bar -->
    <!-- Begin content -->
    <div class="card-body" *ngIf="firmware.files as files">
      <h2>Signed files</h2>
      <ul class="files-list">
        <li *ngFor="let file of files" class="mb-1">
          <div class="btn-group text-left" (click)="downloadFile(file)">
            <button class="btn btn-outline-primary" disabled>
              {{ file.filename }}
            </button>
            <button class="btn btn-primary">
              <i
                class="fas fa-cloud-download-alt"
                *ngIf="(file.loading$ | async) !== true; else downloadLoader"
                aria-hidden="true"
              ></i>
            </button>
          </div>
        </li>
      </ul>
    </div>
    <div class="card-body" *ngIf="firmware.errors as errors">
      <h2>Signing errors</h2>
      <ul>
        <li *ngFor="let error of errors">
          {{ error }}
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #downloadLoader>
  <app-spinner-small></app-spinner-small>
</ng-template>
