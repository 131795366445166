import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BaseFactoryFirmware,
  CreateFactoryFirmware,
  FactoryFirmware,
  FileUploadPresignUrl,
} from '../../../../models/factory-firmware';
import { map, mapTo, switchMap, timeout } from 'rxjs/operators';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FactoryFirmwaresService {
  private readonly backendUrl = environment.backendUrl;

  constructor(private readonly http: HttpClient) {}

  /**
   * Returns the list of existing factory firmware versions for a given thing type
   *
   * @param thingType
   */
  listFactoryFirmwares(thingType: string): Observable<BaseFactoryFirmware[]> {
    return this.http
      .get<
        BaseFactoryFirmware[]
      >(`${this.backendUrl}/factory-firmwares?thingType=${thingType}`)
      .pipe(
        map((factoryFirmwares) =>
          factoryFirmwares.map((factoryFirmware) => ({
            ...factoryFirmware,
            creationDate: moment(factoryFirmware.creationDate).format(
              'YYYY-MM-DD HH:mm:ss',
            ),
          })),
        ),
      );
  }

  /**
   * Fetches the details about a factory firmware
   *
   * @param thingType
   * @param version
   */
  getFactoryFirmware(
    thingType: string,
    version: string,
  ): Observable<FactoryFirmware> {
    return this.http.get<FactoryFirmware>(
      `${this.backendUrl}/factory-firmwares/${encodeURIComponent(version)}?thingType=${thingType}`,
    );
  }

  /**
   * Deletes a factory firmware
   *
   * @param thingType
   * @param version
   */
  deleteFactoryFirmware(thingType: string, version: string): Observable<void> {
    return this.http.delete<void>(
      `${this.backendUrl}/factory-firmwares/${encodeURIComponent(version)}?thingType=${thingType}`,
    );
  }

  /**
   * Create a new factory firmware
   *
   * @param firmware
   */
  createFactoryFirmware(firmware: CreateFactoryFirmware): Observable<void> {
    return this.http.put<void>(
      `${this.backendUrl}/factory-firmwares/${encodeURIComponent(firmware.version)}`,
      {
        thingType: firmware.thingType,
        files: firmware.files,
      },
    );
  }

  /**
   * Uploads a file to the temporary S3 bucket on the backend.
   *
   * @param file
   * @return temporary filename created on backend side
   */
  uploadFile(file: File): Observable<string> {
    return this.http
      .get<FileUploadPresignUrl>(
        `${this.backendUrl}/firmwares/binaries/${encodeURIComponent(file.name)}/presign-url`,
      )
      .pipe(
        switchMap((presignUrlResponse) =>
          this.http
            .put(presignUrlResponse.url, file)
            .pipe(mapTo(presignUrlResponse.temporaryFilename), timeout(10000)),
        ),
      );
  }
}
