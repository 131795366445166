import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-variation',
  templateUrl: './variation.component.html',
  styleUrls: ['./variation.component.css'],
})
export class VariationComponent implements OnChanges {
  @Input() value?: number;
  _iconClass = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value) {
      const newValue = changes.value.currentValue;

      if (newValue < -0.05) {
        this._iconClass = 'fa fa-arrow-down';
      } else if (newValue < 0.05) {
        this._iconClass = 'fa fa-arrow-right';
      } else {
        this._iconClass = 'fa fa-arrow-up';
      }
    }
  }
}
