import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuditAction, AuditType } from '@common/audit-log/models/AuditLog';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { saveAs as importedSaveAs } from 'file-saver';
import { combineLatest, from, Observable, Subject } from 'rxjs';
import {
  catchError,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import { FactoryFirmwaresService } from '../../api/backend/services/factory-firmwares/factory-firmwares.service';
import { FirmwareService } from '../../api/backend/services/firmware/firmware.service';
import {
  FactoryFirmware,
  FactoryFirmwareFile,
} from '../../models/factory-firmware';
import { NotificationService } from '../../shared/notification.service';

import { FactoryFirmwareDeleteComponent } from './factory-firmware-delete/factory-firmware-delete.component';

@Component({
  selector: 'app-factory-firmware-detail',
  templateUrl: './factory-firmware-detail.component.html',
  styleUrls: ['./factory-firmware-detail.component.scss'],
})
export class FactoryFirmwareDetailComponent implements OnInit, OnDestroy {
  firmware$?: Observable<FactoryFirmware | void>;

  destroy$ = new Subject<void>();

  readonly AuditType = AuditType;
  readonly AuditAction = AuditAction;

  private readonly modalOptions: NgbModalOptions = {
    backdrop: 'static',
    centered: true,
  };

  // Proxy method to allow stubbing in tests
  private saveAs = importedSaveAs;

  constructor(
    private readonly factoryFirmwaresService: FactoryFirmwaresService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly notif: NotificationService,
    private readonly firmwareService: FirmwareService,
    private readonly ngbModal: NgbModal,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.firmware$ = combineLatest([
      this.activatedRoute.params,
      this.activatedRoute.queryParams,
    ]).pipe(
      takeUntil(this.destroy$),
      switchMap(([params, queryParams]) => {
        if (!params.version || !queryParams.thingType) {
          console.error(
            'Wrong navigation data for factory firmware details page, redirecting to list view',
          );
          console.log('params', params);
          console.log('queryParams', queryParams);
          return from(this.router.navigateByUrl('factory-firmwares')).pipe(
            map(() => undefined),
          );
        }
        return this.factoryFirmwaresService
          .getFactoryFirmware(
            queryParams.thingType,
            decodeURIComponent(params.version),
          )
          .pipe(
            catchError((err) =>
              this.notif.showError(
                'Error fetching details for factory firmware',
                err,
              ),
            ),
          );
      }),
      shareReplay(1),
    );
  }

  downloadFile(file: FactoryFirmwareFile): void {
    file.loading$ = this.firmwareService
      .downloadFromPresignUrl(file.presignedUrl)
      .pipe(
        map((blob) => this.saveAs(blob, file.filename)),
        catchError((err) =>
          this.notif.showError(`Error downloading file ${file.filename}`, err),
        ),
        map(() => false),
        startWith(true),
      );
  }

  delete(firmware: FactoryFirmware): void {
    const modal = this.ngbModal.open(
      FactoryFirmwareDeleteComponent,
      this.modalOptions,
    );

    modal.componentInstance.version = firmware.id;
    modal.componentInstance.thingType = firmware.thingType;

    modal.result.then((res) => {
      if (res) {
        this.notif.showSuccess('Factory firmware successfully deleted');
        this.router.navigate(['factory-firmwares'], {
          queryParams: {
            thingType: firmware.thingType,
          },
        });
      }
    });
  }
}
