<!-- Loader -->
<ng-template #loader>
  <app-spinner></app-spinner>
</ng-template>

<h5 class="ml-4">Costs repartition</h5>
<canvas
  baseChart
  *ngIf="_chartDataSets; else loader"
  [datasets]="_chartDataSets"
  [labels]="_chartLabels"
  type="doughnut"
  [options]="_chartOptions"
></canvas>
