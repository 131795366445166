import { Component, OnInit } from '@angular/core';
import CONFIG from 'src/config';
import * as Auth from 'aws-amplify/auth';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit {
  username = 'username';

  async ngOnInit(): Promise<void> {
    if (CONFIG.cognitoUserPoolId && CONFIG.cognitoClientId) {
      const cognitoUser = await Auth.getCurrentUser();
      if (cognitoUser) {
        this.username = cognitoUser.username;
      }
    }
  }

  async logout(): Promise<void> {
    await Auth.signOut();
  }
}
