import { Component, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { ThingTypesService } from '../../api/thing-types.service';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { GroupsOfThingsService } from '../../api/groups-of-things.service';
import { NotificationService } from '../../shared/notification.service';
import { ServiceException } from '@smithy/smithy-client';
import { GroupOfThings } from '../../models/Group-of-things.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { GroupsOfThingsCreateComponent } from './groups-of-things-create/groups-of-things-create.component';
import { Router } from '@angular/router';
import { GroupsOfThingsDeleteComponent } from './groups-of-things-delete/groups-of-things-delete.component';

@Component({
  selector: 'app-groups-of-things-list',
  templateUrl: './groups-of-things-list.component.html',
  styleUrls: ['./groups-of-things-list.component.scss'],
})
export class GroupsOfThingsListComponent implements OnInit {
  _isLoading = false;

  thingTypeControl = new UntypedFormControl();
  thingTypes$?: Observable<string[]>;

  groups$?: Observable<GroupOfThings[]>;
  refreshGroups$: Subject<void> = new BehaviorSubject<void>(void 0);

  readonly resetLocalFilters = new EventEmitter<void>();

  private readonly modalOptions: NgbModalOptions = {
    backdrop: 'static',
    centered: true,
  };

  constructor(
    private readonly thingTypesService: ThingTypesService,
    private readonly groupsService: GroupsOfThingsService,
    private readonly notif: NotificationService,
    private readonly ngbModal: NgbModal,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.thingTypes$ = this.thingTypesService
      .getThingTypes()
      ?.pipe(shareReplay(1));

    this.groups$ = merge(
      this.thingTypeControl.valueChanges,
      this.refreshGroups$,
    ).pipe(
      tap((val) => {
        if (val) {
          this._isLoading = true;
        }
      }),
      map((val) => (val !== 'null' ? val : null)), // Tranforms 'null' string into real null
      switchMap(
        (thingType: string | null) =>
          this.groupsService.listGroups(thingType ?? undefined)?.pipe(
            catchError((err: ServiceException) => {
              this.notif.showError(
                `An error occurred while fetching groups : ${err.message}`,
                err,
              );
              return of([] as GroupOfThings[]);
            }),
          ) ?? of([] as GroupOfThings[]),
      ),
      tap(() => (this._isLoading = false)),
      map((groups) =>
        groups.sort((val1, val2) =>
          val2.creationDateStr.localeCompare(val1.creationDateStr),
        ),
      ),
      shareReplay(1),
    );
  }

  resetFilters(): void {
    this.thingTypeControl.setValue(null);
    this.resetLocalFilters.emit(void 0);
  }

  createGroup(): void {
    const modal = this.ngbModal.open(
      GroupsOfThingsCreateComponent,
      this.modalOptions,
    );

    modal.componentInstance.thingTypes$ = this.thingTypes$;

    modal.result.then((createdGroupId) => {
      if (createdGroupId) {
        // If a new group has been created (the modal was not canceled), navigate to its details
        this.router.navigate(['/groups', createdGroupId]);
      }
    });
  }

  delete(group: GroupOfThings): void {
    const modal = this.ngbModal.open(
      GroupsOfThingsDeleteComponent,
      this.modalOptions,
    );

    modal.componentInstance.group = group;

    modal.result.then((deleted: boolean) => {
      if (deleted) {
        this.notif.showSuccess('Group successfully deleted');
        this.refreshGroups$.next();
      }
    });
  }
}
