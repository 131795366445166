import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace',
  standalone: true,
})
export class ReplacePipe implements PipeTransform {
  transform(
    value: string,
    pattern: string,
    replaceWith: string,
  ): string | undefined {
    if (value.length === 0 || pattern.length === 0) return value;

    return value.replace(new RegExp(pattern, 'ig'), replaceWith);
  }
}
