<!-- Begin Page Content -->
<div class="container-fluid">
  <!-- Page Heading -->
  <div class="d-flex flex-row justify-content-between align-items-center">
    <h1 class="text-gray-800">Factory firmwares</h1>
    <button
      class="btn btn-primary"
      routerLink="/factory-firmwares/new"
      [state]="{ thingType: thingTypeControl.value }"
      *canCreateDeleteFactoryFirmware
    >
      <i class="fas fa-plus mr-1" aria-hidden="true"></i>
      New firmware
    </button>
  </div>
  <!-- Page Body -->
  <div class="card shadow mb-4">
    <!-- Begin of filter bar -->
    <div class="card-header">
      <div class="d-flex flex-row justify-content-between w-100">
        <form action="" class="form-inline">
          <div class="form-row align-items-center g-3">
            <div class="col-auto ml-1">
              <select
                class="form-select"
                aria-label="Default select example"
                id="thingType"
                name="thingType"
                [formControl]="thingTypeControl"
              >
                <option [value]="null">Thing Type</option>
                <option
                  *ngFor="let filter of filters$ | async"
                  [selected]="thingTypeControl.value === filter"
                >
                  {{ filter }}
                </option>
              </select>
            </div>
            <div class="col-auto ml-1" *ngIf="loading">
              <app-spinner-small></app-spinner-small>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- end of filter bar -->
    <!-- Begin content -->
    <div
      class="card-body"
      *ngIf="factoryFirmwares$ | async as factoryFirmwares; else infoMessage"
    >
      <div class="table-responsive">
        <app-ngx-table-with-query-params-persistence
          [columns]="columns"
          [data]="factoryFirmwares!"
          [configuration]="configuration"
          [filtersFormGroup]="localFiltersFormGroup"
          [filtersTemplate]="filtersTemplate"
          [customMatchers]="customMatchers"
        >
          <ng-template let-untypedFactoryFirmware>
            <ng-container
              *ngIf="
                typeFactoryFirmware(untypedFactoryFirmware) as factoryFirmware
              "
            >
              <td>{{ factoryFirmware.id }}</td>
              <td>{{ factoryFirmware.creationDate }}</td>
              <td>{{ factoryFirmware.createdBy | displayCreatorName }}</td>
              <td class="btn-group">
                <a
                  class="btn btn-eye"
                  *canSeeFactoryFirmwareDetails
                  routerLink="/factory-firmwares/{{
                    factoryFirmware.id | urlencode
                  }}"
                  [queryParams]="{ thingType: thingTypeControl.value }"
                >
                  <i class="fas fa-eye" aria-hidden="true"></i>
                </a>
              </td>
            </ng-container>
          </ng-template>
        </app-ngx-table-with-query-params-persistence>
      </div>
    </div>
  </div>
</div>

<ng-template #filtersTemplate>
  <ng-container [formGroup]="localFiltersFormGroup">
    <th scope="col" class="pl-0 pr-3 pt-0">
      <input
        class="form-control"
        type="text"
        formControlName="id"
        placeholder="version"
      />
    </th>
    <th scope="col" class="pl-0 pr-3 pt-0">
      <span class="d-flex flex-row">
        <input
          class="form-control"
          type="text"
          formControlName="creationDateMin"
          placeholder="After"
        />
        <input
          class="form-control"
          type="text"
          formControlName="creationDateMax"
          placeholder="Before"
        />
      </span>
    </th>
    <th scope="col" class="pl-0 pr-3 pt-0">
      <input
        class="form-control"
        type="text"
        formControlName="createdBy"
        placeholder="createdBy"
      />
    </th>
    <th scope="col">
      <button
        class="btn btn-primary"
        (click)="localFiltersFormGroup.reset()"
        ngbTooltip="Reset table filters"
      >
        <i class="fas fa-undo-alt" aria-hidden="true"></i>
      </button>
    </th>
  </ng-container>
</ng-template>

<ng-template #infoMessage>
  <div class="card-body">Please select a thing type.</div>
</ng-template>
