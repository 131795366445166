import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { GroupsOfThingsService } from '../../../api/groups-of-things.service';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceException } from '@smithy/smithy-client';
import { NotificationService } from '../../../shared/notification.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-groups-of-things-create',
  templateUrl: './groups-of-things-create.component.html',
  styleUrls: ['./groups-of-things-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupsOfThingsCreateComponent implements OnInit {
  @Input() thingTypes$?: Observable<string[]>;

  creating = false;

  formGroup = new UntypedFormGroup({
    groupName: new UntypedFormControl(null, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(120),
      Validators.pattern(/^\S.{1,118}\S$/),
    ]),
    thingType: new UntypedFormControl(null, [
      Validators.required,
      Validators.pattern(/^(?!null).*$/),
    ]),
    criteriaType: new UntypedFormControl('RANGE', Validators.required),
  });

  constructor(
    private readonly groupsService: GroupsOfThingsService,
    private readonly modal: NgbActiveModal,
    private readonly notif: NotificationService,
  ) {}

  ngOnInit(): void {
    this.formGroup.controls.groupName.valueChanges.subscribe((value) =>
      this.formGroup.controls.groupName.setValue(value?.trimLeft(), {
        emitEvent: false,
      }),
    );
  }

  submitForm(event?: Event): void {
    event?.preventDefault();

    if (this.formGroup.invalid) {
      return;
    }

    this.creating = true;

    this.groupsService
      .createGroup(this.formGroup.value)
      .subscribe(this.modal.close, (err: ServiceException) =>
        this.notif.showError(`Error creating group : ${err.message}`, err),
      );
  }

  cancel(): void {
    this.modal.close();
  }
}
