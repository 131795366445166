import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MonthRange } from '../../models/month-range';
import { MonthProductNumbers, ProductNumbers } from '../../models/device-stats';
import { AWSCost, AWSCosts } from '../../models/AWSCost';

@Component({
  selector: 'app-product-costs',
  templateUrl: './product-costs.component.html',
  styleUrls: ['./product-costs.component.css'],
})
export class ProductCostsComponent implements OnChanges {
  @Input() initialFilters?: MonthRange;
  @Input() currentFilters?: MonthRange;
  @Input() numberOfProducts?: MonthProductNumbers;
  @Input() costsByService?: AWSCosts;
  @Input() costsByTag?: AWSCosts;

  @Output() filters = new EventEmitter<MonthRange>();

  @ViewChild('numberOfProductsTable')
  _numberOfProductsTableRef?: ElementRef<HTMLTableElement>;
  @ViewChild('recalculatedPerMonthTable')
  _recalculatedPerMonthTableRef?: ElementRef<HTMLTableElement>;
  @ViewChild('recalculatedPerYearTable')
  _recalculatedPerYearTableRef?: ElementRef<HTMLTableElement>;

  _costsRecalculatedPerMonth?: AWSCosts;
  _costsRecalculatedPerYear?: AWSCosts;

  readonly _chartsColors = [
    '#ff6f8d',
    '#52b5ff',
    '#ffce56',
    '#d998ea',
    '#4bc0c0',
    '#97bbcd',
    '#81cb87',
  ];

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.numberOfProducts?.currentValue ||
        changes.costsByService?.currentValue ||
        changes.costsByTag?.currentValue) &&
      this.numberOfProducts &&
      this.costsByTag &&
      this.costsByService
    ) {
      this._costsRecalculatedPerMonth = {
        monthLabels: this.numberOfProducts.monthLabels,
        costs: this.numberOfProducts.productsNumbers
          .map(this.recalculateCostsForProduct.bind(this))
          .filter((cost: AWSCost | undefined): cost is AWSCost => !!cost), // Filters undefined or null values
      };

      this._costsRecalculatedPerYear = {
        monthLabels: this._costsRecalculatedPerMonth.monthLabels,
        costs: this._costsRecalculatedPerMonth.costs.map((cost) => ({
          key: cost.key,
          monthCosts: cost.monthCosts.map((monthCost) => monthCost * 12),
        })),
      };
    }
  }

  private recalculateCostsForProduct(
    product: ProductNumbers,
  ): AWSCost | undefined {
    const productCosts: AWSCost | undefined = this.costsByTag?.costs.find(
      (cost) => cost.key === product.productName,
    );

    if (!productCosts) {
      return;
    }

    const costRecalculated: AWSCost = {
      key: product.productName,
      monthCosts: productCosts.monthCosts.map((monthCosts, index) =>
        this.recalculateCosts(product.monthNumbers[index], monthCosts),
      ),
    };

    // ======= Specific rules for some products =========================================================

    // Add S3 costs to VAC001 costs
    this.addCostsToProduct({
      awsCosts: this.costsByService,
      product,
      costRecalculated,
      rule: {
        productName: 'VAC001',
        serviceOrTagName: 'Simple Storage Service',
      },
    });
    this.addCostsToProduct({
      awsCosts: this.costsByTag,
      product,
      costRecalculated,
      rule: {
        productName: 'VAC001',
        serviceOrTagName: 'SmartHome',
      },
    });
    this.addCostsToProduct({
      awsCosts: this.costsByService,
      product,
      costRecalculated,
      rule: {
        productName: 'PUR001',
        serviceOrTagName: 'Elasticsearch Service',
      },
    });
    this.addCostsToProduct({
      awsCosts: this.costsByService,
      product,
      costRecalculated,
      rule: {
        productName: 'PUR001',
        serviceOrTagName: 'OpenSearch Service',
      },
    });

    return costRecalculated;
  }

  private addCostsToProduct(params: {
    awsCosts?: AWSCosts;
    product: ProductNumbers;
    costRecalculated: AWSCost;
    rule: {
      productName: string;
      serviceOrTagName: string;
    };
  }): void {
    if (params.rule.productName === params.product.productName) {
      const serviceOrTagCosts = params.awsCosts?.costs.find(
        (cost) => params.rule.serviceOrTagName === cost.key,
      );
      if (serviceOrTagCosts) {
        params.costRecalculated.monthCosts =
          params.costRecalculated.monthCosts.map(
            (cost, index) =>
              cost +
              serviceOrTagCosts.monthCosts[index] /
                params.product.monthNumbers[index],
          );
      }
    }
  }

  private recalculateCosts(
    numberOfProducts: number,
    globalCost: number,
  ): number {
    return globalCost === 0 ? 0 : globalCost / numberOfProducts;
  }
}
