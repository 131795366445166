<tag-input
  [placeholder]="placeholder"
  [secondaryPlaceholder]="placeholder"
  [inputId]="inputId"
  [(ngModel)]="chipsModel"
  [disable]="disable"
  [onAdding]="onAddingChip.bind(this)"
  [onRemoving]="onRemovingChip.bind(this)"
  [addOnPaste]="true"
  [pasteSplitPattern]="chipsPasteSplitRegex"
  [separatorKeys]="chipsSeparatorKeys"
  [addOnBlur]="true"
  [editable]="false"
  [modelAsStrings]="true"
  [theme]="'bootstrap'"
  [inputClass]="'form-control'"
  [ripple]="false"
></tag-input>
<small
  class="form-text text-danger font-weight-bold"
  *ngIf="errorMessage"
  [innerHTML]="errorMessage"
></small>
