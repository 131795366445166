import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

export type RouteTitleData = {
  title?: string;
  titleRouteParam?: string;
};

@Injectable({
  providedIn: 'root',
})
export class TitleService {
  constructor(private readonly titleService: Title) {}

  getTitle(): string {
    return this.titleService.getTitle();
  }

  setTitle(newTitle: string): void {
    this.titleService.setTitle(newTitle);
  }

  replaceInTitle(findStr: string, replaceStr: string): void {
    const title = this.titleService.getTitle();
    const newTitle = title.replace(findStr, replaceStr);
    this.setTitle(newTitle);
  }
}
