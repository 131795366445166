<div class="container-fluid">
  <h1 class="text-gray-800">Billing</h1>
  <app-tab-view>
    <app-tab tabTitle="Global costs" tabId="global">
      <ng-template #tabContent>
        <app-global-costs
          [initialFilters]="_initialFilters"
          [currentFilters]="_currentFilters"
          [numberOfProducts]="_numberOfProducts$ | async"
          [costsByService]="_costsByService$ | async"
          (filters)="updateFilters($event)"
        ></app-global-costs>
      </ng-template>
    </app-tab>
    <app-tab tabTitle="Product costs" tabId="product">
      <ng-template #tabContent>
        <app-product-costs
          [initialFilters]="_initialFilters"
          [currentFilters]="_currentFilters"
          [numberOfProducts]="_numberOfProducts$ | async"
          [costsByService]="_costsByService$ | async"
          [costsByTag]="_costsByTag$ | async"
          (filters)="updateFilters($event)"
        ></app-product-costs>
      </ng-template>
    </app-tab>
  </app-tab-view>
</div>
