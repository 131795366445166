import { Directive, Input } from '@angular/core';
import { AbstractGroupBasedDirective } from './abstract-group-based.directive';
import { FeatureGroupEnum } from '../feature-group.enum';

@Directive({
  selector: '[canSeeFirmwareDetails]',
  standalone: true,
})
export class SeeFirmwareDetailsDirective extends AbstractGroupBasedDirective {
  @Input() set canSeeFirmwareDetails(value: boolean | undefined | null) {
    if (value != null) {
      this.expected = value;
    }
  }

  protected override getSpecificGroup(): FeatureGroupEnum {
    return FeatureGroupEnum.SEE_FIRMWARE_DETAILS;
  }
}
