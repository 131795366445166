import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MetaVersionJob } from '../../models/meta-version-job.model';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import * as moment from 'moment';
import { UtilsService } from '../../lib/utils.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NgxTableWithQueryParamsPersistenceComponent } from '../../shared/ngx-table-with-query-params-persistence/ngx-table-with-query-params-persistence.component';

@Component({
  selector: 'app-jobs-table',
  templateUrl: './jobs-table.component.html',
  styleUrls: ['./jobs-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobsTableComponent implements OnChanges, OnInit, OnDestroy {
  @Input() jobs: MetaVersionJob[] = [];
  @Input() resetFilters?: EventEmitter<void>;

  @ViewChild('table')
  table?: NgxTableWithQueryParamsPersistenceComponent<MetaVersionJob>;

  jobsForDisplay: Array<MetaVersionJob & { dateStr: string }> = [];

  readonly columns: Columns[] = [
    { key: 'jobId', title: 'Name' },
    { key: 'dateStr', title: 'Created on' },
    {
      key: 'jobType',
      title: 'Target',
      orderEnabled: false,
      searchEnabled: false,
    },
    {
      key: 'actions',
      title: 'Actions',
      orderEnabled: false,
      searchEnabled: false,
      width: '5%',
    },
  ];

  configuration: Config = {
    ...DefaultConfig,
    searchEnabled: true,
    paginationEnabled: true,
    rows: 5,
    orderEnabled: true,
  };

  formGroupColumnsFilters = new UntypedFormGroup({
    jobId: new UntypedFormControl(),
    dateStr: new UntypedFormControl(),
  });

  private destroy$ = new Subject<void>();

  constructor(private readonly utilsService: UtilsService) {}

  ngOnInit(): void {
    this.resetFilters
      ?.pipe(takeUntil(this.destroy$))
      .subscribe(() => this.formGroupColumnsFilters.reset());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.jobs?.currentValue) {
      this.jobsForDisplay = this.jobs.map((job) => ({
        ...job,
        dateStr: moment(job.date).format('YYYY-MM-DD HH:mm:ss'),
      }));

      this.jobsForDisplay.sort((a, b) =>
        this.utilsService.compare(a.date, b.date, false),
      );

      this.table?.resetPagination();
    }
  }

  typedJobObject(
    rawJob: MetaVersionJob & { dateStr: string },
  ): MetaVersionJob & { dateStr: string } {
    return rawJob;
  }
}
