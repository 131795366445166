<div class="modal-header">
  <h3>Delete thing</h3>
</div>

<div class="modal-body d-flex flex-column">
  <div class="d-flex flex-row">
    <div class="d-flex align-self-center pr-3">
      <i class="fa fa-exclamation-triangle modal-icon" aria-hidden="true"></i>
    </div>
    <div class="d-flex flex-column">
      Please confirm deletion of
      <div class="font-weight-bold">{{ thingName }}</div>
      <div class="text-danger">This cannot be undone.</div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" [disabled]="ongoing" (click)="confirm()">
    <i class="fa fa-check" aria-hidden="true"></i>
    Confirm
    <app-spinner-small *ngIf="ongoing"></app-spinner-small>
  </button>
  <button class="btn btn-secondary" [disabled]="ongoing" (click)="cancel()">
    <i class="fa fa-times" aria-hidden="true"></i>
    Cancel
  </button>
</div>
