import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThingListDisplay } from '../../../models/thing-list-display.model';
import { ChartOptions } from 'chart.js';

@Component({
  selector: 'app-group-of-thing-details-firmware-distribution',
  templateUrl: './group-of-thing-details-firmware-distribution.component.html',
  styleUrls: ['./group-of-thing-details-firmware-distribution.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupOfThingDetailsFirmwareDistributionComponent {
  readonly _chartOptions: ChartOptions = {
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
  };

  readonly _chartData: {
    wifi: number[];
    ui: number[];
  } = {
    wifi: [],
    ui: [],
  };

  readonly _chartLabels: {
    wifi: string[];
    ui: string[];
  } = {
    wifi: [],
    ui: [],
  };

  readonly _totals = { wifi: 0, ui: 0 };

  @Input() set things(things: ThingListDisplay[]) {
    this._totals.wifi = 0;
    this._totals.ui = 0;

    const wifiDistributionMap = new Map<string, number>();
    const uiDistributionMap = new Map<string, number>();

    things.forEach((thing) => {
      const wifi = thing.firmwareDetails?.wifi;
      if (wifi) {
        wifiDistributionMap.set(wifi, (wifiDistributionMap.get(wifi) ?? 0) + 1);
        this._totals.wifi++;
      }

      const ui = thing.firmwareDetails?.ui;
      if (ui) {
        uiDistributionMap.set(ui, (uiDistributionMap.get(ui) ?? 0) + 1);
        this._totals.ui++;
      }
    });

    this._chartLabels.wifi = [...wifiDistributionMap.keys()];
    this._chartData.wifi = this._chartLabels.wifi.map(
      (label) => wifiDistributionMap.get(label) ?? 0,
    );

    this._chartLabels.ui = [...uiDistributionMap.keys()];
    this._chartData.ui = this._chartLabels.ui.map(
      (label) => uiDistributionMap.get(label) ?? 0,
    );
  }
}
