import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import CONFIG from '../../config';
import { AwsService } from '../lib/aws.service';

@Injectable({
  providedIn: 'root',
})
export class CrossOriginService {
  constructor(private readonly aws: AwsService) {}

  /**
   * Makes a post request using the cross-origin lambda as proxy
   */
  post<T>(
    url: string,
    body?: unknown,
    headers?: { [k: string]: string },
  ): Observable<T | undefined> {
    return this.invokeLambda<T>({ method: 'POST', url, body, headers });
  }

  private invokeLambda<T>(callParameters: {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
    url: string;
    body?: unknown;
    headers?: { [k: string]: string };
  }): Observable<T | undefined> {
    return from(this.aws.lambda()).pipe(
      switchMap((lambda) =>
        lambda.invoke({
          FunctionName: CONFIG.crossOriginLambda,
          Payload: JSON.stringify(callParameters),
        }),
      ),
      map((lambdaRes) => {
        if (
          lambdaRes.StatusCode &&
          (lambdaRes.StatusCode < 200 || lambdaRes.StatusCode >= 300)
        ) {
          throw lambdaRes;
        }
        if (!lambdaRes?.Payload) {
          return undefined;
        }

        try {
          return JSON.parse(new TextDecoder().decode(lambdaRes.Payload)) as T;
        } catch (_) {
          return lambdaRes.Payload as T;
        }
      }),
    );
  }
}
