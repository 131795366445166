<div class="modal-body">
  <h2>Confirmation</h2>

  <p *ngIf="logfile">
    You are about to delete "<strong>{{ logfile }}</strong
    >" log file
  </p>
  <p *ngIf="!logfile">
    You are about to purge <strong>ALL</strong> log files from "{{ thingName }}"
  </p>

  <hr />
  <button
    (click)="confirm()"
    class="btn btn-danger float-right button-model m-2"
    [disabled]="deleting"
  >
    <span *ngIf="logfile">Delete</span>
    <span *ngIf="!logfile">Purge</span>
    <app-spinner-small white="true" *ngIf="deleting"></app-spinner-small>
  </button>
  <button (click)="cancel()" class="btn btn-secondary float-right m-2">
    Cancel
  </button>
</div>
