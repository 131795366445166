import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import * as FileSaver from 'file-saver';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import {
  catchError,
  map,
  mapTo,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { FirmwareService } from '../api/backend/services/firmware/firmware.service';
import { CriteriaKey, Firmware, ParsedCriteriaKey } from '../models/firmware';
import { NotificationService } from '../shared/notification.service';
import { DisplayFirmwareSigningInfoPipe } from '../shared/pipes/display-firmware-signing-info.pipe';
import { DisplayFormatDatePipe } from '../shared/pipes/display-format-date.pipe';

export interface RowValue {
  s3key: string;
  date: string;
  range: string;
  cmmf: string;
  indice: string;
  brandArea: string;
  fileName: string;
  signing: string;
  signed: boolean;
  bootloader: string;
  error: string;
  loadingPresignUrl$: Observable<boolean>;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-firmware-table',
  templateUrl: './firmware-table.component.html',
  styleUrls: ['./firmware-table.component.css'],
})
export class FirmwareTableComponent implements OnInit, OnChanges {
  @Input() firmware?: Firmware;
  @Input() shouldDisplayRange = false;
  @Input() shouldDisplayCmmf = false;
  @Input() shouldDisplayIndice = false;
  @Input() shouldDisplayBrandArea = false;

  columns: Columns[] = [];
  data: RowValue[] = [];
  configuration: Config = {
    ...DefaultConfig,
    searchEnabled: true,
    paginationEnabled: false,
    threeWaySort: true,
    rows: 1000000,
  };

  constructor(
    private signingPipe: DisplayFirmwareSigningInfoPipe,
    private formatDatePipe: DisplayFormatDatePipe,
    private firmwareService: FirmwareService,
    private notifService: NotificationService,
  ) {}

  ngOnChanges(): void {
    const displayableColumns = [
      { key: 'date', title: 'Creation Date' },
      { key: this.shouldDisplayRange ? 'range' : false, title: 'Range' },
      { key: this.shouldDisplayCmmf ? 'cmmf' : false, title: 'CMMF' },
      { key: this.shouldDisplayIndice ? 'indice' : false, title: 'Indice' },
      {
        key: this.shouldDisplayBrandArea ? 'brandArea' : false,
        title: 'Brand Area',
      },
      { key: 'fileName', title: 'File Name' },
      { key: 'signing', title: 'Signing', searchEnabled: false },
      { key: 'bootloader', title: 'Bootloader' },
      { key: 'error', title: 'Error' },
    ] as Columns[];

    this.columns = displayableColumns.filter((_) => _.key); // removes "false" keys (its corresponding "shouldDisplay..." is false)
  }

  ngOnInit(): void {
    if (!this.firmware?.s3Key) {
      return;
    }

    this.data = Object.entries(this.firmware.s3Key).map(([s3key, s3value]) => {
      const parsed = ParsedCriteriaKey.fromCriteriaKey(s3key as CriteriaKey);
      const signing = this.signingPipe.transform(this.firmware, s3key);
      return {
        firmwareId: this.firmware?.id,
        s3key,
        date: this.formatDatePipe.transform(s3value.date),
        range: parsed.range,
        cmmf: parsed.cmmf,
        indice: parsed.indice,
        brandArea: parsed.brandArea,
        fileName: this.firmware?.getFilename(s3key as CriteriaKey),
        signing,
        signed: signing !== '-',
        bootloader: this.firmware?.getBootloader(s3key as CriteriaKey),
        error: this.firmware?.getSigningError(s3key as CriteriaKey),
        loadingPresignUrl$: of(false),
      } as RowValue;
    });
  }

  onDownloadFirmware(rowValue: RowValue): void {
    if (!this.firmware || !rowValue.signed) {
      return;
    }

    rowValue.loadingPresignUrl$ = this.firmwareService
      .getFirmwareGetPresignUrl(this.firmware, rowValue.s3key)
      .pipe(
        switchMap((url) => this.firmwareService.downloadFromPresignUrl(url)),
        map((blob) => {
          FileSaver.saveAs(blob, rowValue.fileName);
          return false;
        }),
        catchError((err) =>
          this.notifService
            .showError(`Failed to download file ${rowValue.fileName}`, err)
            .pipe(mapTo(false)),
        ),
        startWith(true),
        shareReplay(1),
      );
  }

  /**
   * Allows IDEs to correctly infer the object type to provide completion and checks in HTML template
   */
  typedRow(rowValue: RowValue): RowValue {
    return rowValue;
  }
}
