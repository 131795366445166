import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GroupOfThings } from '../../../models/Group-of-things.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-groups-of-things-list-table',
  templateUrl: './groups-of-things-list-table.component.html',
  styleUrls: ['./groups-of-things-list-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupsOfThingsListTableComponent implements OnChanges, OnDestroy {
  @Input() groups?: GroupOfThings[];
  @Input() resetFilters?: EventEmitter<void>;
  @Input() actionsTemplate?: TemplateRef<GroupOfThings>;

  resetSubscription?: Subscription;

  readonly columns = [
    { key: 'creationDate', title: 'Creation Date', width: '10%' },
    { key: 'groupName', title: 'Name' },
    {
      key: 'thingType',
      title: 'Thing type',
      searchEnabled: false,
      width: '5%',
    },
    {
      key: 'numberOfThings',
      title: 'Number of things',
      orderEnabled: false,
      width: '10%',
    },
    {
      key: 'actions',
      title: 'Actions',
      orderEnabled: false,
      searchEnabled: false,
      width: '4%',
    },
  ] as Columns[];

  configuration: Config = {
    ...DefaultConfig,
    searchEnabled: true,
    paginationEnabled: true,
    rows: 10,
    orderEnabled: true,
  };

  formGroupLocalFilters = new UntypedFormGroup({
    creationDateStr: new UntypedFormControl(),
    groupName: new UntypedFormControl(),
    minNumberOfThings: new UntypedFormControl(),
    maxNumberOfThings: new UntypedFormControl(),
  });

  customColumnFilters = {
    minNumberOfThings: this.filterMinNumberOfThings,
    maxNumberOfThings: this.filterMaxNumberOfThings,
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resetFilters) {
      this.resetSubscription?.unsubscribe();
      this.resetSubscription = this.resetFilters?.subscribe(() =>
        this.formGroupLocalFilters.reset(),
      );
    }
  }

  ngOnDestroy(): void {
    this.resetSubscription?.unsubscribe();
  }

  filterMinNumberOfThings(formValue: number, item: GroupOfThings): boolean {
    return item.numberOfThings >= (formValue ?? 0);
  }

  filterMaxNumberOfThings(formValue: number, item: GroupOfThings): boolean {
    return item.numberOfThings <= (formValue ?? Number.MAX_VALUE);
  }

  /**
   * Used to help type inference in template
   */
  typeGroup(untyped: GroupOfThings): GroupOfThings {
    return untyped;
  }
}
