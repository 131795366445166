import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../../lib/utils.service';

@Pipe({
  name: 'displayMetaVersionDate',
})
export class DisplayMetaVersionDatePipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}

  transform(value: string | number | Date | undefined | null): string {
    if (!value) {
      return '-';
    }
    return this.utilsService.formatDate(value);
  }
}
