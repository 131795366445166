import { v4 as uuidv4, validate } from 'uuid';

export class UuidUtils {
  /**
   * Returns a random uuid using the v4 function from uuid library.
   */
  public static getRandomUuid(): string {
    return uuidv4();
  }

  /**
   * Rougly checks if the given uuid is valid
   *
   * @param uuid the uuid string to check against
   */
  public static isValidUuid(uuid: string): boolean {
    return validate(uuid);
  }
}
