import { Directive } from '@angular/core';
import { AbstractGroupBasedDirective } from './abstract-group-based.directive';
import { FeatureGroupEnum } from '../feature-group.enum';

@Directive({
  selector: '[canSeeThingsHome]',
  standalone: true,
})
export class SeeThingsHomeDirective extends AbstractGroupBasedDirective {
  protected override getSpecificGroup(): FeatureGroupEnum {
    return FeatureGroupEnum.SEE_THINGS_HOME;
  }
}
