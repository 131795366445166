import { Component, OnInit } from '@angular/core';
import { BillingService } from '../api/billing.service';
import { Observable } from 'rxjs';
import { MonthRange } from '../models/month-range';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, mergeMap, shareReplay } from 'rxjs/operators';
import { NotificationService } from '../shared/notification.service';
import { MonthProductNumbers } from '../models/device-stats';
import { AWSCosts } from '../models/AWSCost';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css'],
})
export class BillingComponent implements OnInit {
  _numberOfProducts$?: Observable<MonthProductNumbers | undefined>;
  _costsByService$?: Observable<AWSCosts | undefined>;
  _costsByTag$?: Observable<AWSCosts | undefined>;

  _updateFilters$?: Subject<MonthRange>;

  _initialFilters?: MonthRange;
  _currentFilters?: MonthRange;

  constructor(
    private readonly billingService: BillingService,
    private notif: NotificationService,
  ) {}

  ngOnInit(): void {
    this._initialFilters = this.computeInitialFilters();

    this._updateFilters$ = new BehaviorSubject(this._initialFilters);

    this._numberOfProducts$ = this._updateFilters$.pipe(
      mergeMap((filters) =>
        this.billingService.getTotalNumberOfDevicesPerMonth(filters).pipe(
          catchError((e: Error) => {
            this.notif.showError(e?.message, e);
            return of(undefined);
          }),
        ),
      ),
      shareReplay(1),
    );

    this._costsByService$ = this._updateFilters$.pipe(
      mergeMap((filters) =>
        this.billingService.getMonthlyCostsByService(filters).pipe(
          catchError((e: Error) => {
            this.notif.showError(e?.message, e);
            return of(undefined);
          }),
        ),
      ),
      shareReplay(1),
    );

    this._costsByTag$ = this._updateFilters$.pipe(
      mergeMap((filters) =>
        this.billingService.getMonthlyCostsByTag(filters).pipe(
          catchError((e: Error) => {
            this.notif.showError(e?.message, e);
            return of(undefined);
          }),
        ),
      ),
      shareReplay(1),
    );
  }

  computeInitialFilters(): MonthRange {
    const startDate = new Date();
    startDate.setDate(1);
    startDate.setMonth(startDate.getMonth() - 11);

    const endDate = new Date();
    endDate.setDate(1);
    endDate.setMonth(endDate.getMonth() + 1);

    return {
      startMonth: this.getIsoMonth(startDate),
      endMonth: this.getIsoMonth(endDate),
    };
  }

  updateFilters(filters: MonthRange): void {
    this._currentFilters = filters;
    this._updateFilters$?.next(filters);
  }

  private getIsoMonth(date: Date): string {
    const split = date.toISOString().split('-');
    return `${split[0]}-${split[1]}`;
  }
}
