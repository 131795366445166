import { Pipe, PipeTransform } from '@angular/core';
import { CriteriaKey, Firmware } from '../../models/firmware';

@Pipe({
  name: 'displayFirmwareFileName',
})
export class DisplayFirmwareFileNamePipe implements PipeTransform {
  transform(firmware: Firmware, criteriaKey?: string): unknown {
    if (firmware) {
      return firmware.getFilename(criteriaKey as CriteriaKey);
    }
    return '';
  }
}
