import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  AuditAction,
  AuditActionAdditionalData,
  AuditType,
  FilterQueryParameters,
  SearchAuditLog,
} from '@common/audit-log/models/AuditLog';
import {
  Columns,
  Config,
  DefaultConfig,
  Event,
  Pagination,
} from 'ngx-easy-table';
import { BehaviorSubject, merge, Observable, Subject, switchMap } from 'rxjs';
import {
  catchError,
  debounceTime,
  map,
  shareReplay,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { AuditService } from '../api/backend/services/audit/audit.service';
import {
  NgxEvent,
  NgxPaginationEventValue,
} from '../generic/ngx/ngx-event.model';
import { NotificationService } from '../shared/notification.service';
import { Utils } from '../shared/utils';

@Component({
  selector: 'app-user-manifest',
  templateUrl: './user-manifest.component.html',
  styleUrl: './user-manifest.component.scss',
})
export class UserManifestComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$ = new Subject<void>();
  filters$?: Observable<string[]>;
  auditLogs$?: Observable<SearchAuditLog[]>;
  forcedRefresh$ = new BehaviorSubject<void>(void 0);

  auditLogTypes$?: Observable<AuditType[]>;
  auditLogActions$?: Observable<AuditAction[]>;

  pagination: Pagination = { count: 0, offset: 1, limit: 10 };

  readonly columns = [
    { key: 'date', title: 'Date', width: '10%' },
    { key: 'userId', title: 'User', width: '5%' },
    { key: 'type', title: 'Type', width: '5%' },
    { key: 'action', title: 'Action', width: '5%' },
    { key: 'resourceId', title: 'Resource', width: '10%' },
    { key: 'additionalData', title: 'Additional data' },
    {
      key: 'actions',
      title: 'Actions',
      orderEnabled: false,
      searchEnabled: false,
      width: '4%',
    },
  ] as Columns[];

  configuration: Config = {
    ...DefaultConfig,
    serverPagination: true,
    orderEnabled: true,
    searchEnabled: false,
    paginationMaxSize: 10,
    isLoading: true,
    rows: 10,
  };

  includeUserLoginControl = new UntypedFormControl();

  formGroupLocalFilters = new UntypedFormGroup({
    includeUserLogin: this.includeUserLoginControl,
    userId: new UntypedFormControl(),
    type: new UntypedFormControl(),
    action: new UntypedFormControl(),
    resourceId: new UntypedFormControl(),
    additionalData: new UntypedFormControl(),
    dateMin: new UntypedFormControl(),
    dateMax: new UntypedFormControl(),
    pageSize: new UntypedFormControl(),
    pageIndex: new UntypedFormControl(),
    sortKey: new UntypedFormControl(),
    sortOrder: new UntypedFormControl(),
  });

  constructor(
    private readonly auditService: AuditService,
    private readonly notif: NotificationService,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.auditLogTypes$ = this.includeUserLoginControl.valueChanges.pipe(
      map(() => this.getAuditTypesFiltered()),
      shareReplay(1),
      startWith(this.getAuditTypesFiltered()),
    );
    this.auditLogActions$ = this.includeUserLoginControl.valueChanges.pipe(
      map((includeUserLogin) =>
        this.getAuditActionsFiltered(includeUserLogin === 'true'),
      ),
      shareReplay(1),
      startWith(this.getAuditActionsFiltered(false)),
    );

    this.auditLogs$ = merge(
      this.forcedRefresh$,
      this.formGroupLocalFilters.valueChanges,
      this.includeUserLoginControl.valueChanges,
    ).pipe(
      debounceTime(300),
      switchMap(() => {
        this.configuration.isLoading = true;
        const queryParameters = this.buildSearchFilters();
        const pageIndex =
          this.formGroupLocalFilters.controls['pageIndex'].value;
        const pageSize = this.formGroupLocalFilters.controls['pageSize'].value;
        return this.auditService.searchLogs(
          queryParameters,
          pageIndex,
          pageSize,
        );
      }),
      map((response) => {
        this.configuration.isLoading = false;
        this.pagination.limit = response.pageSize;
        this.pagination.offset = response.pageIndex;
        this.pagination.count = response.total || 0;
        this.pagination = { ...this.pagination };
        return response.data;
      }),
      shareReplay(1),
      catchError((error) => {
        this.configuration.isLoading = false;
        this.notif.showError(error.message, error);
        return [];
      }),
    );
  }

  ngAfterViewInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        if (params == null || Object.keys(params).length === 0) return;

        const formValues = this.formGroupLocalFilters.value;

        const paramIncludeUserLogin = params.includeUserLogin;
        if (paramIncludeUserLogin !== formValues.includeUserLogin) {
          this.includeUserLoginControl.setValue(
            paramIncludeUserLogin === undefined ? null : paramIncludeUserLogin,
          );
        }

        this.setControlFromQueryParam(params.userId, 'userId');
        this.setControlFromQueryParam(params.type, 'type');
        this.setControlFromQueryParam(params.action, 'action');
        this.setControlFromQueryParam(params.dateMin, 'dateMin');
        this.setControlFromQueryParam(params.dateMax, 'dateMax');
        this.setControlFromQueryParam(params.resourceId, 'resourceId');
        this.setControlFromQueryParam(params.additionalData, 'additionalData');
        this.setControlFromQueryParam(params.sortKey, 'sortKey');
        this.setControlFromQueryParam(params.sortOrder, 'sortOrder');

        const paramsPageSize = params.pageSize;
        if (paramsPageSize !== formValues.pageSize) {
          const sanitizedParamsPageSize =
            paramsPageSize === undefined ? 10 : paramsPageSize;
          this.pagination.limit = sanitizedParamsPageSize;
          this.formGroupLocalFilters.controls['pageSize'].setValue(
            sanitizedParamsPageSize,
            { emitEvent: false },
          );
        }

        const paramsPageIndex = params.pageIndex;
        if (paramsPageIndex !== formValues.pageIndex) {
          const sanitizedparamsPageIndex =
            paramsPageIndex === undefined ? 1 : paramsPageIndex;
          this.pagination.offset = sanitizedparamsPageIndex;
          this.formGroupLocalFilters.controls['pageIndex'].setValue(
            sanitizedparamsPageIndex,
            { emitEvent: false },
          );
        }

        this.pagination = { ...this.pagination };
      });
  }

  private setControlFromQueryParam<T>(
    queryParam: T,
    controlName: string,
  ): void {
    const control = this.formGroupLocalFilters.get(controlName);
    if (queryParam !== control?.value) {
      const sanitizedValue = queryParam === undefined ? null : queryParam;
      control?.setValue(sanitizedValue, { emitEvent: false });
    }
  }

  buildSearchFilters(): FilterQueryParameters {
    const includeUserLogin = this.includeUserLoginControl.value === 'true';
    const values = this.formGroupLocalFilters.value;

    return Utils.deleteNullishValues({
      userId: values.userId,
      action: values.action ?? this.getAuditActionsFiltered(includeUserLogin),
      type: values.type ?? this.getAuditTypesFiltered(),
      dateStart: values.dateMin,
      dateEnd: values.dateMax,
      resourceId: values.resourceId,
      additionalData: values.additionalData,
      sort: values.sortKey,
      order: values.sortOrder,
    }) as FilterQueryParameters;
  }

  private getAuditTypesFiltered(): AuditType[] {
    return Object.values(AuditType);
  }

  private getAuditActionsFiltered(
    includeUserLogin?: boolean | null,
  ): AuditAction[] {
    return Object.values(AuditAction).filter(
      (_t) => _t !== AuditAction.LOGIN || includeUserLogin === true,
    );
  }

  typeAuditLog(untyped: SearchAuditLog): SearchAuditLog {
    return untyped;
  }

  handleTableEvent($event: NgxEvent): void {
    switch ($event?.event) {
      case Event.onPagination:
        const paginationEventValue = $event.value as NgxPaginationEventValue;
        this.formGroupLocalFilters.controls['pageSize'].setValue(
          paginationEventValue.limit,
          { emitEvent: true },
        );
        this.formGroupLocalFilters.controls['pageIndex'].setValue(
          paginationEventValue.page,
          { emitEvent: true },
        );
        break;
      case Event.onOrder:
        this.forceRefresh();
    }
  }

  private forceRefresh(): void {
    this.forcedRefresh$.next();
  }

  getLegibleResourceId(log: SearchAuditLog): string | undefined {
    const additionalData = log?.additionalData;
    const actionData = additionalData as AuditActionAdditionalData;

    switch (log?.type) {
      case AuditType.USER:
        return `${log.lastname.toUpperCase()} ${log.firstname}`;
      case AuditType.FIRMWARE:
      case AuditType.FACTORY_FIRMWARE:
      case AuditType.METAVERSION:
      case AuditType.PRODUCT_LOG:
      case AuditType.THING:
        return log.resourceId;
      case AuditType.DEPLOYMENT:
        return `${log.resourceId}${actionData.target ? ` (${actionData.target})` : ''}`;
      case AuditType.THING_GROUP:
        const thingGroupName = actionData?.thing_group_name;
        if ([AuditAction.CREATE, AuditAction.DELETE].includes(log.action)) {
          return thingGroupName;
        }
        const thingname = actionData?.thingname;
        return `${thingname} (Group: ${thingGroupName})`;
    }
  }

  buildResourcePathUrl(
    log: SearchAuditLog,
  ): { href: string; queryParams?: { [k: string]: unknown } } | undefined {
    const additionalData = log?.additionalData;
    const actionData = additionalData as AuditActionAdditionalData;

    switch (log?.type) {
      case AuditType.USER:
        return undefined;
      case AuditType.FIRMWARE:
        return { href: `/firmwarefile/${log.resourceId}` };
      case AuditType.FACTORY_FIRMWARE:
        return {
          href: `/factory-firmwares/${log.resourceId}`,
          queryParams: { thingType: actionData.thing_type },
        };
      case AuditType.METAVERSION:
        return { href: `/metaversions/${log.resourceId}` };
      case AuditType.DEPLOYMENT:
        return { href: `/deployments/${log.resourceId}` };
      case AuditType.THING:
      case AuditType.PRODUCT_LOG:
        if (log.action === AuditAction.DELETE) {
          return;
        }
        return { href: `/things/${log.resourceId}` };
      case AuditType.THING_GROUP:
        if (log.action === AuditAction.DELETE) {
          return;
        }
        return { href: `/groups/${log.resourceId}` };
    }
  }
}
