import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { Observable } from 'rxjs';
import { merge } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private readonly toastr: ToastrService,
    private readonly modal: NgbModal,
  ) {
    toastr.toastrConfig.preventDuplicates = true;
  }

  showSuccess(msg: string): void {
    this.toastr.success('', msg, {
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
    });
  }

  showError(errMessage: string, errDetails?: unknown): Observable<void> {
    const modal: NgbModalRef = this.modal.open(ErrorDialogComponent, {
      backdrop: 'static',
      centered: true,
    });

    const modalInstance: ErrorDialogComponent = modal.componentInstance;

    modalInstance.errorMessage = errMessage;
    modalInstance.errorDetails = errDetails;

    return merge(modal.closed, modal.dismissed);
  }

  showInfo(err: string): void {
    this.toastr.info(err, '', {
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
    });
  }

  showWarning(err: string): void {
    this.toastr.warning(err, '', {
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
    });
  }
}
