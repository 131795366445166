<!-- Loader -->
<ng-template #loader>
  <app-spinner></app-spinner>
</ng-template>

<!-- Data chart -->
<div class="card shadow mb-4">
  <div class="card-body">
    <div class="flex-column" *ngIf="costsByService as costs; else loader">
      <h5>Total costs ($) and total number of products</h5>
      <canvas
        baseChart
        [datasets]="_datasets"
        [labels]="costs.monthLabels"
        type="line"
        [options]="_chartOptions"
      ></canvas>
    </div>
  </div>
</div>

<!-- Form -->
<div class="card shadow mb-4">
  <div class="card-body">
    <div class="d-flex flex-row justify-content-between">
      <div class="d-flex flex-column">
        <app-month-range-filter
          [initialFilters]="initialFilters"
          [currentFilters]="currentFilters"
          (filter)="filters.emit($event)"
        ></app-month-range-filter>
      </div>
      <div class="d-flex align-self-end ml-2">
        <!-- In a development environment, the table reference used may sometimes cause an ExpressionChangedAfterItHasBeenCheckedError.
        I couldn't properly fix this behavior in all cases, but it is no big deal and shouldn't appear in a production environment. -->
        <app-table-export
          fileName="global_costs"
          [tables]="
            !costsByService
              ? undefined
              : [
                  {
                    name: 'Global costs',
                    table: _dataTableRef?.nativeElement,
                  },
                ]
          "
        >
        </app-table-export>
      </div>
    </div>
  </div>
</div>

<!-- Data table -->
<div class="card shadow mb-4" [style]="costsByService ? '' : 'display: none'">
  <div class="card-body with-caption">
    <div class="scrollable">
      <table class="table data-table" #dataTable>
        <caption>
          Global costs per service as reported by
          <a
            href="https://console.aws.amazon.com/cost-management/home"
            target="_blank"
            >AWS</a
          >
        </caption>
        <ng-container *ngIf="costsByService as costs">
          <thead>
            <tr class="odd-row">
              <th scope="col" class="row-header">Service</th>
              <th *ngFor="let monthLabel of costs.monthLabels" scope="col">
                {{ monthLabel.replace("(Estimated)", "") }}
                <ng-container *ngIf="monthLabel.endsWith('(Estimated)')">
                  <br />(Estimated)
                </ng-container>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let serviceCosts of costs.costs; let index = index"
              [class]="index % 2 ? 'odd-row' : ''"
            >
              <th scope="row" class="row-header">{{ serviceCosts.key }}</th>
              <td *ngFor="let amount of serviceCosts.monthCosts">
                {{ amount | number: "1.2-2" }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="odd-row">
              <th scope="row" class="row-header tfoot-top-row">Total</th>
              <td *ngFor="let total of costs.totals" class="tfoot-top-row">
                {{ total | number: "1.2-2" }}
              </td>
            </tr>
            <tr class="font-weight-bold">
              <th scope="row" class="row-header">Total excl. taxes</th>
              <td *ngFor="let total of costs.totalsWithoutTaxes">
                {{ total | number: "1.2-2" }}
              </td>
            </tr>
            <tr class="odd-row">
              <th scope="row" class="row-header">Variation</th>
              <td *ngFor="let variation of costs.variations">
                <app-variation
                  *ngIf="variation"
                  [value]="variation"
                ></app-variation>
              </td>
            </tr>
          </tfoot>
        </ng-container>
      </table>
    </div>
  </div>
</div>
