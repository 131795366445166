import { Pipe, PipeTransform } from '@angular/core';
import { CriteriaKey, Firmware } from '../../models/firmware';

@Pipe({
  name: 'displayFirmwareSigningInfo',
})
export class DisplayFirmwareSigningInfoPipe implements PipeTransform {
  transform(firmware?: Firmware, criteriaKey?: string): unknown {
    if (!firmware) {
      return;
    }

    if (criteriaKey) {
      if (firmware.isAlreadySigned(criteriaKey as CriteriaKey)) {
        return 'already signed';
      }
      if (firmware.isSigned(criteriaKey as CriteriaKey)) {
        return 'signed by Iot';
      }
      return '-';
    }

    if (firmware.isAlreadySigned()) {
      return 'This firmware was already signed when uploaded to the console';
    }
    if (firmware.isSigned('NA')) {
      return 'This firmware has been automatically signed by the IoT platform';
    }
    return '-';
  }
}
