<app-ngx-table-with-query-params-persistence
  [configuration]="configuration"
  [columns]="columns"
  [data]="jobsForDisplay"
  [filtersFormGroup]="formGroupColumnsFilters"
  [filtersTemplate]="filtersTemplate"
  #table
>
  <ng-template let-rawJob>
    <!-- Allows IDEs to correctly infer the object type to provide completion and checks -->
    <ng-container *ngIf="typedJobObject(rawJob) as job">
      <td>{{ job.jobId }}</td>
      <td>{{ job.dateStr }}</td>
      <td>
        <app-job-type-badge
          *ngIf="job.jobType as jobType"
          [jobType]="jobType"
        ></app-job-type-badge>
      </td>
      <td>
        <a class="btn btn-eye" routerLink="/deployments/{{ job.jobId }}">
          <i class="fas fa-eye" aria-hidden="true"></i>
        </a>
      </td>
    </ng-container>
  </ng-template>
  <ng-template #filtersTemplate>
    <ng-container [formGroup]="formGroupColumnsFilters">
      <th scope="col" class="pl-0 pr-3 pt-0">
        <input
          class="form-control"
          type="text"
          placeholder="Name"
          formControlName="jobId"
        />
      </th>
      <th scope="col" class="pl-0 pr-3 pt-0">
        <input
          class="form-control"
          type="text"
          placeholder="Created on"
          formControlName="dateStr"
        />
      </th>
    </ng-container>
  </ng-template>
</app-ngx-table-with-query-params-persistence>
