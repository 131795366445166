import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = [];

  constructor(
    private readonly router: Router,
    private readonly location: Location,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    });
  }

  /**
   * Triggers a navigation to the last in-app url visited, similarly as clicking "back" in the browser.
   *
   * @param fallbackUrl Navigates to that url instead if the in-app navigation history is empty
   */
  back(fallbackUrl?: string): void {
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl(fallbackUrl ?? '/');
    }
  }
}
