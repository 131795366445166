<div class="card shadow">
  <div class="card-header">
    <h1>Create new group</h1>
  </div>
  <form [formGroup]="formGroup" (ngSubmit)="submitForm()">
    <div class="card-body">
      <label for="groupName">Group name :</label>
      <input
        class="form-control"
        type="text"
        id="groupName"
        formControlName="groupName"
        (keydown.enter)="submitForm($event)"
      />

      <label for="thingType" class="mt-4">Thing type :</label>
      <select
        class="form-select"
        id="thingType"
        formControlName="thingType"
        (keydown.enter)="submitForm($event)"
      >
        <option [value]="null">Choose one…</option>
        <option *ngFor="let thingType of thingTypes$ | async">
          {{ thingType }}
        </option>
      </select>

      <label for="criteriaType" class="mt-4">Filter criteria :</label>
      <select
        class="form-select"
        id="criteriaType"
        formControlName="criteriaType"
        (keydown.enter)="submitForm($event)"
      >
        <option value="THINGTYPE">Thing type</option>
        <option value="RANGE">Range</option>
        <option value="CMMF">Cmmf</option>
      </select>
    </div>
    <div class="card-footer d-flex flex-row w-100 justify-content-end">
      <button
        type="button"
        class="btn btn-outline-secondary mr-2"
        (click)="cancel()"
        [disabled]="creating"
      >
        <i class="fas fa-times mr-1" aria-hidden="true"></i>
        Cancel
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="formGroup.invalid || creating"
      >
        <i class="fas fa-check mr-1" aria-hidden="true"></i>
        Create
        <app-spinner-small *ngIf="creating"></app-spinner-small>
      </button>
    </div>
  </form>
</div>
