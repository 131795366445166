<div class="container-fluid">
  <form>
    <!-- Main info -->
    <div class="card shadow mb-4">
      <div class="card-header">
        <div class="d-flex justify-content-between align-items-center">
          <h1 class="text-gray-800">New Metaversion</h1>
          <div class="d-flex align-items-center justify-content-center">
            <ng-template [ngTemplateOutlet]="actionButtons"></ng-template>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row g-3 mb-3 align-items-center align-content-stretch">
          <div class="col-auto">
            <label for="metaVersion" class="form-label text-nowrap"
              >Thing Type*</label
            >
            <select
              class="form-select"
              id="thingType"
              name="thingType"
              [(ngModel)]="filterValue"
              (change)="onChangeTypeThing($event)"
              [disabled]="isLoading"
            >
              <option *ngFor="let filter of filters">{{ filter.name }}</option>
            </select>
          </div>
          <div class="col-auto">
            <label for="metaVersion" class="form-label text-nowrap"
              >Meta Version* (passed in the "nextFirmware.version" field)</label
            >
            <input
              class="form-control"
              id="metaVersion"
              name="metaVersion"
              type="text"
              placeholder="Example: 1.0.0"
              (change)="onChangeIdMetaVersion($event)"
              [disabled]="isLoading"
            />
          </div>
          <div class="col-auto">
            <label for="priority" class="form-label">Priority</label>
            <select
              class="form-select"
              id="priority"
              name="priority"
              ngDefaultControl
              [disabled]="this.isLoading"
              (change)="onChangePriority($event)"
            >
              <option *ngFor="let priority of filtersPriority">
                {{ priority }}
              </option>
            </select>
          </div>
          <div class="col-auto">
            <label for="validationAccepted" class="form-label text-nowrap"
              >Validation Accepted</label
            >
            <select
              class="form-select"
              id="validationAccepted"
              name="validationAccepted"
              ngDefaultControl
              [disabled]="this.isLoading"
              (change)="onChangeValidationAccepted($event)"
            >
              <option
                *ngFor="let validationAccepted of filtersValidationAccepted"
              >
                {{ validationAccepted }}
              </option>
            </select>
          </div>
          <div class="col-auto mt-5">
            <div class="form-switch">
              <input
                type="checkbox"
                id="updateValidation"
                (change)="onChangeUpdateValidation()"
                [disabled]="isLoading"
                class="form-check-input"
              />
              <label for="updateValidation" class="form-check-label"
                >Update Validation</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Firmware files -->
    <div class="card shadow mb-4">
      <h2 class="card-header">Firmware Files</h2>
      <div class="card-body">
        <div class="form-row">
          <div class="col">
            <div class="form-group">
              <label for="wifiFirmware">Choose a WIFI firmware</label>
              <select
                [disabled]="isLoading || metaVersion.thingType.length === 0"
                class="form-select"
                id="wifiFirmware"
                name="wifiFirmware"
                (change)="onChangeWIFIFirmware($event)"
              >
                <option>None</option>
                <option>New WIFI Firmware</option>
                <option *ngFor="let firmware of firmwaresWifi">
                  {{ firmware.version }}
                </option>
              </select>
            </div>
            <div *ngIf="metaVersion?.wifiFirmware?.date?.length === 0">
              <div class="form-group" *ngIf="brandAreasValueWIFI">
                <label for="wifiBrandArea">Choose a brand-area</label>
                <select
                  class="form-control"
                  id="wifiBrandArea"
                  name="wifiBrandArea"
                  (change)="onChangeBrandAreaWifi($event)"
                  [disabled]="isLoading || metaVersion.thingType.length === 0"
                >
                  <option disabled selected>Brand Area</option>
                  <option
                    *ngFor="let brandArea of brandAreasWifi"
                    value="{{ brandArea.key }}"
                  >
                    {{ brandArea.key }} {{ brandArea.description }}
                  </option>
                </select>
              </div>

              <div class="form-row">
                <div class="form-group col">
                  <label for="wifiVersion">Version*</label>
                  <input
                    class="form-control"
                    id="wifiVersion"
                    name="wifiVersion"
                    type="text"
                    placeholder="Example: 1.0.0"
                    (change)="onChangeVersionWifi($event)"
                    [disabled]="isLoading"
                  />
                </div>
                <div class="form-group col">
                  <label for="firmwares-wifi-version-flag" class="form-label text-nowrap"
                  >Version flag</label
                  >
                  <select
                    class="form-select"
                    id="firmwares-wifi-version-flag"
                    name="firmwares-wifi-version-flag"
                    ngDefaultControl
                    [disabled]="this.isLoading"
                    [(ngModel)]="metaVersion.wifiFirmware.versionFlag"
                  >
                    <option
                      [value]="versionFlag"
                      *ngFor="let versionFlag of versionFlags"
                    >
                      {{ versionFlag | titlecase | replace: "_" : " " }}
                    </option>
                  </select>
                </div>
              </div>


              <div class="form-group">
                <div class="form-switch mb-2">
                  <input
                    class="form-check-input"
                    id="wifiSigned"
                    name="wifiSigned"
                    type="checkbox"
                    [disabled]="isLoading"
                    (change)="onChangeFirmwareSignedWifi($event)"
                  />
                  <label class="form-check-label" for="wifiSigned">
                    The firmware is already signed
                  </label>
                </div>
              </div>
              <div
                class="form-group"
                [hidden]="
                  !metaVersion.wifiFirmware ||
                  !metaVersion.wifiFirmware.isNewSigned()
                "
              >
                <label for="wifiBootloader">Bootloader</label>
                <input
                  class="form-control"
                  id="wifiBootloader"
                  name="wifiBootloader"
                  type="text"
                  placeholder="Example: 1.0.0"
                  [disabled]="isLoading"
                  (change)="onChangeBootloaderWifi($event)"
                />
              </div>
              <div class="form-group">
                <label for="wifiFirmwareFile">Firmware File*</label>
                <input
                  #fileInputWifi
                  class="form-control-file"
                  id="wifiFirmwareFile"
                  name="wifiFirmwareFile"
                  type="file"
                  [disabled]="isLoading"
                />
                <br />
              </div>
              <div class="form-group">
                <label for="wifiReleaseNote">Release Note</label>
                <textarea
                  class="form-control-file releaseNote"
                  id="wifiReleaseNote"
                  name="wifiReleaseNote"
                  rows="{10}"
                  [disabled]="isLoading"
                  (change)="onChangeReleaseNoteWifi($event)"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="uiFirmware">Choose a UI firmware</label>
              <select
                [disabled]="isLoading || metaVersion.thingType.length === 0"
                class="form-select"
                id="uiFirmware"
                (change)="onChangeUIFirmware($event)"
              >
                <option>None</option>
                <option>New UI Firmware</option>
                <option *ngFor="let firmware of firmwaresUi">
                  {{ firmware.version }}
                </option>
              </select>
            </div>
            <div *ngIf="metaVersion?.uiFirmware?.date?.length === 0">
              <div class="form-group">
                <label for="uiFirmwareFile">Firmware File*</label>
                <input
                  #fileInputUi
                  class="form-control-file"
                  id="uiFirmwareFile"
                  name="uiFirmwareFile"
                  (change)="extractFirmwareData()"
                  type="file"
                  [disabled]="isLoading"
                />
                <div
                  class="mt-1 d-flex flex-row"
                  *ngIf="extractingFirmwareData"
                >
                  <app-spinner-small></app-spinner-small>
                  <p class="pl-2">Extracting data…</p>
                </div>
              </div>
              <div class="form-group">
                <label for="range">Range</label>
                <input
                  class="form-control"
                  id="range"
                  name="range"
                  type="text"
                  placeholder="Example: BC90"
                  (change)="onChangeRange($event)"
                  [disabled]="isLoading"
                />
              </div>
              <div class="form-group">
                <label for="cmmf">CMMF</label>
                <app-chips-input
                  [disable]="isLoading"
                  [inputId]="'cmmf'"
                  [placeholder]="'Example: 8372032750'"
                  [validationRegex]="'^[0-9]{10}$'"
                  [validationErrorMessage]="
                    'CMMFs must be 10 digits only.<br>Invalid values : #tagNames'
                  "
                  (addingChip)="
                    onAddingCMMF(this.metaVersion.uiFirmware, $event)
                  "
                  (removingChip)="
                    onRemovingCMMF(this.metaVersion.uiFirmware, $event)
                  "
                ></app-chips-input>
                <small class="form-text text-muted font-italic"
                  >You can write or copy/paste several CMMFs, separated by a
                  space, a new line or / , ; - : or by pressing Enter</small
                >
                <small
                  *ngIf="additionalCmmfs?.length"
                  class="form-text text-warning font-weight-bold"
                  >The created "Firmware File" will be duplicated to assign each
                  CMMF</small
                >
              </div>

              <div class="form-group">
                <label for="indice">Indice</label>
                <input
                  #indiceInput
                  class="form-control"
                  id="indice"
                  name="indice"
                  type="text"
                  placeholder="Example: 1"
                  (change)="onChangeIndice($event)"
                  [disabled]="isLoading"
                />
              </div>
              <div class="form-group" *ngIf="brandAreasValueUI">
                <label for="uiBrandArea">Choose a brand-area</label>
                <select
                  class="form-select"
                  id="uiBrandArea"
                  name="uiBrandArea"
                  [disabled]="isLoading || metaVersion.thingType.length === 0"
                  (change)="onChangeBrandAreaUi($event)"
                >
                  <option disabled selected>Brand Area</option>
                  <option
                    *ngFor="let brandArea of brandAreasUi"
                    value="{{ brandArea.key }}"
                  >
                    {{ brandArea.key }} {{ brandArea.description }}
                  </option>
                </select>
              </div>

              <div class="form-row">
                <div class="form-group col">
                  <label for="uiVersion">Version*</label>
                  <input
                    #uiVersionInput
                    [disabled]="isLoading"
                    class="form-control"
                    id="uiVersion"
                    name="uiVersion"
                    type="text"
                    placeholder="Example: 1.0.0"
                    (change)="onChangeVersionUi($event)"
                  />
                </div>
                <div class="form-group col">
                  <label for="firmwares-ui-version-flag" class="form-label text-nowrap"
                  >Version flag</label
                  >
                  <select
                    class="form-select"
                    id="firmwares-ui-version-flag"
                    name="firmwares-ui-version-flag"
                    ngDefaultControl
                    [disabled]="this.isLoading"
                    [(ngModel)]="metaVersion.uiFirmware.versionFlag"
                  >
                    <option
                      [value]="versionFlag"
                      *ngFor="let versionFlag of versionFlags"
                    >
                      {{ versionFlag | titlecase | replace: "_" : " " }}
                    </option>
                  </select>
                </div>
              </div>


              <div class="form-group">
                <div class="form-switch mb-2">
                  <input
                    class="form-check-input"
                    id="uiSigned"
                    name="uiSigned"
                    type="checkbox"
                    [disabled]="isLoading"
                    (change)="onChangeFirmwareSignedUi($event)"
                  />
                  <label class="form-check-label" for="uiSigned">
                    The firmware is already signed
                  </label>
                </div>
              </div>
              <div
                class="form-group"
                [hidden]="
                  !metaVersion.uiFirmware ||
                  !metaVersion.uiFirmware.isNewSigned()
                "
              >
                <label for="uiBootloader">Bootloader</label>
                <input
                  class="form-control"
                  id="uiBootloader"
                  name="uiBootloader"
                  type="text"
                  placeholder="Example: 1.0.0"
                  [disabled]="isLoading"
                  (change)="onChangeBootloaderUi($event)"
                />
              </div>
              <div class="form-group">
                <label for="uiReleaseNote">Release Note</label>
                <textarea
                  class="form-control-file releaseNote"
                  id="uiReleaseNote"
                  name="uiReleaseNote"
                  rows="{10}"
                  [disabled]="isLoading"
                  (change)="onChangeReleaseNoteUi($event)"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-3 justify-content-end">
      <ng-template [ngTemplateOutlet]="actionButtons"></ng-template>
    </div>
  </form>
</div>

<ng-template #actionButtons>
  <button
    class="btn btn-secondary mr-2"
    (click)="onClickCancel()"
    ngbTooltip="Return to Metaversions"
  >
    Cancel
  </button>
  <button
    class="btn btn-primary"
    (click)="createFirmware()"
    type="submit"
    [disabled]="isLoading || extractingFirmwareData"
    ngbTooltip="Create new Metaversion"
  >
    Create
  </button>
</ng-template>
