import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayTemporaryFilename',
})
export class DisplayTemporaryFilenamePipe implements PipeTransform {
  transform(value: string): unknown {
    return value.replace(/^[0-9]+_/, '');
  }
}
