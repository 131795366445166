import { Injectable } from '@angular/core';
import { AuthorizationService } from '../../api/authorization.service';

@Injectable({
  providedIn: 'root',
})
export class AuthResolver {
  constructor(private auth: AuthorizationService) {}
  resolve(): Promise<void> {
    return this.auth.initSession();
  }
}
