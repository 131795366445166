<body id="page-top">
  <div id="wrapper">
    <app-sidebar
      id="wrapper-side-bare"
      *ngIf="showNavBarSideBar$ | async"
    ></app-sidebar>
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content">
        <app-navbar *ngIf="showNavBarSideBar$ | async"></app-navbar>
        <div id="component-content">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</body>
