<div class="sidebar toggled">
  <ul
    class="sidebar toggled sidebar-content navbar-nav bg-gradient-primary sidebar-dark accordion"
    id="accordionSidebar"
  >
    <!-- Sidebar - Brand -->
    <a
      class="sidebar-brand d-flex align-items-center justify-content-center"
      routerLink=""
    >
      <div class="sidebar-brand-icon">
        <i class="fab fa-mixcloud font-weight-normal" aria-hidden="true"></i>
      </div>
    </a>
    <!-- Divider -->
    <hr class="sidebar-divider my-0" />
    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item" *canSeeThingsHome>
      <a class="nav-link" routerLink="">
        <i class="fas fa-fw fa-home" aria-hidden="true"></i>
        <span>Home</span>
      </a>
    </li>
    <!-- Nav Item - Utilities Collapse Menu -->
    <li class="nav-item" *canSearchThing>
      <a class="nav-link collapsed" routerLink="/search">
        <i class="fas fa-fw fa-search" aria-hidden="true"></i>
        <span>Search</span>
      </a>
    </li>
    <li class="nav-item" *canListGroupsOfThings>
      <a class="nav-link collapsed" routerLink="/groups">
        <i class="fas fa-fw fa-sitemap" aria-hidden="true"></i>
        <span>Groups</span>
      </a>
    </li>
    <!-- Nav Item - Pages Collapse Menu -->
    <li class="nav-item" *canListMetaversions>
      <a class="nav-link collapsed" routerLink="/metaversions">
        <i class="fas fa-fw fa-cogs" aria-hidden="true"></i>
        <span>MetaVersions</span>
      </a>
    </li>
    <!-- Nav Item - Charts -->
    <li class="nav-item" *canListFirmwares>
      <a class="nav-link" routerLink="/firmwares">
        <i class="fas fa-fw fa-cogs" aria-hidden="true"></i>
        <span>Firmwares</span></a
      >
    </li>

    <li class="nav-item" *canListFactoryFirmwares>
      <a class="nav-link" routerLink="/factory-firmwares">
        <i class="fas fa-fw fa-industry" aria-hidden="true"></i>
        <span>Factory</span></a
      >
    </li>
    <!-- Nav Item - Tables -->
    <li class="nav-item" *canListDeployments>
      <a class="nav-link" routerLink="/deployments">
        <i class="fas fa-fw fa-boxes" aria-hidden="true"></i>
        <span>Deployments</span></a
      >
    </li>
    <!-- Nav Item - Tables -->
    <li class="nav-item" *canSeeStatistics>
      <a class="nav-link" routerLink="/statistics">
        <i class="fas fa-fw fa-chart-pie" aria-hidden="true"></i>
        <span>Statistics</span></a
      >
    </li>

    <!-- Nav Item - Billing -->
    <li class="nav-item" *canSeeBilling>
      <a
        class="nav-link"
        routerLink="/billing"
        [queryParams]="{ tab: 'global' }"
      >
        <i class="fas fa-fw fa-dollar-sign" aria-hidden="true"></i>
        <span>Billing</span></a
      >
    </li>

  <!-- Nav Item - User Manifest -->
  <li class="nav-item" *canSeeManifest>
    <a class="nav-link" routerLink="/manifest">
      <i class="fas fa-fw fa-archive" aria-hidden="true"></i>
      <span>Manifest</span></a>
  </li>
  </ul>
</div>
