import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExportStatistics } from '../../../../models/backend/statistics/export-statistics';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService {
  private readonly backendUrl = environment.backendUrl;

  constructor(private readonly http: HttpClient) {}

  getExportFileUrl(
    date: string,
    thingType: string,
  ): Observable<ExportStatistics> {
    return this.http.get<ExportStatistics>(
      `${this.backendUrl}/statistics/thing-type/${thingType}/export`,
      {
        params: { date },
      },
    );
  }
}
