<!doctype html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <meta name="description" content="" />
    <meta name="author" content="" />

    <title>Register</title>
  </head>

  <body>
    <div class="row justify-content-center" id="login">
      <div class="col-xl-6 col-lg-10 col-md-9">
        <div class="card o-hidden border-0 shadow-lg my-5">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-lg-4 d-none d-lg-block bg-login-image"></div>
              <div class="col-lg-6">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Create your Account</h1>
                  </div>
                  <form class="user">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control form-control-user"
                        name="username"
                        placeholder="User name"
                        autoComplete="username"
                        autoFocus
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control form-control-user"
                        id="token"
                        name="token"
                        placeholder="Temporary Password"
                        autoComplete="one-time-code"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        class="form-control form-control-user"
                        name="newPassword"
                        type="password"
                        autoComplete="new-password"
                        placeholder="New Password"
                        minlength="8"
                        ngModel
                        #newPassword="ngModel"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        class="form-control form-control-user"
                        name="newPasswordCheck"
                        type="password"
                        autoComplete="new-password"
                        placeholder="Confirm Password"
                        ngModel
                        #newPasswordCheck="ngModel"
                      />
                    </div>
                    <div class="form-group">
                      <div class="small">
                        Password must contain at least:
                        <table class="table-register">
                          <tbody>
                            <tr>
                              <td
                                class="text-danger"
                                *ngIf="
                                  (!newPassword.valid &&
                                    newPassword.errors.minlength) ||
                                  !newPassword.value
                                "
                              >
                                x
                              </td>
                              <td
                                class="text-success"
                                *ngIf="
                                  newPassword.value &&
                                  !newPassword.errors?.minlength
                                "
                              >
                                ✓
                              </td>
                              <td>8 characters</td>
                            </tr>
                            <tr>
                              <td
                                class="text-danger"
                                *ngIf="!hasLowercaseChar(newPassword.value)"
                              >
                                x
                              </td>
                              <td
                                class="text-success"
                                *ngIf="hasLowercaseChar(newPassword.value)"
                              >
                                ✓
                              </td>
                              <td>1 lowercase character</td>
                            </tr>
                            <tr>
                              <td
                                class="text-danger"
                                *ngIf="!hasUppercaseChar(newPassword.value)"
                              >
                                x
                              </td>
                              <td
                                class="text-success"
                                *ngIf="hasUppercaseChar(newPassword.value)"
                              >
                                ✓
                              </td>
                              <td>1 uppercase character</td>
                            </tr>
                            <tr>
                              <td
                                class="text-danger"
                                *ngIf="!hasNumber(newPassword.value)"
                              >
                                x
                              </td>
                              <td
                                class="text-success"
                                *ngIf="hasNumber(newPassword.value)"
                              >
                                ✓
                              </td>
                              <td>1 number</td>
                            </tr>
                            <tr>
                              <td
                                class="text-danger"
                                *ngIf="!hasSymbol(newPassword.value)"
                              >
                                x
                              </td>
                              <td
                                class="text-success"
                                *ngIf="hasSymbol(newPassword.value)"
                              >
                                ✓
                              </td>
                              <td>1 symbol</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <button
                      href="login.html"
                      class="btn btn-primary btn-user btn-block"
                      [disabled]="
                        newPassword.value !== newPasswordCheck.value ||
                        !newPasswordCheck.value
                      "
                    >
                      Register
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</html>
