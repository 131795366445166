import {
  EventEmitter,
  Component,
  ElementRef,
  Input,
  Output,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { AWSCosts } from '../../models/AWSCost';
import { MonthRange } from '../../models/month-range';
import { MonthProductNumbers } from '../../models/device-stats';
import { ChartDataset, ChartOptions } from 'chart.js';

@Component({
  selector: 'app-global-costs',
  templateUrl: './global-costs.component.html',
  styleUrls: ['./global-costs.component.scss'],
})
export class GlobalCostsComponent implements OnChanges {
  @Input() initialFilters?: MonthRange;
  @Input() currentFilters?: MonthRange;
  @Input() numberOfProducts?: MonthProductNumbers;
  @Input() costsByService?: AWSCosts;

  @Output() filters = new EventEmitter<MonthRange>();

  @ViewChild('dataTable') _dataTableRef?: ElementRef<HTMLTableElement>;

  readonly _chartOptions: ChartOptions = {
    maintainAspectRatio: false,
    scales: {
      Costs: {
        type: 'linear',
        position: 'left',
      },
      Products: {
        type: 'linear',
        position: 'right',
      },
    },
    plugins: {
      tooltip: {
        mode: 'index',
        callbacks: {
          label(tooltipItem): string {
            console.log(tooltipItem);
            let label = tooltipItem.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (
              typeof tooltipItem.raw === 'number' &&
              !Number.isInteger(tooltipItem.raw)
            ) {
              label += tooltipItem.raw.toFixed(2);
            } else {
              label += tooltipItem.raw;
            }

            if (tooltipItem.datasetIndex === 0) {
              label += ' $';
            }
            return label;
          },
        },
      },
      legend: {
        position: 'bottom',
        labels: {
          boxHeight: 0,
        },
      },
    },
  };

  _datasets: ChartDataset[] = [];

  ngOnChanges(): void {
    if (this.costsByService && this.numberOfProducts) {
      this._datasets = [
        {
          label: 'Costs',
          data: this.costsByService.totals ?? [],
          yAxisID: 'Costs',
          fill: false,
        },
        {
          label: 'Number of products',
          data: this.numberOfProducts.totals,
          yAxisID: 'Products',
          fill: false,
        },
      ];
    }
  }
}
