<ngx-table
  [columns]="columns"
  [configuration]="_configuration"
  [data]="filteredData"
  [filtersTemplate]="filtersTemplate ?? emptyTemplate"
  [pagination]="pagination"
  (event)="handleTableEvent($event)"
  #ngxTable
  class="table"
>
  <ng-template let-row>
    <ng-container
      *ngTemplateOutlet="
        rowTemplate ?? emptyTemplate;
        context: { $implicit: row }
      "
    ></ng-container>
  </ng-template>
</ngx-table>

<ng-template #emptyTemplate></ng-template>
