import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../../lib/utils.service';
import { Shadow } from '../../models/shadow';

@Pipe({
  name: 'displayDesiredNextFirmware',
})
export class DisplayDesiredNextFirmwarePipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}
  transform(value: string): unknown {
    if (value) {
      const shadow = new Shadow(JSON.parse(value || '{}'));
      return this.utilsService.getVersions(shadow.desiredNextFirmware()) || '-';
    }
    return '-';
  }
}
