export class Shadow {
  private state?: ShadowState;

  constructor(state?: ShadowState) {
    this.state = state;
  }

  static fromShadowObject(obj: Shadow): Shadow {
    return new Shadow(obj.state ?? {});
  }

  public connected(): boolean {
    return !!this.state?.reported?.status?.connected;
  }

  public updating(): boolean {
    return !!this.state?.reported?.status?.updating;
  }

  public lastConnection(): number | undefined {
    return this.state?.reported?.status?.lastConnection;
  }

  public lastDisconnection(): number | undefined {
    return this.state?.reported?.status?.lastDisconnection;
  }

  public reportedFirmware(): ShadowStateFirmware | undefined {
    return this.state?.reported?.firmware;
  }

  public reportedNextFirmware(): ShadowStateFirmware | undefined {
    return this.state?.reported?.nextFirmware;
  }

  public desiredNextFirmware(): ShadowStateFirmware | undefined {
    return this.state?.desired?.nextFirmware;
  }

  public firmwareUpdateStatus(): FirmwareUpdateStatus | undefined {
    return this.state?.reported?.status?.firmwareUpdate;
  }

  public logUploadDesired(): boolean {
    return this.state?.desired?.logUpload ?? false;
  }

  public logUploadReported(): boolean {
    return this.state?.reported?.logUpload ?? false;
  }

  public synchronizing(): boolean | undefined {
    return this.state?.reported?.status?.synchronizing;
  }

  public firmwareUpdateDate(): number | undefined {
    return this.state?.reported?.firmware?.updateDate;
  }

  public localizationTimeZone(): string | undefined {
    return this.state?.reported?.localization?.timeZone;
  }

  static getFirmwareIdFromFile(
    file: ShadowStateFirmwareFile,
    thingType: string,
  ): string {
    return `${thingType}_${file.type.toUpperCase()}_${file.version}`;
  }
}

export interface ShadowState {
  reported?: ShadowStateReported;
  desired?: ShadowStateDesired;
}

export interface ShadowStateReported {
  status?: ShadowStateReportedStatus;
  firmware?: ShadowStateFirmware;
  nextFirmware?: ShadowStateFirmware;
  logUpload: boolean;
  localization?: ShadowStateReportedLocalization;
}

export interface ShadowStateReportedLocalization {
  timeZone?: string;
}

export interface ShadowStateDesired {
  nextFirmware?: ShadowStateFirmware;
  logUpload: boolean;
}

export interface ShadowStateReportedStatus {
  connected: boolean;
  lastConnection: number;
  lastDisconnection: number;
  updating: boolean;
  firmwareUpdate?: FirmwareUpdateStatus;
  synchronizing?: boolean;
}

export interface ShadowStateFirmware {
  version: string;
  files: ShadowStateFirmwareFile[];
  updateDate?: number;
}

export interface ShadowStateFirmwareFile {
  name?: string | null;
  product?: string | null;
  type: 'wifi' | 'ui';
  url?: string | null;
  version: string;
}

export interface FirmwareUpdateStatus {
  updateStatus: string;
  updateReason: string;
  updateMetaversion: string;
}

export interface UpdateShadow {
  logUpload: boolean;
}
