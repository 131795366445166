import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { getCurrentUser } from 'aws-amplify/auth';

export async function loginActivateGuard(
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): Promise<boolean | UrlTree> {
  const router = inject(Router);
  const isCurrentRouteLogin = state.url === '/login';

  try {
    const currentUser = await getCurrentUser();

    if (currentUser && isCurrentRouteLogin) {
      return router.createUrlTree(['/']);
    }

    return true;
  } catch {
    return isCurrentRouteLogin || router.createUrlTree(['/login']);
  }
}
