<div class="container-fluid">
  <!-- Page Heading -->
  <h1 class="text-gray-800">Groups of things</h1>
  <!-- Begin of filter bar -->
  <div class="card shadow mb-4">
    <div class="card-header">
      <div class="d-flex flex-row justify-content-between w-100">
        <form action="" class="form-inline">
          <div class="form-row align-items-center">
            <div class="col-auto ml-1">
              <select
                class="form-select"
                id="thingType"
                [attr.disabled]="_isLoading ? '' : null"
                [formControl]="thingTypeControl"
              >
                <option [value]="null">All thing types</option>
                <option *ngFor="let thingType of thingTypes$ | async">
                  {{ thingType }}
                </option>
              </select>
            </div>
            <div class="col-auto">
              <button
                class="btn btn-primary"
                (click)="resetFilters()"
                [disabled]="_isLoading"
              >
                Reset filters
              </button>
            </div>
            <div class="col-auto" *ngIf="_isLoading">
              <app-spinner-small></app-spinner-small>
            </div>
          </div>
        </form>
        <button
          class="btn btn-primary"
          (click)="createGroup()"
          *canEditGroupOfThings
        >
          <i class="fas fa-plus mr-1" aria-hidden="true"></i>
          Create group
        </button>
      </div>
    </div>

    <!-- end of filter bar -->
    <div class="card-body">
      <div
        class="table-responsive"
        *ngIf="groups$ | async as groups; else loading"
      >
        <app-groups-of-things-list-table
          [groups]="groups ?? undefined"
          [resetFilters]="resetLocalFilters"
          [actionsTemplate]="tableActionsTemplate"
        >
          <ng-template #tableActionsTemplate let-group>
            <div class="btn-group">
              <a
                class="btn btn-eye"
                *canSeeGroupsOfThingsDetails
                routerLink="/groups/{{ group.groupId }}"
                [queryParams]="{ tab: 'devices' }"
              >
                <i class="fas fa-eye" aria-hidden="true"></i>
              </a>
              <button
                class="btn btn-danger"
                *canDeleteGroupOfThings
                (click)="delete(group)"
                ngbTooltip="Delete group"
              >
                <i class="fas fa-trash-alt" aria-hidden="true"></i>
              </button>
            </div>
          </ng-template>
        </app-groups-of-things-list-table>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <app-spinner></app-spinner>
</ng-template>
