import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MonthRange } from '../../models/month-range';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-month-range-filter',
  templateUrl: './month-range-filter.component.html',
  styleUrls: ['./month-range-filter.component.css'],
})
export class MonthRangeFilterComponent implements OnInit, OnChanges {
  @Input() initialFilters?: MonthRange;
  @Input() currentFilters?: MonthRange;
  @Output() filter = new EventEmitter<MonthRange>();

  _filtersChanged = false;

  readonly _formGroup = new UntypedFormGroup({
    startMonth: new UntypedFormControl(undefined, Validators.required),
    endMonth: new UntypedFormControl(undefined, Validators.required),
  });

  ngOnInit(): void {
    if (this.currentFilters) {
      this._formGroup.setValue(this.currentFilters);
    } else if (this.initialFilters) {
      this._formGroup.setValue(this.initialFilters);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentFilters.currentValue) {
      this._formGroup.setValue(this.currentFilters ?? {});
      this._formGroup.markAsPristine();
      this._filtersChanged =
        this.currentFilters?.startMonth !== this.initialFilters?.startMonth ||
        this.currentFilters?.endMonth !== this.initialFilters?.endMonth;
    }
  }

  updateFilters(): void {
    this._formGroup.markAsPristine();

    this._filtersChanged = true;

    this.filter.emit(this._formGroup.value);
  }

  resetFilters(event: Event): void {
    event.preventDefault();

    this._formGroup.setValue(this.initialFilters ?? {});
    this._formGroup.markAsPristine();

    if (this._filtersChanged) {
      this._filtersChanged = false;
      this.filter.emit(this.initialFilters);
    }
  }
}
