import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  API,
  APIDefinition,
  Columns,
  Config,
  DefaultConfig,
  Event,
  Pagination,
} from 'ngx-easy-table';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  NgxEvent,
  NgxPaginationEventValue,
  NgxSortEventValue,} from '../../generic/ngx/ngx-event.model';

export type CustomFilters<T> = {
  [columnName: string]: (controlValue: any, item: T) => boolean;
};

/*eslint @typescript-eslint/no-explicit-any: 0*/
@Component({
  selector: 'app-ngx-table-with-query-params-persistence',
  templateUrl: './ngx-table-with-query-params-persistence.component.html',
  styleUrls: ['./ngx-table-with-query-params-persistence.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Wraps the ngx-table component and provides custom sort/filter/pagination implementation
 * where all filters, sort and pagination parameters are stored in the query parameters.
 */
export class NgxTableWithQueryParamsPersistenceComponent<
    T extends { [k: string]: any },
  >
  implements AfterViewInit, OnInit, OnDestroy
{
  @ViewChild('ngxTable') table?: APIDefinition;

  /**
   * ngx-table columns declaration. See ngx-table documentation.
   */
  @Input() columns: Columns[] = [];

  /**
   * HTML template used to define custom filter inputs.<br/>
   * Each column's input must be in a \<th> element, but no surrounding \<tr> is required.
   */
  @Input() filtersTemplate?: TemplateRef<void>;

  /**
   * A form group used to handle the filtering.<br/>
   * The controls must be mapped to the inputs in filtersTemplate.<br/>
   * Default filtering will be provided by this component under the following conditions :
   * - The form control name has exactly the same name as the associated data field
   * - Filtering will follow the pattern : ```js
   * data.fieldName.toString().includes(filtersFormGroup.value.fieldName.toString())
   * ```
   */
  @Input() filtersFormGroup?: UntypedFormGroup;

  /**
   * Column names on which to apply strict filtering using equals instead of includes
   */
  @Input() strictMatchColumns: string[] = [];

  /**
   * Allows providing custom filter functions for some columns
   */
  @Input() customMatchers?: CustomFilters<T>;

  /**
   * A form control used to handle global filtering. Must contain only string values
   */
  @Input() globalSearchControl?: UntypedFormControl;

  /**
   * A prefix that will be used to name query parameters.
   */
  @Input() prefix?: string;

  /**
   * Whether to locally filter the data
   */
  @Input() localFilter?: boolean = true;

  /**
   * Whether to locally paginate the data
   */
  @Input() pagination?: Pagination;

  /**
   * Events emitted by the ngx-table will be passed through via this output after custom handling by this component is done.
   */
  @Output() event = new EventEmitter<NgxEvent>();

  @ContentChild(TemplateRef) rowTemplate?: TemplateRef<T>;

  filteredData: T[] = [];

  protected _configuration: Config = { ...DefaultConfig };
  protected _data: T[] = [];

  private needToInitializeFromQueryParams = true;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  /**
   * ngx-table configuration. See ngx-table documentation.
   */
  @Input() set configuration(value: Config | undefined | null) {
    if (value) {
      this._configuration = value;
    }
  }

  /**
   * Data to be displayed
   */
  @Input() set data(value: T[] | undefined | null) {
    if (value) {
      this._data = value;
      this.filter(this.filtersFormGroup?.value ?? {});
    }
  }

  ngOnInit(): void {
    this.filtersFormGroup?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((formValue: Partial<T>) => {
        this.filter(formValue);

        const filteredValue: Params = {};

        Object.keys(formValue).forEach((key) => {
          if (formValue[key] && formValue[key] !== '') {
            filteredValue[this.getParamName(key)] = formValue[key];
          } else {
            filteredValue[this.getParamName(key)] = null; // Remove falsy values from params
          }
        });

        this.updateQueryParams(filteredValue);
      });

    this.globalSearchControl?.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.globalSearch(value);
        this.updateQueryParams({
          globalSearch: value || null,
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngAfterViewInit(): void {
    if (!this.table || !this.needToInitializeFromQueryParams) {
      return;
    }
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        const paramSize = params[this.getParamName('size')];
        if (paramSize) {
          this.table?.apiEvent({
            type: API.setPaginationDisplayLimit,
            value: paramSize,
          });
        } else {
          this.table?.apiEvent({
            type: API.setPaginationDisplayLimit,
            value: this._configuration.rows,
          });
        }

        const paramPage = params[this.getParamName('page')];
        if (paramPage) {
          this.table?.apiEvent({
            type: API.setPaginationCurrentPage,
            value: paramPage,
          });
        } else {
          this.table?.apiEvent({
            type: API.setPaginationCurrentPage,
            value: 1,
          });
        }

        const paramGlobalSearch = params[this.getParamName('globalSearch')];
        if (paramGlobalSearch && this.needToInitializeFromQueryParams) {
          this.globalSearchControl?.setValue(paramGlobalSearch);
        }

        const paramSortKey = params[this.getParamName('sortKey')];
        const paramSortOrder = params[this.getParamName('sortOrder')];
        if (paramSortKey && this.needToInitializeFromQueryParams) {
          const sortParams: { column: string; order: 'asc' | 'desc' } = {
            column: paramSortKey,
            order: paramSortOrder ?? 'asc',
          };

          this.table?.apiEvent({
            type: API.sortBy,
            value: sortParams,
          });
        }

        this.handleParamsForFormGroup(params);

        this.needToInitializeFromQueryParams = false;
      });
  }

  handleTableEvent($event: NgxEvent): void {
    const params: Params = {};

    switch ($event?.event) {
      case Event.onPagination:
        const paginationEventValue = $event.value as NgxPaginationEventValue;

        if (paginationEventValue?.page && paginationEventValue.page > 1) {
          params[this.getParamName('page')] = paginationEventValue.page;
        } else {
          params[this.getParamName('page')] = null;
        }

        if (
          paginationEventValue?.limit &&
          paginationEventValue.limit !== this._configuration.rows
        ) {
          params[this.getParamName('size')] = paginationEventValue.limit;
        } else {
          params[this.getParamName('size')] = null;
        }
        break;

      case Event.onOrder:
        const sortEventValue = $event.value as NgxSortEventValue;

        if (sortEventValue?.key) {
          params[this.getParamName('sortKey')] = sortEventValue.key;
        }

        if (sortEventValue?.order) {
          params[this.getParamName('sortOrder')] = sortEventValue.order;
        }
        break;
    }

    if (Object.keys(params).length) {
      this.updateQueryParams(params);
    }

    this.event.emit($event);
  }

  public resetPagination(): void {
    this.updateQueryParams({
      [this.getParamName('page')]: null,
      [this.getParamName('size')]: null,
    });
  }

  public globalSearch(value: string): void {
    this.table?.apiEvent({
      type: API.onGlobalSearch,
      value,
    });
  }

  private handleParamsForFormGroup(params: Params): void {
    if (!this.filtersFormGroup) {
      return;
    }

    const value: { [k: string]: unknown } = {};

    Object.keys(params).forEach((key) => {
      const shortKey = this.extractShortName(key);
      if (this.filtersFormGroup?.contains(shortKey)) {
        value[shortKey] = params[key];
      }
    });

    this.filtersFormGroup.patchValue(value);
  }

  private updateQueryParams(params: Params): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  private filter(formValue: any): void {
    if (!this.localFilter) {
      this.filteredData = this._data;
      return;
    }

    this.filteredData = this._data.filter((elt: T) => {
      let result = true;

      Object.keys(formValue).forEach((key) => {
        const isEmpty = formValue[key] == null || formValue[key] === '';
        if (this.strictMatchColumns?.includes(key)) {
          // eslint-disable-next-line max-len
          result &&=
            isEmpty ||
            elt[key]?.toString().toLocaleLowerCase() ===
              formValue[key]?.toString().toLocaleLowerCase();
        } else if (this.customMatchers?.[key]) {
          result &&= isEmpty || this.customMatchers[key](formValue[key], elt);
        } else {
          // eslint-disable-next-line max-len
          result &&=
            isEmpty ||
            elt[key]
              ?.toString()
              .toLocaleLowerCase()
              .includes(formValue[key]?.toString().toLocaleLowerCase());
        }
      });

      return result;
    });
  }

  private getParamName(shortName: string): string {
    if (this.prefix?.length) {
      return `${this.prefix}_${shortName}`;
    }
    return shortName;
  }

  private extractShortName(paramName: string): string {
    if (this.prefix) {
      return paramName.replace(`${this.prefix}_`, '');
    }
    return paramName;
  }
}
