<div class="card shadow">
  <div class="card-header">
    <h1>Delete Thing from Group</h1>
  </div>
  <div class="card-body">
    <div class="d-flex flex-row">
      <i
        class="warning-icon fas fa-exclamation-triangle"
        aria-hidden="true"
      ></i>
      <div>
        <p>
          Do you confirm removing Thing<br />
          <strong>{{ thing?.thingName }}</strong>
        </p>
        <small class="form-text font-italic text-warning"
          >You may need to refresh in a minute for the Group to be
          updated.</small
        >
      </div>
    </div>
  </div>
  <div class="card-footer d-flex flex-row w-100 justify-content-end">
    <button
      class="btn btn-outline-secondary mr-2"
      (click)="cancel()"
      [disabled]="deleting"
    >
      <i class="fas fa-times mr-1" aria-hidden="true"></i>
      Cancel
    </button>
    <button class="btn btn-danger" (click)="delete()" [disabled]="deleting">
      <i class="fas fa-trash-alt mr-1" aria-hidden="true"></i>
      Delete
      <app-spinner-small *ngIf="deleting"></app-spinner-small>
    </button>
  </div>
</div>
