import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ThingTypesService } from '../../api/thing-types.service';
import { catchError, shareReplay, take } from 'rxjs/operators';
import { NotificationService } from '../../shared/notification.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FactoryFirmwaresService } from '../../api/backend/services/factory-firmwares/factory-firmwares.service';
import { Router } from '@angular/router';
import { CreateFactoryFirmware } from '../../models/factory-firmware';

@Component({
  selector: 'app-factory-firmware-create',
  templateUrl: './factory-firmware-create.component.html',
  styleUrls: ['./factory-firmware-create.component.scss'],
})
export class FactoryFirmwareCreateComponent
  implements OnInit, AfterViewChecked
{
  thingTypes$?: Observable<string[]>;

  formGroup = new FormGroup({
    version: new FormControl<string>('', Validators.required),
    thingType: new FormControl<string>('', Validators.required),
    files: new FormControl<string[]>(
      [],
      [Validators.required, Validators.minLength(1)],
    ),
  });

  loading = false;
  fileUploadLoading = 0;

  preSelectedThingType?: string;

  constructor(
    private readonly thingTypesService: ThingTypesService,
    private readonly notif: NotificationService,
    private readonly factoryFirmwaresService: FactoryFirmwaresService,
    private readonly router: Router,
  ) {
    this.preSelectedThingType =
      router.getCurrentNavigation()?.extras.state?.thingType;
  }

  ngOnInit(): void {
    this.thingTypes$ = this.thingTypesService.getThingTypes()?.pipe(
      catchError((err) => {
        this.notif.showError(err.message, err);
        return of([]);
      }),
      shareReplay(1),
    );
  }

  ngAfterViewChecked(): void {
    if (this.preSelectedThingType && this.formGroup.pristine) {
      this.thingTypes$?.pipe(take(1)).subscribe(() => {
        this.formGroup.patchValue({
          thingType: this.preSelectedThingType,
        });
      });
    }
  }

  create(): void {
    if (this.formGroup.invalid) {
      return;
    }

    this.loading = true;

    this.factoryFirmwaresService
      .createFactoryFirmware(this.formGroup.value as CreateFactoryFirmware)
      .subscribe({
        next: () =>
          this.router.navigate(
            [
              'factory-firmwares',
              encodeURIComponent(this.formGroup.value.version!),
            ],
            {
              queryParams: {
                thingType: this.formGroup.value.thingType,
              },
            },
          ),
        error: (err) => {
          this.notif.showError('Error creating version', err);
          this.loading = false;
        },
      });
  }

  onFileSelected(event: Event): void {
    const files = (event.target as HTMLInputElement).files;

    if (!files?.length) {
      return;
    }

    this.fileUploadLoading = files.length;

    // Can't do a for-of loop because the native element FileList is not iterable
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < files.length; i++) {
      this.factoryFirmwaresService.uploadFile(files[i]).subscribe({
        next: (temporaryFilename) => {
          this.formGroup.patchValue({
            files: [...this.formGroup.value.files!, temporaryFilename],
          });
          this.fileUploadLoading--;
        },
        error: (err) => {
          this.notif.showError('Error uploading file', err);
          this.fileUploadLoading--;
        },
      });
    }
  }

  removeFile(filename: string): void {
    this.formGroup.patchValue({
      files: this.formGroup.value.files!.filter(
        (existingFilename: string) => existingFilename !== filename,
      ),
    });
  }
}
