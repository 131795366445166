<!-- Begin Page Content -->
<div class="container-fluid">
  <!-- Page Heading -->
  <h1 class="text-gray-800">Search</h1>
  <!-- Page Body -->
  <div class="card shadow mb-4">
    <!-- Begin of filter bar -->
    <div class="card-header">
      <div class="d-flex flex-row justify-content-between w-100">
        <form action="" class="form-inline flex-column align-items-start">
          <div class="form-row align-items-center gap-2">
            <div>
              <select
                class="form-select"
                aria-label="Default select example"
                id="thingType"
                name="thingType"
                [disabled]="isLoading"
                [(ngModel)]="thingType"
              >
                <option [ngValue]="undefined">Thing Type</option>
                <option *ngFor="let thingType of thingTypes">
                  {{ thingType }}
                </option>
              </select>
            </div>
            <input
              class="form-control"
              style="min-width: 450px"
              id="searchTerm"
              name="searchTerm"
              type="text"
              placeholder="MAC Address, Serial Number, ThingName, or Nickname"
              [(ngModel)]="searchTerm"
              (change)="onChangeSearchValue($event)"
            />
            <button
              class="btn btn-primary"
              (click)="search(searchTerm)"
              [disabled]="isLoading"
            >
              @if (isLoading) {
                <app-spinner-small ></app-spinner-small>
              }
              @else {
                Search
              }
            </button>
            @if (searchTerm) {
              <button
                class="btn btn-outline-dark"
                (click)="clearSearch()"
              >
                <i aria-hidden="true" class="fa fa-trash"></i>
              </button>
            }
          </div>
          <small class="form-text text-muted font-italic">
            You can use the "<strong>*</strong>" wildcard, except at the <strong>start</strong>, to search for partial ThingName or Nickname (e.g. "VAC001-abcdef-<strong>*</strong>" or "testing<strong>*</strong>cookeo")
          </small>
        </form>
      </div>
    </div>
    <!-- end of filter bar -->
    <!-- Begin content -->
    <app-thing-list-table
      [devices]="devices"
      [thingType]="thingType"
      [isLoading]="isLoading"
      [columns]="columns"
      [configuration]="configuration"
      [formGroupLocalFilters]="formGroupLocalFilters"
      [pageNumber]="pageNumber"
      [disablePreviousPage]="disablePreviousPage"
      [disableNextPage]="disableNextPage"
      [previousDevices]="previousDevices.bind(this)"
      [nextDevices]="nextDevices.bind(this)"
    >
    </app-thing-list-table>
  </div>
</div>
