import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SearchResult } from '../api/backend/services/things/things.model';
import { ThingsService } from '../api/backend/services/things/things.service';
import { IotStatisticsService } from '../api/iot-statistics.service';
import { ThingTypesService } from '../api/thing-types.service';
import { ThingListDisplay } from '../models/thing-list-display.model';
import { ThingTypesSelect } from '../models/thingtypesselect';
import { NotificationService } from '../shared/notification.service';
import { scrollToTop } from '../shared/utils/window.utils';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  private readonly THINGTYPE_DEFAULT = 'Thing Type';

  paginationCache = new Map<number, SearchResult>();
  devices: ThingListDisplay[] = [];
  filters: ThingTypesSelect[] = [{ id: '1', name: this.THINGTYPE_DEFAULT }];
  thingType: string = this.filters[0].name;
  connected = true;
  pageNumber = 0;
  disablePreviousPage = true;
  disableNextPage = false;
  isLoading = false;
  queryString?: string;

  thingsCount$?: Observable<number | string>;

  readonly columns = [
    { key: 'name', title: 'Name', width: '25%' },
    { key: 'connectionTimeStr', title: 'Last Connection', width: '10%' },
    { key: 'currentFirmware', title: 'Current Firmware', width: '10%' },
    {
      key: 'nextFirmwareReported',
      title: 'Next Firmware (reported)',
      searchEnabled: true,
      width: '10%',
    },
    {
      key: 'nextFirmwareDesired',
      title: 'Next Firmware (desired)',
      orderEnabled: true,
      width: '10%',
    },
    {
      key: 'actions',
      title: 'Actions',
      orderEnabled: false,
      searchEnabled: false,
      width: '4%',
    },
  ] as Columns[];

  configuration: Config = {
    ...DefaultConfig,
    searchEnabled: true,
    paginationEnabled: false,
    rows: 1000000,
    orderEnabled: true,
    isLoading: this.isLoading,
  };

  formGroupLocalFilters = new UntypedFormGroup({
    name: new UntypedFormControl(),
    connectionTimeStr: new UntypedFormControl(),
    currentFirmware: new UntypedFormControl(),
    nextFirmwareReported: new UntypedFormControl(),
    nextFirmwareDesired: new UntypedFormControl(),
  });

  constructor(
    private notif: NotificationService,
    private toastrService: ToastrService,
    private thingTypesService: ThingTypesService,
    private iotStatisticsService: IotStatisticsService,
    private readonly thingsService: ThingsService,
  ) {}

  ngOnInit(): void {
    this.search().catch((err: Error) => this.notif.showError(err.message, err));
    this.getThingTypes();
  }

  resetFilter(): void {
    this.connected = true;
    this.formGroupLocalFilters.reset();
    this.thingType = this.filters[0].name;
    this.search().catch((err: Error) => this.notif.showError(err.message, err));
  }

  onChange(): void {
    this.search().catch((err: Error) => this.notif.showError(err.message, err));
  }

  onChangeTypeThing(event: Event): void {
    this.thingType = (event.target as HTMLOptionElement).value;
    this.search().catch((err: Error) => this.notif.showError(err.message, err));
  }

  async nextDevices(event: Event): Promise<void> {
    event.preventDefault();
    const currentPageResult = this.paginationCache.get(this.pageNumber);
    if (!currentPageResult?.nextToken) return;

    this.setIsLoading(true);
    const nextPage = this.pageNumber + 1;

    try {
      const nextPageResult = await this.getCacheOrSearch(
        nextPage,
        this.thingType,
        currentPageResult.nextToken,
      );
      this.setResultAsCurrentPage(nextPageResult, nextPage);
      this.disablePreviousPage = false;
    } catch (e) {
      console.error(e);
      this.notif.showError((e as Error).message, e);
      this.setIsLoading(false);
      return;
    }
    scrollToTop();
  }

  getThingTypes(): void {
    this.thingTypesService
      .getThingTypes()
      ?.pipe(
        catchError((err) => {
          this.notif.showError(err.message, err);
          return of([]);
        }),
      )
      .subscribe((thingTypes) => {
        let index = 2;
        for (const type of thingTypes) {
          this.filters.push({ id: index.toString(), name: type });
          index++;
        }
      });
  }

  previousDevices(): void {
    if (this.pageNumber <= 0) return;

    const previousPage = this.pageNumber - 1;
    const cachedResult = this.paginationCache.get(previousPage)!;
    this.setResultAsCurrentPage(cachedResult, previousPage);
    scrollToTop();
  }

  private setIsLoading(val: boolean): void {
    this.isLoading = val;
    if (this.configuration) {
      this.configuration.isLoading = val;
    }
  }

  async search(): Promise<void> {
    this.queryString = this.makeSearchQueryString();
    if (this.queryString === '') {
      return;
    }

    this.setIsLoading(true);
    this.pageNumber = 0;
    this.paginationCache.clear();

    const thingType =
      this.thingType !== this.THINGTYPE_DEFAULT ? this.thingType : undefined;

    this.thingsCount$ = this.iotStatisticsService
      .getThingCount({
        thingType: thingType,
        connected: this.connected,
      })
      .pipe(
        catchError((err: Error) => {
          this.notif.showError(
            `Error fetching things count : ${err.message}`,
            err,
          );
          return of('error');
        }),
      );

    let searchResult: SearchResult = { things: [] };

    try {
      searchResult = await this.getCacheOrSearch(this.pageNumber, thingType);
    } catch (e) {
      console.error(e);
      this.notif.showError((e as Error).message, e);
    }

    this.devices = []; // to avoid emptying the table then waiting for result
    this.setResultAsCurrentPage(searchResult, 0);
  }

  setResultAsCurrentPage(result: SearchResult, page: number): void {
    this.setIsLoading(false);
    this.paginationCache.set(page, result);
    this.devices = ThingListDisplay.fromSearchThings(result.things);
    this.pageNumber = page;
    this.disableNextPage = !result.nextToken;
    this.disablePreviousPage = page === 0;
  }

  private makeSearchQueryString(): string {
    const queryParams = [];

    // Connected param
    queryParams.push('connectivity.connected:' + this.connected);

    // Thing type
    if (this.thingType !== this.THINGTYPE_DEFAULT) {
      queryParams.push('thingTypeName:' + this.thingType);
    }

    return queryParams.join(' AND ');
  }

  async getCacheOrSearch(
    page: number,
    thingType: string | undefined,
    nextToken?: string | undefined,
  ): Promise<SearchResult> {
    const cachedResult = this.paginationCache.get(page);
    if (cachedResult) return cachedResult;

    const searchResult = await lastValueFrom(
      this.thingsService.search('*', thingType, this.connected, nextToken),
    );

    this.paginationCache.set(page, searchResult);

    return searchResult;
  }
}
