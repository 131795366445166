import { AuthSession } from 'aws-amplify/auth';

export class User {
  public readonly groups: string[];

  constructor(public readonly session: AuthSession) {
    this.groups =
      (session.tokens?.idToken?.payload['custom:rightGroups'] as string)?.split(
        ';',
      ) ?? [];
  }
}
