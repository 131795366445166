<div
  class="month-picker"
  [triggered]="isOpen"
  (clickOutside)="onClickOutside()"
>
  <div class="input-container">
    <input
      type="text"
      [attr.readonly]="isReadOnly"
      [class]="inputClass"
      [ngClass]="{ 'is-invalid': isInvalid }"
      [(ngModel)]="displayValue"
      (blur)="setValue()"
      (focus)="onFocus()"
      (click)="toggle()"
    />
    <ng-container *ngIf="!showIcon; else calendarIcon"></ng-container>
  </div>
  <div class="month-picker-container" *ngIf="isOpen">
    <div class="month-content">
      <div class="month-picker-header">
        <button (click)="decrementYear()" type="button" class="previous">
          <span>‹</span>
        </button>
        <button class="current">
          <span>{{ selectedYear }}</span>
        </button>
        <button (click)="incrementYear()" type="button" class="next">
          <span>›</span>
        </button>
      </div>
      <div class="month-picker-body">
        <table class="month">
          <tbody>
            <tr *ngFor="let splittedMOnths of months; let i = index">
              <td
                *ngFor="let month of splittedMOnths; let i = index"
                (click)="selectMonth(month.value)"
              >
                <span [ngClass]="{ active: month.value === selectedMonth }">{{
                  month.name
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #calendarIcon>
  <svg
    class="calendar-icon"
    (click)="toggle()"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="36px"
    height="36px"
    viewBox="0 0 36.449 36.448"
    style="enable-background: new 0 0 36.449 36.448"
    xml:space="preserve"
  >
    <g>
      <g>
        <rect x="12.858" y="14.626" width="4.596" height="4.089" />
        <rect x="18.996" y="14.626" width="4.595" height="4.089" />
        <rect x="25.128" y="14.626" width="4.596" height="4.089" />
        <rect x="6.724" y="20.084" width="4.595" height="4.086" />
        <rect x="12.858" y="20.084" width="4.596" height="4.086" />
        <rect x="18.996" y="20.084" width="4.595" height="4.086" />
        <rect x="25.128" y="20.084" width="4.596" height="4.086" />
        <rect x="6.724" y="25.54" width="4.595" height="4.086" />
        <rect x="12.858" y="25.54" width="4.596" height="4.086" />
        <rect x="18.996" y="25.54" width="4.595" height="4.086" />
        <rect x="25.128" y="25.54" width="4.596" height="4.086" />
        <path
          d="M31.974,32.198c0,0.965-0.785,1.75-1.75,1.75h-24c-0.965,0-1.75-0.785-1.75-1.75V12.099h-2.5v20.099
                c0,2.343,1.907,4.25,4.25,4.25h24c2.344,0,4.25-1.907,4.25-4.25V12.099h-2.5V32.198z"
        />
        <path
          d="M30.224,3.948h-1.098V2.75c0-1.517-1.197-2.75-2.67-2.75c-1.474,0-2.67,1.233-2.67,2.75v1.197h-2.74V2.75
                c0-1.517-1.197-2.75-2.67-2.75c-1.473,0-2.67,1.233-2.67,2.75v1.197h-2.74V2.75c0-1.517-1.197-2.75-2.67-2.75
                c-1.473,0-2.67,1.233-2.67,2.75v1.197H6.224c-2.343,0-4.25,1.907-4.25,4.25v2h2.5h27.5h2.5v-2
                C34.474,5.855,32.568,3.948,30.224,3.948z M11.466,7.646c0,0.689-0.525,1.25-1.17,1.25s-1.17-0.561-1.17-1.25V2.75
                c0-0.689,0.525-1.25,1.17-1.25s1.17,0.561,1.17,1.25V7.646z M19.546,7.646c0,0.689-0.525,1.25-1.17,1.25s-1.17-0.561-1.17-1.25
                V2.75c0-0.689,0.525-1.25,1.17-1.25s1.17,0.561,1.17,1.25V7.646z M27.626,7.646c0,0.689-0.525,1.25-1.17,1.25
                c-0.646,0-1.17-0.561-1.17-1.25V2.75c0-0.689,0.524-1.25,1.17-1.25c0.645,0,1.17,0.561,1.17,1.25V7.646z"
        />
      </g>
    </g>
  </svg>
</ng-template>
