import { Component, OnInit, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import * as moment from 'moment';

export interface Month {
  value: string;
  name: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ng-month-picker',
  templateUrl: './ng-month-picker.component.html',
  styleUrls: ['./ng-month-picker.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NgMonthPickerComponent,
      multi: true,
    },
  ],
})

/*eslint {
"@typescript-eslint/no-explicit-any": 0,
"@typescript-eslint/no-empty-function": 0
}
 */
export class NgMonthPickerComponent implements OnInit, ControlValueAccessor {
  /**
   * Default input class
   */
  @Input() inputClass = 'form-control';

  /**
   * Input display format
   */
  @Input() displayFormat = 'YYYY/MM';

  /**
   * Input value format
   */
  @Input() valueFormat = 'YYYY-MM';

  /**
   * Is read only input
   */
  @Input() isReadOnly = true;

  /**
   * Show calendar icon
   */
  @Input() showIcon = true;

  /**
   * Is control invalid
   */
  @Input() isInvalid = false;

  /**
   * Open month picker flag
   */
  isOpen = false;

  /**
   * Months array
   */
  months: Month[][] = [];

  /**
   * Selected year
   */
  selectedYear?: number;

  /**
   * Selected month
   */
  selectedMonth?: string;

  /**
   * Display value to show in input
   */
  displayValue?: string;

  /**
   * Value to be sent in form control
   */
  controlValue?: string;

  onChange: any = () => {};

  onTouch: any = () => {};

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.selectedYear = parseInt(moment().format('YYYY'), 10);
    this.generateMonths();
  }

  writeValue(value: string): void {
    if (value) {
      this.displayValue = moment(value).format(this.displayFormat);
      this.controlValue = moment(value).format(this.valueFormat);
      this.setSelectedYearMonth();
      this.onChange(this.controlValue);
      this.onTouch(this.controlValue);
    } else {
      this.onChange(null);
      this.onTouch(null);
    }
  }

  /**
   * Register on change value
   */
  registerOnChange(fn: (val: any) => any): void {
    this.onChange = fn;
  }

  /**
   * Register on touch value
   */
  registerOnTouched(fn: (val: any) => any): void {
    this.onTouch = fn;
  }

  /**
   * Select month
   */
  selectMonth(selectedMonth: string): void {
    this.selectedMonth = selectedMonth;
    this.setValue();
    this.isOpen = false;
  }

  /**
   * Set selected value to display and form
   */
  setValue(): void {
    if (this.selectedMonth) {
      this.displayValue = moment(
        `${this.selectedYear}-${this.selectedMonth}`,
      ).format(this.displayFormat);
      this.controlValue = moment(
        `${this.selectedYear}-${this.selectedMonth}`,
      ).format(this.valueFormat);
      this.setSelectedYearMonth();
      this.onChange(this.controlValue);
      this.onTouch(this.controlValue);
    }
  }

  /**
   * On foucus mark as touched
   */
  onFocus(): void {
    this.onTouch(null);
  }

  /**
   * Open close month picker
   */
  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  /**
   * Close on click outside month picker
   */
  onClickOutside(): void {
    this.isOpen = false;
    this.setSelectedYearMonth();
  }

  /**
   * Set selected year and selected month
   */
  setSelectedYearMonth(): void {
    if (this.controlValue) {
      this.selectedYear = parseInt(
        moment(this.controlValue).format('YYYY'),
        10,
      );
      this.selectedMonth = moment(this.controlValue).format('MM');
    }
  }

  /**
   * Increase year
   */
  incrementYear(): void {
    if (this.selectedYear) {
      this.selectedYear++;
    }
  }

  /**
   * Decrease year
   */
  decrementYear(): void {
    if (this.selectedYear) {
      this.selectedYear--;
    }
  }

  /**
   * Generate total number of months
   */
  generateMonths(): void {
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ].map((month, index) => ({
      value: moment().month(index).format('MM'),
      name: month,
    }));
    this.splitMonths(months, 3);
  }

  /**
   * Create month row array for displaying
   */
  splitMonths(months: Month[], part: number): void {
    this.months = [];
    while (months.length > 0) {
      const splittedMonts = months.splice(0, part);
      this.months.push(splittedMonts);
    }
  }
}
