import { Component } from '@angular/core';
import { AuthorizationService } from '../api/authorization.service';
import { NotificationService } from '../shared/notification.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  email = '';
  password = '';
  loading = false;

  constructor(
    private auth: AuthorizationService,
    private notif: NotificationService,
  ) {}

  async onSubmit(e: Event): Promise<void> {
    e.preventDefault();
    this.auth.signIn().catch((err: Error) => this.notif.showError(err.message));
  }
}
