import { Directive } from '@angular/core';
import { AbstractGroupBasedDirective } from './abstract-group-based.directive';
import { FeatureGroupEnum } from '../feature-group.enum';

@Directive({
  selector: '[canListDeployments]',
  standalone: true,
})
export class ListDeploymentsDirective extends AbstractGroupBasedDirective {
  protected override getSpecificGroup(): FeatureGroupEnum {
    return FeatureGroupEnum.LIST_DEPLOYMENTS;
  }
}
